import React, { useEffect, useMemo, useRef, useState } from "react";
import Input from "../../../components/Input";
import username from "../../assets/images/username.png";
import email from "../../assets/images/white-email.png";
import password from "../../assets/images/password.png";
import hash from "../../assets/images/hash.png"
import "./Setting.css";
import CryptoJS from "crypto-js";
import { useDispatch, useSelector } from "react-redux";
import {
	addservice,
	changeApprovalType,
	changepass,
	changesecques,
	checksecques,
	DeleteAccount,
	DeleteAccountOTP,
	genpromo,
	getsecques,
	ref_account,
	removeservice,
	requestmobverfyotp,
	settings,
	verifyotp,
} from "../../API";
import { setSettingData } from "../../../redux/Actions/UserAction";
import { toast } from "react-hot-toast";
import Loader from "../../../components/Loader";
import Button from "../../../components/Button";
import { useMediaQuery } from "react-responsive";
import usePopupModal from "../../../components/popUpModal";
import { getUserInfo } from "../../../utility";
import COLORS from "../../../utility/colors";
import { toasty } from "../admin/utils/helper";
import Captcha from "./Captcha";
const generateCaptcha = () => {
	const chars =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	let captcha = "";
	for (let i = 0; i < 6; i++) {
		captcha += chars.charAt(Math.floor(Math.random() * chars.length));
	}
	return captcha;
};
const Setting = () => {
	const { settingData, locale } = useSelector((state) => state.user);
	const { modalVisible, hideModal, showModal } = usePopupModal();
	const dispatch = useDispatch();
	const [isApproved, setApproved] = useState({});
	const [isPromoListModal, setPromoListModal] = useState(false);
	const [isChangeSecurityQuestion, setChangeSecurityQuestion] = useState(false);
	const [isChangePassword, setChangePassword] = useState(false);
	const [isDeleteAccount, setDeleteAccount] = useState(false);
	const [isDeleteConfirmModal, setDeleteConfirmModal] = useState(false)
	const [isDeleteConfirmModalOTP, setDeleteConfirmModalOTP] = useState(false)
	const en = locale?.languageType === "English";
	const [isLoading, setLoading] = useState(false);
	const [promoList, setPromoList] = useState([]);
	const [otpValue, setOtpValue] = useState("");
	const [isVerifiNumberModal, setVerifiNumberModal] = useState(false);
	const [userData, setUserData] = useState({});
	const [question, setQuestion] = useState();
	const [isPasswordSecurity, setPasswordSecurity] = useState(false);
	const [passwordValue, setPasswordValue] = useState();
	const [cpasswordValue, setCPasswordValue] = useState();
	const [captcha, setCaptcha] = useState(generateCaptcha());
	const [userInput, setUserInput] = useState("");
	const handleInputChange = (e) => {
		setUserInput(e.target.value);
	};
	const refreshCaptcha = () => {
		setCaptcha(generateCaptcha());
		setUserInput("");
	};
	const secretQuestions1 = [
		{ value: "What is your birth date?" },
		{ value: "What is your birth place?" },
		{ value: "What is your birth state capital?" },
		{ value: "What is your favourite color?" },
		{ value: "What is your favourite destination place?" },
		{ value: "What is your favourite food?" },
		{ value: "What is your favourite movie?" },
		{ value: "What is your favourite sport?" },
		{ value: "What is your first car brand?" },
		{ value: "What is your first car color?" },
		{ value: "What is your first mobile brand?" },
	];
	const secretQuestions2 = [
		{ value: "What is your first pet name?" },
		{ value: "What is your first school name?" },
		{ value: "What is your graduation percentage?" },
		{ value: "What is your spouse birth date?" },
		{ value: "What is your spouse birth place?" },
		{ value: "Where did you go on your first international trip?" },
		{ value: "Where did you study 6th grade?" },
		{ value: "who is your favourite actor?" },
		{ value: "Who is your favourite sports person?" },
	];

	const [pass, setPass] = useState();
	const [error, setError] = useState(false);
	const isMobile = useMediaQuery({
		query: "(max-width: 820px)",
	});
	const handleOtpChange = (event) => {
		setOtpValue(event.target.value);
	};
	useEffect(() => {
		getSettingData();
		if (!settingData?.mob_verify) {
			toast.error("Verify phone number to proceed!", {
				duration: 2000
			});
		}
	}, []);

	const validateCaptcha = () => {
		if (userInput === captcha) {
			return true;
		} else {
			toast.error("CAPTCHA is incorrect. Try again!");
			return false;
		}
	};

	const handleDeleteAccount = async () => {
		console.log(pass?.password, "pass?.password")
		const res = validateCaptcha();
		if (!res) return null;
		const result = await DeleteAccount(pass?.password)
		if (result?.response === "Incorrect password" || result?.response === "Missing Input") {
			toasty(result?.response, "error")
		} else if (result?.response === "ok") {
			setDeleteConfirmModal(false);
			setDeleteConfirmModalOTP(true)
			toasty("Please check your registered emailID for OTP.",)
		}
		else {
			toasty("Something went wrong", "error")
		}
	}

	const handleDeleteAccountOTP = async () => {
		const result = await DeleteAccountOTP(userData?.del_acc_otp)
		if (result?.response === "ok") {
			setDeleteConfirmModalOTP(false);
			toasty("The request for deleting your account has been registered. If you wish to revoke this request, you may contact support@synerthinksolutions.com.")
			setTimeout(() => {
				localStorage.clear();
				window.location.reload()
			}, 2000);
		}
		else {
			toasty("Something went wrong", "error")
		}
	}

	const getQuestion = useMemo(() => {
		setPass();
		setPasswordValue();
		setCPasswordValue();
		setUserData({});
		setPasswordSecurity(false);
		setLoading(true);
		let token = localStorage.getItem("key");
		getsecques(token).then((response) => {
			if (response?.response === "ok") {
				setQuestion(response.data);
				setLoading(false);
			} else {
				setChangePassword(false);
				setLoading(false);
				toast.error("Something went wrong");
			}
		});
	}, [isChangePassword, error]);
	const Checkanswer = () => {
		let token = localStorage.getItem("key");
		let row = JSON.stringify({
			ques: question,
			ans: userData?.answer,
		});
		checksecques(row, token).then((response) => {
			if (response?.data) {
				toast.success("Answer matched successfully");
				setPasswordSecurity(true);
			} else {
				setError(!error);
				toast.error("Wrong answer");
			}
		});
	};

	const onChangePassword = () => {
		if (passwordValue != cpasswordValue) {
			toast.error("Password doesn't match.");
			return;
		}
		let digitsRegExp =
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
		if (digitsRegExp.test(passwordValue)) {
			var formdata = new FormData();
			formdata.append("password", pass?.password);
			let token = localStorage.getItem("key");
			changepass(formdata, token).then((response) => {
				setPasswordSecurity(false);
				toast.success("Password changed successfully");
				localStorage.clear();
				window.location.reload();
				setChangePassword(false);
			});
		} else {
			toast.error(
				"Password must be of at least 8 alphanumeric character and symbol"
			);
		}
	};
	const callHash = (val, key_name) => {
		if (key_name == "password") {
			setPasswordValue(val);
		} else if (key_name == "confirmPassword") {
			setCPasswordValue(val);
		} else if (key_name == "currentPassword") {
			setUserData((previous) => ({
				...previous,
				[key_name]: val,
			}));
		}
		var value = CryptoJS.SHA1(val);
		setPass((previous) => ({
			...previous,
			[key_name]: value,
		}));
	};

	const getSettingData = async () => {
		setLoading(true);
		const token = localStorage.getItem("key");
		const result = await settings(token);
		if (result?.response === "ok") {
			dispatch(setSettingData(result?.data));
			setLoading(false);
		}
		setLoading(false);
	};

	const VerifyMobileNumber = async () => {
		setLoading(true);
		let token = localStorage.getItem("key");
		requestmobverfyotp(token).then((response) => {
			if (response?.response === "ok") {
				toast.success("OTP send successfully");
				setLoading(false);
				setVerifiNumberModal(true);
			} else {
				toast.error("Something went wrong");
				setLoading(false);
			}
		});
	};

	const handleApprovalType = async (type, password) => {
		setLoading(true);
		let token = localStorage.getItem("key");
		var raw = JSON.stringify({
			approval_type: type,
			password: password ? password : null,
		});
		try {
			changeApprovalType(raw, token).then((response) => {
				if (response?.response === "ok") {
					setLoading(false);
					hideModal(false);
					setPresetPassword({});
					getSettingData();
					getUserInfo(dispatch);
					toast.success("Change approval type successfully");
				} else {
					setLoading(false);
					toast.error("Something went wrong");
				}
			});
		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	};

	const onChangeSecurityQuestion = async (e) => {
		e.preventDefault();
		const {
			SecretQuestion1Answer,
			SecretQuestion2Answer,
			SecurityAnswer1,
			SecurityAnswer2,
			currentPassword,
		} = userData;
		if (
			SecretQuestion1Answer &&
			SecretQuestion2Answer &&
			SecurityAnswer1 &&
			SecurityAnswer2 &&
			currentPassword
		) {
			var formdata = new FormData();
			formdata.append("sec_pass", pass?.currentPassword);
			formdata.append("q1", SecretQuestion1Answer);
			formdata.append("ans1", SecurityAnswer1);
			formdata.append("q2", SecretQuestion2Answer);
			formdata.append("ans2", SecurityAnswer2);
			let token = localStorage.getItem("key");
			changesecques(formdata, token).then((response) => {
				if (response.response === "ok") {
					toast.success("Changed security question successfully!");
					setChangeSecurityQuestion(false);
				} else {
					toast.error("Incorrect password");
				}
			});
		} else {
			toast.error("All fields are mendatory");
		}
	};

	const submitOTPVerifyNumber = async () => {
		let token = localStorage.getItem("key");
		var raw = JSON.stringify({
			code: otpValue,
		});
		try {
			verifyotp(raw, token).then((response) => {
				if (response.response === "ok") {
					toast.success("Number verified successfully");
					window.location.reload();
				} else {
					toast.error("Something went wrong");
				}
			});
		} catch (error) {
			console.log(error);
		}
	};
	const [presetPassword, setPresetPassword] = useState({});

	const onHandlePresetPassword = () => {
		const uppercaseRegExp = /(?=.*?[A-Z])/;
		const lowercaseRegExp = /(?=.*?[a-z])/;
		const digitsRegExp = /(?=.*?[0-9])/;
		const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
		const minLengthRegExp = /.{8,}/;

		const uppercasePassword = uppercaseRegExp.test(presetPassword.password);
		const lowercasePassword = lowercaseRegExp.test(presetPassword.password);
		const digitsPassword = digitsRegExp.test(presetPassword.password);
		const specialCharPassword = specialCharRegExp.test(presetPassword.password);
		const minLengthPassword = minLengthRegExp.test(presetPassword.password);
		if (
			uppercasePassword &&
			lowercasePassword &&
			digitsPassword &&
			specialCharPassword &&
			minLengthPassword
		) {
			handleApprovalType(3, presetPassword.password);
		} else {
			toast.error("please enter valid password");
		}
	};
	const onChangeText = (value, key) => {
		setPresetPassword((previous) => ({
			...previous,
			[key]: value,
		}));
	};

	const SettingServiceComponent = ({ icon, text, onClick, isExternalIcon }) => {
		return (
			<div
				onClick={() => onClick()}
				style={{
					display: "flex",
					justifyContent: "center",
					marginBottom: 10,
					cursor: "pointer",
				}}
			>
				<div
					className="input-icon"
					style={{ justifyContent: "center", display: "flex" }}
				>
					{isExternalIcon ?? <img
						src={icon}
						alt="password"
						style={{ padding: 0, margin: 0, width: 18 }}
					/>}
				</div>
				<div
					className="text-input"
					style={{ display: "flex", alignItems: "center" }}
				>
					<span>{text}</span>
				</div>
			</div>
		);
	};

	const ChangeSecurityQuestionModal = () => {
		return (
			<div className="modal-wrapper">
				<div
					style={{
						backgroundColor: "#fff",
						width: 540,
						paddingBottom: 60,
						paddingTop: 20,
						borderRadius: 12,
						maxHeight: 600,
						overflow: "scroll",
					}}
				>
					<i
						onClick={() => setChangeSecurityQuestion(false)}
						className="fa fa-times-circle fa-lg"
						style={{
							color: "#e98936",
							display: "flex",
							justifyContent: "flex-end",
							paddingRight: 20,
							cursor: "pointer",
						}}
					/>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<h2
							className="gradient-text"
							style={{
								color: "#e98936",
								marginBottom: 30,
								textTransform: "uppercase",
							}}
						>
							Change Security Question
						</h2>
						<div
							onClick={() => {
								setUserData((previous) => ({
									...previous,
									["SecretQuestion1"]: !userData?.SecretQuestion1,
									["SecretQuestion2"]: false,
								}));
							}}
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								padding: 10,
								justifyContent: "space-between",
								width: "82%",
								backgroundColor: "rgba(20,97,156, 0.9)",
								borderRadius: 6,
								color: "#fff",
								cursor: "pointer",
								marginBottom: 10,
							}}
						>
							<span>
								{userData?.SecretQuestion1Answer
									? userData?.SecretQuestion1Answer
									: `Select secret question 1`}
							</span>
							<i class="fa fa-angle-down" aria-hidden="true"></i>
						</div>
						<div
							className={`secretQuestion ${userData?.SecretQuestion1 ? "" : "hidden"
								}`}
						>
							{secretQuestions1.map((question, index) => (
								<p
									onClick={() => {
										setUserData((previous) => ({
											...previous,
											["SecretQuestion1Answer"]: question?.value,
											["SecretQuestion1"]: false,
										}));
									}}
									key={index}
									style={{
										margin: 4,
										backgroundColor: "rgba(0,0,0,0.04)",
										padding: 8,
										borderRadius: 6,
										cursor: "pointer",
										color: "rgba(0,0,0,0.7)",
									}}
								>
									{question?.value}
								</p>
							))}
						</div>
						{userData?.SecretQuestion1Answer && (
							<div style={{ marginBottom: 10, width: "100%" }}>
								<Input
									icon={username}
									value={userData?.SecurityAnswer1}
									placeholder={"Enter security Answer 1"}
									onChangeText={(val, key_name) => {
										setUserData((previous) => ({
											...previous,
											[key_name]: val,
										}));
									}}
									key_name={"SecurityAnswer1"}
								/>
							</div>
						)}

						<div
							onClick={() => {
								setUserData((previous) => ({
									...previous,
									["SecretQuestion2"]: !userData?.SecretQuestion2,
									["SecretQuestion1"]: false,
								}));
							}}
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								padding: 10,
								justifyContent: "space-between",
								width: "82%",
								backgroundColor: "rgba(20,97,156, 0.9)",
								borderRadius: 6,
								color: "#fff",
								cursor: "pointer",
								marginBottom: 10,
							}}
						>
							<span>
								{userData?.SecretQuestion2Answer
									? userData?.SecretQuestion2Answer
									: `Select secret question 2`}
							</span>
							<i class="fa fa-angle-down" aria-hidden="true"></i>
						</div>
						<div
							className={`secretQuestion ${userData?.SecretQuestion2 ? "" : "hidden"
								}`}
						>
							{secretQuestions2.map((question, index) => (
								<p
									onClick={() => {
										setUserData((previous) => ({
											...previous,
											["SecretQuestion2Answer"]: question?.value,
											["SecretQuestion2"]: false,
										}));
									}}
									key={index}
									style={{
										margin: 4,
										backgroundColor: "rgba(0,0,0,0.04)",
										padding: 8,
										borderRadius: 6,
										cursor: "pointer",
										color: "rgba(0,0,0,0.7)",
									}}
								>
									{question?.value}
								</p>
							))}
						</div>
						{userData?.SecretQuestion2Answer && (
							<div style={{ marginBottom: 10, width: "100%" }}>
								<Input
									icon={username}
									value={userData?.SecurityAnswer2}
									placeholder={"Enter security Answer 2"}
									onChangeText={(val, key_name) => {
										setUserData((previous) => ({
											...previous,
											[key_name]: val,
										}));
									}}
									key_name={"SecurityAnswer2"}
								/>
							</div>
						)}

						<Input
							icon={password}
							value={userData?.currentPassword}
							placeholder={"Enter current password"}
							onChangeText={callHash}
							key_name={"currentPassword"}
							type="password"
						/>
						<div style={{ marginTop: 20 }}>
							<Button name={"SUBMIT"} onClick={onChangeSecurityQuestion} />
						</div>
					</div>
				</div>
			</div>
		);
	};

	const ChangePasswordModal = () => {
		return (
			<div className="modal-wrapper">
				<div
					style={{
						backgroundColor: "#fff",
						width: 540,
						paddingBottom: 60,
						paddingTop: 20,
						borderRadius: 12,
					}}
				>
					<i
						onClick={() => setChangePassword(false)}
						className="fa fa-times-circle fa-lg"
						style={{
							color: "#e98936",
							display: "flex",
							justifyContent: "flex-end",
							paddingRight: 20,
							cursor: "pointer",
						}}
					/>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<h2
							className="gradient-text"
							style={{
								color: "#e98936",
								marginBottom: 30,
								textTransform: "uppercase",
							}}
						>
							Change Password
						</h2>
						<Input
							icon={username}
							value={question}
							disable={true}
							fontWeight="bold"
						/>
						<Input
							icon={username}
							value={userData?.answer}
							placeholder={"Enter your security question's answer"}
							onChangeText={(val, key_name) => {
								setUserData((previous) => ({
									...previous,
									[key_name]: val,
								}));
							}}
							key_name={"answer"}
							disable={isPasswordSecurity}
						/>
						{isPasswordSecurity && (
							<>
								<>
									<Input
										value={passwordValue}
										placeholder={"Enter New Password"}
										onChangeText={callHash}
										key_name={"password"}
										type={"password"}
									/>
									<span
										style={{
											fontSize: 12,
											textAlign: "left",
											width: "75%",
											margin: "auto",
											marginBottom: 20,
											color: "gray",
										}}
									>
										1 upper case character, 1 lower case character, 1 digit and
										1 special character other than underscore, It must contain
										at least 8 characters and no more than 32 characters.
									</span>
								</>
								<>
									<Input
										value={cpasswordValue}
										placeholder={"Confirm Password"}
										onChangeText={callHash}
										key_name={"confirmPassword"}
										type={"password"}
									/>
								</>
							</>
						)}
						<div style={{ marginTop: 20 }}>
							<Button
								name={isPasswordSecurity ? "SUBMIT" : "VERIFY"}
								onClick={() => {
									isPasswordSecurity ? onChangePassword() : Checkanswer();
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	};
	const DeleteAccountModal = () => {
		return (
			<div className="modal-wrapper">
				<div
					style={{
						backgroundColor: "#fff",
						width: 540,
						paddingBottom: 60,
						paddingTop: 20,
						borderRadius: 12,
					}}
				>
					<i
						onClick={() => setDeleteAccount(false)}
						className="fa fa-times-circle fa-lg"
						style={{
							color: "#e98936",
							display: "flex",
							justifyContent: "flex-end",
							paddingRight: 20,
							cursor: "pointer",
						}}
					/>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							padding: isMobile ? '0 20px' : '0 40px',
							textAlign:'center'
						}}
					>
						<h2
							className="gradient-text"
							style={{
								color: "#e98936",
								textTransform: "uppercase",
							}}
						>
							{en ? "Delete Account":"खाता हटाएं"} ?
						</h2>
							{en ? "Are you sure you want to delete your account? This action is permanent and cannot be undone. Once deleted, all your data will be permanently removed from our systems within 30 days and cannot be restored. Please note that if you delete your account, you will not be able to use this email address to create a new account in the future.":"क्या आप वाकई अपना खाता हटाना चाहते हैं? यह क्रिया स्थायी है और इसे पूर्ववत नहीं किया जा सकता। एक बार हटाए जाने के बाद, आपके सभी डेटा को हमारे सिस्टम से 30 दिनों के भीतर स्थायी रूप से हटा दिया जाएगा और इसे पुनः प्राप्त नहीं किया जा सकेगा। कृपया ध्यान दें कि यदि आप अपना खाता हटाते हैं, तो आप भविष्य में इस ईमेल पते का उपयोग करके नया खाता नहीं बना पाएंगे।"}
						<div style={{ marginTop: 20, display: 'flex', justifyContent: 'space-around', width: '100%' }}>
							<Button
								name={"No"}
								onClick={() => setDeleteAccount(false)}
								customStyle={{ maxWidth: 100, backgroundColor: COLORS.orangeShade, borderRadius: 8 }}

							/>
							<Button
								name={"Yes"}
								onClick={() => { setDeleteAccount(false); setDeleteConfirmModal(true) }}
								customStyle={{ maxWidth: 100, backgroundColor: COLORS.blue, borderRadius: 8 }}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const DeleteConfirmModal = () => {
		return (
			<div className="modal-wrapper">
				<div
					style={{
						backgroundColor: "#fff",
						width: 540,
						paddingBottom: 60,
						paddingTop: 20,
						borderRadius: 12,
					}}
				>
					<i
						onClick={() => setDeleteConfirmModal(false)}
						className="fa fa-times-circle fa-lg"
						style={{
							color: "#e98936",
							display: "flex",
							justifyContent: "flex-end",
							paddingRight: 20,
							cursor: "pointer",
						}}
					/>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							padding: isMobile ? '0 20px' : '0 40px'
						}}
					>
						<h2
							className="gradient-text"
							style={{
								color: "#e98936",
								textTransform: "uppercase",
							}}
						>
							Confirm Password
						</h2>
						<Input
							value={passwordValue}
							placeholder={"Enter Current Password"}
							onChangeText={callHash}
							key_name={"password"}
							type={"password"}
						/>

						<Captcha
							captcha={captcha}
							refreshCaptcha={refreshCaptcha}
							userInput={userInput}
							handleInputChange={handleInputChange}
						/>
						<div style={{ marginTop: 20 }}>
							<Button
								name={"Send OTP"}
								onClick={() => handleDeleteAccount()}
								customStyle={{ backgroundColor: COLORS.blue, borderRadius: 8 }}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const DeleteConfirmModalOTP = () => {
		return (
			<div className="modal-wrapper">
				<div
					style={{
						backgroundColor: "#fff",
						width: 540,
						paddingBottom: 60,
						paddingTop: 20,
						borderRadius: 12,
					}}
				>
					<i
						onClick={() => setDeleteConfirmModalOTP(false)}
						className="fa fa-times-circle fa-lg"
						style={{
							color: "#e98936",
							display: "flex",
							justifyContent: "flex-end",
							paddingRight: 20,
							cursor: "pointer",
						}}
					/>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							padding: isMobile ? '0 20px' : '0 40px'
						}}
					>
						<h2
							className="gradient-text"
							style={{
								color: "#e98936",
								textTransform: "uppercase",
							}}
						>
							Enter OTP
						</h2>
						<Input
							icon={hash}
							value={userData?.del_acc_otp}
							placeholder={"Enter OTP"}
							onChangeText={(val, key_name) => {
								setUserData((previous) => ({
									...previous,
									["del_acc_otp"]: val,
								}));
							}}
							key_name={"del_acc_otp"}
						/>
						<div style={{ marginTop: 20 }}>
							<Button
								name={"Submit"}
								onClick={() => handleDeleteAccountOTP()}
								customStyle={{ backgroundColor: COLORS.blue, borderRadius: 8 }}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const VerifyMobileNumberModal = () => {
		return (
			<div className="modal-wrapper" style={{ zIndex: 9999999 }}>
				<div
					style={{
						backgroundColor: "#fff",
						width: 540,
						paddingBottom: 60,
						paddingTop: 20,
						borderRadius: 12,
					}}
				>
					<i
						onClick={() => {
							setVerifiNumberModal(false);
						}}
						className="fa fa-times-circle fa-lg"
						style={{
							color: "#e98936",
							display: "flex",
							justifyContent: "flex-end",
							paddingRight: 20,
							cursor: "pointer",
						}}
					/>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<h2
							className="gradient-text"
							style={{
								color: "#e98936",
								marginBottom: 30,
								textTransform: "uppercase",
							}}
						>
							OTP To Verify Mobile Number
						</h2>
						<div
							className="input-wrapper"
							style={{
								width: isMobile ? 240 : null,
								display: "flex",
								margin: "auto",
							}}
						>
							<input
								className="input"
								placeholder="Enter OTP"
								value={otpValue}
								onChange={handleOtpChange}
							/>
						</div>
						<span
							style={{
								marginRight: 20,
								fontSize: 12,
								fontWeight: "400",
								textAlign: "center",
								paddingLeft: 40,
								paddingRight: 40,
								marginTop: 10,
							}}
						>
							An OTP is sent to mobile number. OTP is valid for 04:55 mins.
						</span>
						<div style={{ marginTop: 20 }}>
							<Button name={"SUBMIT"} onClick={submitOTPVerifyNumber} />
						</div>
					</div>
				</div>
			</div>
		);
	};

	return (
		<div className="table-container">
			<div className="page-section">
				<span>{en ? "Change Account Settings" :"खाता सेटिंग बदलें"}</span>
			</div>
			{isLoading && <Loader />}
			<div className="setting-content-wrapper">
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						marginBottom: 10,
						cursor: "pointer",
					}}
				>
					<div
						className="input-icon"
						style={{ justifyContent: "center", display: "flex" }}
					>
						<img
							src={email}
							alt="email"
							style={{ padding: 0, margin: 0, width: 18 }}
						/>
					</div>
					<div
						className="text-input"
						style={{ display: "flex", alignItems: "center" }}
					>
						<span style={{ color: COLORS.grayBold, fontSize: '0.9rem' }}>{settingData?.email}</span>
					</div>
				</div>
				<Input
					icon={username}
					value={settingData?.mobile}
					disable={true}
					isSetting={true}
					item={settingData}
					onClick={VerifyMobileNumber}
				/>
				<div className="hr" />
				<SettingServiceComponent
					icon={password}
					text={en ? "Change Security Question?" : "सुरक्षा प्रश्न बदलें?"}
					onClick={() => {
						setUserData({});
						setChangeSecurityQuestion(true);
					}}
				/>
				<SettingServiceComponent
					icon={password}
					text={en ? "Change Password?":"पासवर्ड बदलें?"}
					onClick={() => setChangePassword(true)}
				/>
				<SettingServiceComponent
					icon={''}
					isExternalIcon={<i class="fa fa-user-times" aria-hidden="true"></i>}
					text={en ? "Delete Account ? ":"खाता हटाएं ? "}
					onClick={() => setDeleteAccount(true)}
				/>
			</div>

			{isChangeSecurityQuestion && ChangeSecurityQuestionModal()}
			{isChangePassword && ChangePasswordModal()}
			{isDeleteAccount && DeleteAccountModal()}
			{isDeleteConfirmModal && DeleteConfirmModal()}
			{isDeleteConfirmModalOTP && DeleteConfirmModalOTP()}


			{isVerifiNumberModal && VerifyMobileNumberModal()}
		</div>
	);
};

export default Setting;
