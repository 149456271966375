import React, { useEffect, useRef, useState } from "react";
import Input from "../../../components/Input";
import "./PageContainer.css";
import "./ReVerifyCertificate.css";

import email from "../../assets/images/username.png";
import Button from "../../../components/Button";
import choosefile from "../../assets/images/choosefile.png";

import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { toast } from "react-hot-toast";
import Loader from "../../../components/Loader";
import { v4 as uuidv4 } from "uuid";
import cashfree from "../../../util";
import { GetPaymentStatus } from "../../API";
import { useSelector } from "react-redux";
import { Redirection_url, ROOT } from "../../API/AppUrl";
import usePopupModal from "../../../components/popUpModal";
import COLORS from "../../../utility/colors";
import PhonelinkEraseIcon from '@mui/icons-material/PhonelinkErase';
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import InfoIcon from '@mui/icons-material/Info';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
const ReVerifyCertificate = () => {
	const defaultLayoutPluginInstance = defaultLayoutPlugin();
	const { settingData, locale } = useSelector((state) => state.user);
	const { ModalComponent, modalVisible, hideModal, showModal } = usePopupModal()
	const [isPhoneNumberVerify, setPhoneNumberVerify] = useState(true)
	const navigation = useNavigate()
	const isMobile = useMediaQuery({
		query: "(max-width: 820px)",
	});
	const [inputData, setInputData] = useState({});
	const fileInputRef = useRef(null);
	const fileInput = useRef(null);
	const [isChecked, setIsChecked] = useState(false);
	const [selectedFile, setSelectedFile] = useState(null);
	const [isLoading, setLoading] = useState(false);
	const [attachments, setAttachments] = useState([]);
	const handleChooseFileClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	const onChangeText = (value, key) => {
		setInputData((previous) => ({
			...previous,
			[key]: value,
		}));
	};
	const handleCheckboxChange = () => {
		setIsChecked(!isChecked);
	};

	const onClick = () => {
		handleSubmit();
	};

	useEffect(() => {
		if (!settingData?.mob_verify) {
			setPhoneNumberVerify(false)
			showModal(true)
		}
	}, [])


	// const handleVerify = async () => {
	// 	const { Email } = inputData;
	// 	if (Email && selectedFile) {
	// 		setLoading(true);
	// 		let token = localStorage.getItem("key");
	// 		var formdata = new FormData();
	// 		formdata.append("cert", fileInput.current);
	// 		formdata.append("mailid", Email);
	// 		formdata.append("form65b", isChecked);
	// 		var requestOptions = {
	// 			method: "POST",
	// 			headers: {
	// 				Authorization: `Token ${token}`,
	// 			},
	// 			body: formdata,
	// 		};

	// 		try {
	// 			const response = await fetch(
	// 				"https://apisyn.certifi.biz/verifychecksum/",
	// 				requestOptions
	// 			);
	// 			const result = await response.json();
	// 			if (result?.response === "ok") {
	// 				setLoading(false);
	// 				toast.success(
	// 					`${
	// 						isChecked
	// 							? "Form65b accepted successfully"
	// 							: `Submitted your request. Verified mail will be sent to ${Email}`
	// 					}`
	// 				);
	// 				setTimeout(() => {
	// 					window.location.reload();
	// 				}, 1000);
	// 			} else {
	// 				setLoading(false);
	// 				toast.error(result.response);
	// 			}
	// 		} catch (error) {
	// 			setLoading(false);
	// 			console.error("Error:", error);
	// 			toast.error("An error occurred. Please try again.");
	// 		}
	// 	} else {
	// 		toast.error("All fields are mandatory");
	// 	}
	// };

	const handleFileChange = async (event) => {
		const newFiles = Array.from(event.target.files); // Convert FileList to an array

		const totalSize = newFiles.reduce((sum, file) => sum + file.size, 0); // Calculate the size of new files only

		const existingFilesSize = attachments.reduce(
			(sum, file) => sum + file.size,
			0
		); // Get the size of already stored files
		const combinedSize = totalSize + existingFilesSize; // Total combined size

		if (combinedSize > 5 * 1024 * 1024) {
			// Check if the combined size exceeds 5MB
			toast.error("Total file size should be less than 5MB");
			return;
		} else {
			try {
				const filePromises = newFiles.map((file) => convertToBase64(file)); // Convert only new files to Base64
				const base64Files = await Promise.all(filePromises); // Wait for all conversions to complete

				const formattedFiles = base64Files.map((base64, index) => ({
					name: newFiles[index].name,
					data: `data:${newFiles[index]?.type};base64,${base64}`,
				}));
				console.log(JSON.stringify(formattedFiles), "formattedFiles");
				// Update the state with new attachments
				setAttachments(formattedFiles);
			} catch (error) {
				console.error("Error converting files to Base64:", error);
				toast.error("An error occurred while processing files.");
			}
		}
	};

	const convertToBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result.split(",")[1]); // Get the Base64 part of the data URL
			reader.onerror = (error) => reject(error);
		});
	};

	const handleRedirect = (session_id) => {
		let paymentOptions = {
			paymentSessionId: session_id,
		};
		cashfree.checkout(paymentOptions).then((result) => {
			console.log(result);
		});
	};

	const handleSubmit = async () => {
		setLoading(true);
		const orderId = uuidv4();
		const jsonObject = {
			purpose: "2",
			// orderid: orderId,
			// return_url: `${Redirection_url}/reverify-certificate?orderId=${orderId}`,
			// notify_url: `${Redirection_url}/reverify-certificate?orderId=${orderId}`,
			return_url: `${Redirection_url}/reverify-certificate?orderId=`,
			notify_url: `${Redirection_url}/reverify-certificate?orderId=`,
			mailid: inputData?.Email,
			form66: isChecked ? "1" : "0",
			attachments: attachments,
		};
		console.log(JSON.stringify(jsonObject, null, 4));
		console.log(orderId);
		const token = await localStorage.getItem("key");
		const requestOptions = {
			method: "POST",
			headers: {
				Authorization: `Token ${token}`,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(jsonObject),
		};

		try {
			const response = await fetch(
				`${ROOT}/beforePayment/`,
				requestOptions
			);
			const contentType = response.headers.get("Content-Type");
			const rawText = await response.text();
			console.log("Raw Response:", rawText);

			let result = contentType.includes("application/json")
				? JSON.parse(rawText)
				: { response: rawText };
			if (result?.order_id) {
				handleRedirect(result.id);
			}else{
				console.log(result)
				toast.success(result?.response)
				setTimeout(() => {
					window.location.reload()
				}, 1000);
			}
		} catch (error) {
			console.log("Submission Error:", error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		const url = window.location.href;
		const orderId = url.split("orderId=")[1];
		if (orderId) {
			getPaymentDetails(orderId);
		}
	}, []);

	const getPaymentDetails = (orderId) => {
		setLoading(true);
		try {
			GetPaymentStatus(orderId).then((response) => {
				setLoading(false);
				if (response.order_info?.order_status) {
					setInputData({});
					setAttachments([]);
					setIsChecked(false);
					toast.success("Payment successfully done \n" + response?.resp);
				} else {
					toast.error("Something went wrong! \n" + response?.resp);
				}
			});
		} catch (error) {
			toast.error(JSON.stringify(error));
		} finally {
			setLoading(false);
		}
	};

	const en = locale?.languageType === "English";

	return (
		<div className="profile-page-container">
			<div className="profile-page-section">
				<span>{en ?"Reverify Certificate":"प्रमाण पत्र की पुनः पुष्टि करें"}</span>
			</div>
			{isLoading && <Loader />}
			<div className="form-container" style={{height:'66vh', paddingTop:40}}>
			<ModalComponent>
				<div style={{ background: '#fff', padding: '40px 40px', borderRadius: 12, width: isMobile ? window.innerWidth / 1.8  :window.innerWidth / 4, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
					<PhonelinkEraseIcon style={{ width: isMobile ? 70 : 100, height: isMobile ? 70 : 100, color: COLORS.redText }} />
					<span style={{ fontSize: '1.1rem', color: COLORS.graniteGrey, marginTop: 20, textAlign: 'center' }}>Verify phone number to <span onClick={()=>navigation("/setting")} style={{ textDecorationLine: 'underline', color: COLORS.blue, cursor: 'pointer' }}>proceed!</span></span>
				</div>
			</ModalComponent>
				<Input
					value={inputData?.Email || ""}
					icon={email}
					placeholder={"Email id"}
					key_name={"Email"}
					onChangeText={onChangeText}
				/>
				<div
					style={{
						display: "flex",
						width: "100%",
						justifyContent: "center",
					}}
				>
					<div className="reverify-file-wrapper">
						{/* <div className="no-file-choosen">
							<span
								style={{
									color: attachments?.length > 0 ? "green" : "#B6B5B5",
									fontSize: 16,
								}}
							>
								{attachments?.length > 0
									? `✅ File Selected`
									: `No File Choosen ..`}
							</span>
						</div> */}
						<div
							className="choose-file"
							onClick={() => handleChooseFileClick()}
						>
							<img
								src={choosefile}
								alt={"choose file"}
								style={{ width: 18, marginRight: 20 }}
							/>
							<span>{en ? "Choose File":"फ़ाइल चुन"} {attachments?.length > 0
								? `✅`
								: ''}</span>
							<input
								type="file"
								accept=".pdf"
								ref={fileInputRef}
								style={{ display: "none" }}
								onChange={handleFileChange}
							/>
						</div>
						<div className="form65b">
							<input
								type="checkbox"
								checked={isChecked}
								onChange={handleCheckboxChange}
							/>
{	en ? 						<span title="Bharatiya Sakshya Adhiniyam, 2023 (BSA), recognizes the significance of electronic or digital records and has dedicated provisions in Chapter 5 to address the admissibility and proof of electronic evidence.">Would you like reverification certificate under Section <strong style={{ color: COLORS.black }}>63 BSA</strong>?<span onClick={() => showModal(true)}><InfoIcon style={{ color: COLORS.blue, width: 12, height: 12, cursor: 'pointer' }} /></span></span>:
							<span title="Bharatiya Sakshya Adhiniyam, 2023 (BSA), recognizes the significance of electronic or digital records and has dedicated provisions in Chapter 5 to address the admissibility and proof of electronic evidence.">क्या आप धारा <strong style={{ color: COLORS.black }}>63 BSA</strong> के अंतर्गत पुनः पुष्टि प्रमाणपत्र लेना चाहेंगे?<span onClick={() => showModal(true)}><InfoIcon style={{ color: COLORS.blue, width: 12, height: 12, cursor: 'pointer' }} /></span></span>}
						</div>
						{isPhoneNumberVerify&&<ModalComponent>
							<div style={{ background: '#fff', padding: '40px 20px', borderRadius: 12, width: isMobile ?window.innerWidth/1.2 :  window.innerWidth / 3, position: 'relative' }}>
								<div onClick={() => hideModal(false)} style={{ position: 'absolute', top: 10, right: 10, cursor: 'pointer' }}><HighlightOffIcon style={{ color: COLORS.blue }} /></div>
								<span style={{ fontSize: '0.9rem', lineHeight: 1.4, letterSpacing: 1.2, color: COLORS.graniteGrey }}>
									Bharatiya Sakshya Adhiniyam, 2023 (BSA), recognizes electronic or digital records and has dedicated provisions in Chapter 5 to address the admissibility and proof of electronic evidence. In the event that a customer relies on certificates provided by Synerthink Solutions LLP (“Synerthink”), the customer is solely responsible for obtaining independent legal advice and/or representation to ensure compliance with applicable laws. Synerthink disclaims all liability for any prejudice or harm that may arise if the customer fails to obtain such legal advice or representation. This disclaimer extends to any consequences resulting from the customer’s reliance on the certificates without appropriate legal review.
								</span>
							</div>
						</ModalComponent>}
					</div>
				</div>
				<div className="reverify-button-wrapper">
					<Button name={en ? "Verify":"पुष्टि करें"} onClick={onClick} />
				</div>
			</div>

			{/* {attachments?.length > 0 && (
				<div className="reverify-pdf-container">
					<Worker
						workerUrl={`https://unpkg.com/pdfjs-dist@2.15.349/build/pdf.worker.min.js`}
					>
						<Viewer fileUrl={selectedFile} />
					</Worker>
				</div>
			)} */}
		</div>
	);
};

export default ReVerifyCertificate;
