// src/app/screens/dashboard/TermsandConditions/index.js
import React from "react";
import PageWrapper from "../common/components/PageWrapper";
import PageHeader from "../common/components/PageHeader";
import styles from "../PrivacyPolicy/styles/privacypolicy.module.css";
import COLORS from "../../../../utility/colors";
const DashboardTermsandConditions = () => {
	return (
		<PageWrapper>
			<div className={styles.privacyParentContainer}>
				<div className={styles.privacyContainer}>
					<PageHeader string={"Terms of Use"} />
					<div>
						<section>
							<h3>A. <span style={{textDecorationLine:'underline'}}>INTRODUCTION</span></h3>

							<h3>1. ACCEPTANCE OF TERMS</h3>
							<ol>
								<li>
									<p>
										Welcome to Certifi.biz, a certified communication services (“Service(s)”) platform of M/s. Synerthink Solutions LLP. Our Services are made available on <a href="https://certifi.biz/" style={{ color: COLORS.blue }}>https://certifi.biz/</a> (<b>“Website”</b>) and/or <a href="https://play.google.com/store/apps/details?id=com.certifi" target="_blank" style={{ color: COLORS.blue }}>Application</a> (collectively referred to as <b>“Platform(s)”</b>). The following terms and conditions (<b>“Terms of Use”</b>) govern your access to and use of our Services, any content on our Platforms, features, technologies, and software that our Platforms offer and constitute a legally binding agreement between the person accessing and/or using the same (hereinafter referred to as <b>“User”</b>, <b>“You”</b>, or <b>“Your”</b>) and Synerthink (defined below) (including its affiliates and subsidiaries). By accessing or using our Platform(s), you agree to comply with and be bound by these Terms of Use.
									</p>
								</li>
								<li>
									<p>
										The Website is operated and owned by M/s. Synerthink Solutions LLP (<b>“Synerthink”</b>, <b>“We”</b>/ <b>“Us”</b> / <b>“Our”</b>), a company incorporated under the Companies Act, 2013 and having its registered office at Gurugram, Haryana, India.
									</p>
								</li>
								<li>
									<p>
										These Terms of Use shall apply to all visitors, Users, and others who access the Services. These Terms of Use shall be read along with our Privacy Policy (please refer to the Privacy Policy section for more information).
									</p>
								</li>
								<li>
									<p>
										We reserve the right to change these Terms of Use from time to time without prior notice. You acknowledge and agree that it is Your responsibility to review these Terms of Use periodically to familiarize yourself with any modifications. Your continued use of this Services after such modifications will constitute acknowledgment and agreement of the modified terms and conditions.
									</p>
								</li>
								<li>
									<p>
										Use of Services is available only to persons who can form a legally binding contract under the laws of India or any other country as applicable.
									</p>
								</li>
							</ol>
						</section>

						<section>
							<h3>2. OUR SERVICES</h3>
							<ol>
								<li>
									<p>
										By using our Platform(s) and Services, you represent and warrant that you have the full right, power, and authority to agree to and be bound by these Terms of Use and to fully perform all your obligations hereunder. You further represent that you are not prohibited or prevented by any applicable law for the time being in force or any other form restraining you from using our Services.
									</p>
								</li>
								<li>
									<p>
										This Platform and the Services provided through this Platform are available only to Indian citizens, non-resident Indians, and persons of Indian origin and may not be available in all locations worldwide.
									</p>
								</li>
							</ol>
						</section>

						<section>
							<h3>B. <span style={{textDecorationLine:'underline'}}>USE OF OUR SERVICES</span></h3>
							<h3>3. RESPONSIBLE USE</h3>
							<ol>
								<li>
									<p>
										We reserve the right to provide our Services to you. Synerthink grants you no license or right in the Platform(s).
									</p>
								</li>
								<li>
									<p>
										You are responsible for maintaining the confidentiality of any information. Accordingly, You are responsible for all activities that occur under Your account(s).
									</p>
								</li>
								<li>
									<p>Proper Use of Our Platform(s)</p>
									You hereby agree to refrain from directly or indirectly engaging in the following actions with regard to the Platform(s):
									<ol type="a">
										<li>Make the access to the Platform(s) available to anyone other than you or permit direct or indirect access to or use of the Platform(s);</li>
										<li>Decompiling, reverse engineering, disassembling, or using any other means to attempt to discover any algorithm, source code, trade secret, or applications underlying the Platform(s);</li>
										<li>Adapting, modifying, combining, or creating derivative works or enhancements of the Platform(s);</li>
										<li>Encumbering, licensing, sublicensing, transferring, distributing, renting, leasing, sub-leasing, time-sharing, or using the Platform(s) for the benefit of any third party;</li>
										<li>Seeking unauthorized access to the Platform(s) or its network;</li>
										<li>Developing or generating a database of data using the Platform(s);</li>
										<li>Disabling, circumventing, disrupting, or attempting to disrupt the proper operation of the Platform(s), including actions that overload the system or undermine any security or authentication device, mechanism, protocol, or procedure implemented in the Platform(s);</li>
										<li>Building or assist a third party in building or supporting, products or services competitive to the Platform(s) or copy any ideas, features, functions or graphics of the Platform(s);</li>
										<li>Misrepresenting your affiliation with or impersonating any person or entity;</li>
										<li>Harassing or abusing our personnel or representatives or agents providing services on behalf of Synerthink;</li>
										<li>Using or accessing the Platform(s) and/or its content for any unlawful, fraudulent, deceptive, malicious, or otherwise harmful or injurious purpose;</li>
										<li>Defacing, removing, obscuring, or altering any proprietary rights notices on any element of the Platform(s);</li>
										<li>Testing or probing the vulnerability of the Platform(s) or any systems or network infrastructure connected with the Platform(s), including without limitation stress testing or penetration testing;</li>
										<li>Advertising to, or solicitation of, other Users to buy or sell any products or services through our Services;</li>
										<li>Transmitting any chain letters or junk email using our Platform(s).</li>
									</ol>
								</li>
								<li>
									<p>Content Standards and Disclaimers</p>
									You are solely responsible for all Content (including text, images, audio, video, and other materials) that You upload, share, or transmit through the Platform(s). You represent and warrant that Your Content:
									<ol type="a">
										<li>Complies with applicable laws, regulations, and these Terms of Use.</li>
										<li>Does not infringe on any third-party rights, including intellectual property and privacy rights.</li>
										<li>Is not defamatory, obscene, abusive, or otherwise objectionable as determined by Synerthink at its sole discretion.</li>
									</ol>
								</li>
								<li>You acknowledge that You do not work for, represent, or are engaged by on an employment or contractual basis with any direct competitor of the Platform(s)’s product offering.</li>
								<li>Synerthink reserves the right, but has no obligation, to review, monitor, and/or remove Content that, in its sole judgment, violates these Terms of Use. Synerthink may suspend or terminate Your access to the Platform(s) if Your Content is found to be in violation.</li>
								<li>Synerthink disclaims all responsibility for Content provided by You or any other Users. You agree to release Synerthink from any claims, damages, or liabilities arising out of or in connection with disputes over Content shared via the Platform(s).</li>
							</ol>
						</section>

						<section>
							<h3>4. USER ACCOUNT</h3>
							<ol>
								<li>
									<p>
										In consideration of Your use of the Services, You agree to: (a) maintain and promptly update any information You provide Us, to keep it accurate, current and complete; (b) maintain confidentiality and security of your password and identification; (c) notify Us immediately of any unauthorized use of Your account or other breach of security; (d) accept all responsibility for any and all activities that occur under Your account; and (e) accept all risks of unauthorized access to the data and any other information You provide Us.
									</p>
								</li>
								<li>If you opt to use Our Platform(s) through an associate account (referred to as <b>“Associate”</b>) for your organisation/group of organisations, then the main account holder (referred to as <b>“Main Account”</b>) will have the following accesses.
									<ol type="a">
										<li>Access to Certificates: The Main Account holder shall have the right to view all certificates associated with the linked Associate accounts, including both successful and unsuccessful communications.</li>
										<li>Access to Communications: The Main Account holder shall have full access to all communications carried out by the Associate accounts. This includes access to the Content of messages, transaction details, and any other relevant information.</li>
									</ol>
								</li>
								<li>If You register as a User using an email address associated with Your employer (<b>“Employer Entity”</b>), (a) You represent and warrant that You have the authority to bind the Employer Entity to these Terms of Use; (b) Your use of the Platform(s) will bind the Employer Entity to these Terms of Use; and (c) “You” and “Your” in these Terms of Use will refer to both You and the Employer Entity. </li>
							</ol>
						</section>

						<section>
							<h3>5. PAYMENTS</h3>
							<ol>
								<li>
									<p>The User shall pay the charges specified <a href="https://www.certifi.biz/charges" style={{color:COLORS.blue}}>here</a> (“Charges”). Except as otherwise specified herein, in the Charges, (a) Charges are based on the Services provided, and (b) Charges paid are non-refundable except as provided in Our Refund Policy available at <a href="https://www.certifi.biz/refund-policy" style={{ color: COLORS.blue }}>here</a>.</p>
								</li>
								<li>
									<p>Prepaid Payments</p>
									<ol type="a">
										<li>Users opting for the prepaid payment option shall pay for each Service transaction at the time of use. Payment must be completed through the available payment methods on the Platform(s) before the Service can be accessed.</li>
										<li>Accepted payment methods for prepaid transactions include [credit/debit cards, net banking, UPI], and any other method as made available by Synerthink from time to time.</li>
									</ol>
								</li>
								<li>Synerthink reserves the right to modify the available payment methods at any time, without prior notice to Users.</li>
								<li>All amounts payable by  Users in terms hereof shall be made without any deduction, set-off or counter claim and free and clear of any deduction or other charges of whatever nature imposed by any taxation or government authority save and except tax deduction at source as per the rate prescribed by the prevailing taxation laws.</li>
							</ol>
						</section>

						<section>
							<h3>6. DURATION OF ACCESS AND AVAILABILITY TO OUR SERVICES</h3>
							<ol>
								<li>
								<p>You agree that We may, at Our sole discretion, suspend or terminate Your access to all or part of our Services with or without notice and for any reason, including, without limitation, breach of these Terms of Use. Any suspected illegal, fraudulent or abusive activity may be grounds for terminating Your access to Our Services or Platform and may be referred to appropriate law enforcement authorities. Upon suspension or termination, Your right to use the Services provided by Us will immediately cease in accordance with these Terms of Use, and we reserve the right to remove or delete any information that you may have on file with Us, including any account or login information.</p>
								</li>
							</ol>
						</section>

						<section>
							<h3>7. ACCOUNT DELETION</h3>
							<ol>
								<li>You may initiate the deletion of your user account with Synerthink at any time. Upon receiving an account deletion request, it may take us up to 30 days to delete your account.</li>
								<li>During this 30 day period, you may request to revoke the deletion of your account by sending an email to support@synerthinksolutions.com.</li>
								<li>If the request is not revoked within this timeframe, the account and all associated data will be deleted in accordance with our Privacy Policy and as outlined in these Terms of Use. </li>
								<li>Please note that any information related to you and your account may not be deleted within the standard 30 day period in the following cases:
									<ol type="a">
										<li>If your data or information has been shared with any recipients, it will remain on their systems until they delete it;</li>
										<li>Where deletion within the standard period is not technically feasible, we will delete it as soon as possible; </li>
										<li>To investigate or prevent illegal activity or breaches of our Terms of Use, Privacy Policy, or any other terms or policies; </li>
										<li>To protect the safety and security of our users, services, or systems; or </li>
										<li>To comply with legal requirements, such as evidence preservation or lawful requests from authorities. </li>
									</ol>
								</li>
							</ol>
						</section>

						<section>
							<h3>8. COMMUNICATING WITH YOU</h3>
							<ol>
								<li><p>You hereby expressly solicit and invite Synerthink and/or its authorized personnel to communicate with You through any telecom resources using the contact information provided by You to explain, explicate, and clarify the various Services provided by Synerthink and to assist, aid, or support You in availing the said Services.</p></li>
								<li><p>If at any time, You wish to discontinue receiving communications (including, but not limited to, emails, SMS, and phone calls) from Synerthink, You may write an email to info@synerthinksolutions.com to revoke Your consent to receive such communication from Synerthink and/or its authorized personnel.</p></li>
								<li><p>In any event, You do hereby also unconditionally agree and undertake that this invitation and solicitation shall supersede any preferences set by you with or registration done with the Do Not Disturb (“DND Register”) / National Customer Preference Register (“NCPR”). Without prejudice to the aforesaid and in any event, by expressly inviting and soliciting the Services from Synerthink, You also unconditionally agree that Your rights under the Telecom Commercial Communications Customer Preference Regulations, 2010 or any subsequent amendments thereto or under NCPR, are kept in abeyance or remain extinguished till you expressly communicate for discontinuation of the relationship.</p> </li>
							</ol>
						</section>

						<section>
							<h3>9. ADMISSIBILITY OF CERTIFICATES</h3>
							<ol>
								<li>The certificate issued by Synerthink may be tendered in a court of law. However, Synerthink takes no responsibility in respect of the inadmissibility, relevance, accuracy, or any other prejudice that may be caused to You upon the certificate being tendered in a court of law. Notwithstanding the above, You agree to maintain and securely store original communications and certificates issued by Synerthink, including but not limited to emails, text messages, and other forms of correspondence, within Your internal systems. Such records shall be retained by You in a manner suitable for production in legal proceedings if required.</li>
							</ol>
						</section>

						<section>
							<h3>10. SECURITY OF OUR PLATFORM(S)</h3>
							<ol>
								<li>We do not guarantee that our Platform(s) will be secure or free from bugs or viruses.</li>
								<li>You are responsible for configuring your information technology, computer programs and platformy to access Our Platform(s). You should use your own virus protection software. </li>
								<li>You must not misuse our Platform(s) by knowingly introducing viruses, trojans, worms, logic bombs or other material that is malicious or technologically harmful. You must not attempt to gain unauthorized access to our Platform(s), the server on which our Platform(s)is stored or any server, computer or database connected to our Tool. By breaching this provision, You would commit a criminal offence,  and we will report any such breach to the relevant law enforcement authorities. In the event of such a breach, your right to use our Platform(s) will cease immediately.</li>
							</ol>
						</section>

						<section>
							<h3>11. PRIVACY POLICY</h3>
							<ol>
								<li>We care about data privacy and security. In consideration of Your use of our Services through our Platform(s), You agree that we may collect your personal data in accordance with our Privacy Policy found at <a href="https://www.certifi.biz/privacy-policy" style={{color:COLORS.blue}}>here</a>, which is incorporated herein by reference. </li>
								<li>Further, Synerthink uses cookies to enable us to track Your details and activities for each visit in accordance with Our Cookie Policy found at Cookie Policy.</li>
							</ol>
						</section>
						
						<section>
							<h3>12. INTELLECTUAL PROPERTY</h3>
							<ol>
								<li>Synerthink owns all intellectual property and proprietary rights in and to the Platform(s). You acknowledge and agree that Synerthink is the exclusive and rightful owner of, and shall retain all worldwide right, title, and interest in and to, all registered and unregistered, and any future registered, names, trademarks, trade dress, service marks, domain names, logos, or any derivation thereof, and all associated goodwill, of the Platform(s) (<b>“Licensed Marks”</b>). Nothing within these Terms of Use shall serve to transfer to You any ownership or proprietary interest in the Licensed Marks. </li>
								<li>The works of authorship contained in the Platform(s), including, but not limited to, all design, text, sound recordings, and images, are owned by Synerthink, unless otherwise expressly stated in these Terms of Use, and may not be copied, reproduced, transmitted, displayed, performed, distributed, rented, sublicensed, uploaded, posted, framed, altered, stored for subsequent use, or otherwise used in whole or in part in any manner without Synerthink’s prior written consent.</li>
								<li>Synerthink is committed to upholding copyright protection standards. You acknowledge and agree that You shall not copy, download, republish, post, display, reproduce, distribute, transmit the Platform(s) through any medium without obtaining any prior written consent and authorization from Synerthink. Your use of our Services under these Terms of Use does not grant You any right to copy, download, republish, post, display, reproduce, distribute, transmit the Platform(s). Any infringement of copyright by You will result in legal action.</li>
							</ol>
						</section>

						<section>
							<h3>13. THIRD-PARTY CONTENT</h3>
							<ol>
								<li>The Platform(s) may provide access to information from third parties, such as articles, advertisements, news updates, or any other information. You understand and acknowledge that Synerthink neither creates nor endorses such third party content.</li>
								<li>Where our Platform(s) contain links to other websites and resources provided by third parties, these links are provided for Your information only. Such links should not be interpreted as approval by Us of those linked websites or information You may obtain from them.</li>
								<li>We have no control over the contents of those websites or resources and You are advised to carefully read the terms of use of such websites and resources.  </li>
							</ol>
						</section>

						<section>
							<h3>14. NO WARRANTIES</h3>
							<ol>
								<li>While Synerthink takes reasonable care and skill to ensure the accuracy and timeliness of the information and certifications provided through its Services, occasional errors or discrepancies may occur. We commit to correcting any such errors but reserve the right to modify, update, delete, or rearrange any information or Services on our Platform(s) at any time, without prior notice to You.</li>
								<li> Please note that the Services provided by Synerthink are intended solely for reference and verification purposes and do not replace any independent legal or professional advice. Our Services and related materials are provided "as is," without any warranties, express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, non-infringement, or accuracy. </li>
								<li> Furthermore, We do not warrant that: (a) our Services will meet Your specific requirements; (b) the Services will be uninterrupted, timely, secure, or error-free; or (c) the results obtained from using our Services will be accurate or reliable </li>
							</ol>
						</section>

						<section>
							<h3>15. INDEMNITY</h3>
							<ol>
								<li>You agree to indemnify and hold Synerthink, its subsidiaries, associates, officers, agents, and other partners and employees, fully indemnified and hold harmless from any loss, liability, claim, cost, expense, judgement or demand, including reasonable attorney’s fees, made by any third party due to or arising out of: 
									<ol type="a">
										<li>Your use or misuse of the Service or any content thereon, including without limitation of the foregoing;</li>
										<li>Any action You take which is in violation of these Terms of Use;</li>
										<li>Any third-party claims asserting that Your Content infringes upon their rights and/or against any applicable law including but not limited to intellectual property rights, payment of statutory dues and taxes, claim of libel, defamation, violation of rights of privacy;</li>
										<li>Your use of the Platform(s) and any Content You transmit;</li>
									</ol>
								</li>
								<li>This provision shall survive the termination, cancellation, or expiration of Your access to our Platform(s) or Services and shall remain in effect for as long as necessary to enforce any rights or obligations arising from Your use of our Platform(s) or Services.</li>
							</ol>
						</section>

						<section>
							<h3>16. LIMITATION OF LIABILITY</h3>
							<ol>
								<li>
									<p>
										Under no circumstances shall Synerthink, its directors, officers, employees, or agents (collectively, the <b>“Protected Parties”</b>), be liable to any party for any direct, indirect, special, incidental, consequential, exemplary, or punitive damages arising from, or directly or indirectly related to, the Services and/or our Platform(s), including but not limited to issues related to the use or inability to use the Services and/or the Platform(s), or the content, materials, and functions thereof. This also extends to the use of any linked sites or resources, or any claims stemming from errors, omissions, or inaccuracies in our Services and/or Platform(s), or an inoperative or problematic email address and phone number provided by You while availing our Services. Further, this includes damages resulting from the use, download, or access to any materials, information, products, or Services, such as lost profits, business interruption, lost savings, or loss of data, both their own and that of the recipient, as well as the consequences that may arise as a result, even if we are advised of the possibility of such damages.
									</p>
								</li>
								<li>
									<p>
										The Protected Parties shall not be liable for:
										<ul>
											<li>Any defects or malfunctions in the Platform(s);</li>
											<li>Any information posted, transmitted, exchanged, or received on or through the Platform(s);</li>
											<li>Any unauthorized access to or alteration of your Content or personal data;</li>
											<li>Any other claims arising from your use or non-usage of the Platform(s).</li>
										</ul>
									</p>
								</li>
								<li>
									<p>You assume all responsibility and risk for the use of the Platform(s) and/or our Services.</p>
								</li>
								<li>
									<p>
										Notwithstanding anything contained in these Terms of Use, the cumulative maximum liability of Synerthink, its directors, officers, employees, affiliates, or agents, whether in contract or tort or damages or indemnification claims or negligence, by statute or otherwise, shall be limited to payment of incurred and suffered direct damages only and shall in no event exceed the amount paid by You to Synerthink for the last invoiced Services availed by You.
									</p>
								</li>
								<li>
									<p>
										Synerthink shall in no event be liable or responsible for any activities undertaken and/or engaged by any of its employees, directors, representatives, agents or any person(s) employed/appointed by Synerthink, in their personal capacity and acts and deeds done by such persons beyond their roles and responsibilities for which they are employed/appointed by Synerthink.
									</p>
								</li>
							</ol>
						</section>

						<section>
							<h2>17. CONFIDENTIALITY</h2>
							<ol>
								<li>
									<p>Synerthink is committed to maintaining the confidentiality of all information provided by You. However, Synerthink reserves the right to disclose such information as required by applicable law.</p>
								</li>
								<li>
									<p>
										By registering on Our Platform(s) and/or availing our Services and agreeing to these Terms of Use, You authorize Synerthink to store and process the information You share through Our Platform(s) for the purpose of certification and re-verification for the duration necessary to complete these Services. The certificates issued by Synerthink will be sent to the User’s email address and shall remain accessible to the User unless deleted by the User from the User’s records.
									</p>
								</li>
								<li>
									<p>
										It is solely Your responsibility to obtain any necessary consent from the recipient of any communication sent via our Platform(s) for the use of their information. Synerthink shall not be liable, and shall be held harmless, for any failure to obtain proper consent from the recipient, or for any issues arising from the lack of such consent.
									</p>
								</li>
							</ol>
						</section>

						<section>
							<h2>C. <span style={{textDecorationLine:'underline'}}>MISCELLANEOUS</span></h2>

							<h3>18. GOVERNING LAW AND JURISDICTION</h3>
							<ol>
								<li>
									<p>
										The Services are controlled by Us from our offices located in India. It may be accessible from selected countries around the world. As each country has laws that may differ from those of India, by accessing our Services, You agree that the statutes and laws of India without regard to the conflict of laws will apply to all matters relating to the use of the Services. Any issues arising out of use of Our Services and any conflict with regard to these Terms of Use and Privacy Policy shall be governed and construed in accordance with the laws of India and subject to clause 18 below. The courts of Delhi, India shall have the exclusive jurisdiction for deciding any disputes related to these Terms of Use. You hereby agree to the jurisdiction by such courts and waive any jurisdictional, venue, or inconvenient forum objections to such courts. Our failure to enforce any right or provision of these Terms of Use will not be considered a waiver of those rights.
									</p>
								</li>
							</ol>

							<h3>19. DISPUTE RESOLUTION</h3>
							<ol>
								<li>
									<p>
										If any dispute or difference of any kind whatsoever shall arise between You and Synerthink in connection with or arising out of these Terms of Use, Cookie Policy, and/or Privacy Policy (whether before or after the termination) the concerned representatives of the parties shall promptly and in good faith negotiate with a view to an amicable resolution and settlement of the dispute. In the event no amicable resolution or settlement is reached within a period of 30 (thirty) days, such dispute or difference shall be referred to a sole arbitrator mutually appointed by the Parties or, upon the failure of the Parties to agree upon a sole arbitrator, within a period of 10 (ten) days, each Party shall appoint 1 (one) arbitrator each and the 2 (two) appointed arbitrators shall appoint the third arbitrator who shall act as the presiding arbitrator. Arbitration shall be conducted in accordance with the provisions of the Indian Arbitration and Conciliation Act, 1996 and any amendments thereof. The arbitration proceedings shall be held at Delhi, India. The existence of any dispute or difference or the initiation or continuance of the arbitration proceedings shall not postpone or delay the performance by the parties of their respective obligations pursuant to these Terms of Use. It is agreed that the arbitrators shall also determine and make an award as to the costs of the arbitration proceedings. Notwithstanding anything contained herein, the Parties shall have a right to institute legal proceedings to prevent any continuing breach of the provisions of these Terms of Use to seek an injunctive or any other specific relief.
									</p>
								</li>
							</ol>

							<h3>20. NOTICE</h3>
							<ol>
								<li>
									<p>
									We may provide any notice to You under these Terms of Use by sending a message to the email address then associated with user account. The notices provided by us by email will be considered delivered when the email is sent. It is Your responsibility to keep Your and the recipient’s email address and phone number current. You will be deemed to have received any email sent to the email address then associated with Your account when we send the email, whether or not You actually receive the email. To give Us notice under these Terms of Use, You must contact Us by sending an email to the below email address. Notices provided by email or certified mail will be considered delivered 3 (three) business days after they are sent.
									</p>
									<ul>
										<li>E-mail: support@synerthinksolutions.com</li>
									</ul>
								</li>
							</ol>

							<h3>21. FORCE MAJEURE</h3>
							<ol>
								<li>
									<p>
									Except for payment obligations for services already rendered, non-performance by either Party of these Terms of Use shall be excused to the extent that performance is rendered impossible by a strike, acts of God, governmental acts or restrictions, failure of suppliers, acts of war or terrorism, or any other reason where failure to perform is beyond the reasonable control of the nonperforming party. The time for performance of this Terms of Use shall be extended for a period equal to the duration of such events.
									</p>
								</li>
							</ol>

							<h3>22. SEVERABILITY</h3>
							<ol>
								<li>
									<p>
									If any provision of the Terms of Use is invalid under any applicable statute or rule of law, it is to that extent to be deemed omitted, and all other terms and provisions of the Terms of Use shall nevertheless remain in full force and effect so long as the economic or legal substance of the transactions contemplated hereby is not affected in any manner materially adverse to any Party. Upon such determination that any term or other provision is invalid, illegal or incapable of being enforced, Synerthink shall modify the Terms of Use so as to effectuate the original intent as closely as possible in an acceptable manner in order that the transactions contemplated hereby are consummated as originally contemplated to the greatest extent possible.
									</p>
								</li>
							</ol>

							<h3>23. RELATIONSHIP OF PARTIES</h3>
							<ol>
								<li>
									<p>Synerthink and You are not employees, agents, partners or joint venturers of each other. You shall not have the right to enter into any agreement on Our behalf.</p>
								</li>
							</ol>

							<h3>24. ENTIRE TERM</h3>
							<ol>
								<li>
									<p>The entire understanding between Synerthink and You hereto relating to the subject matter hereof is contained herein and we make no warranties, representations or undertakings hereto except as expressly provided herein.</p>
								</li>
							</ol>

							<h3>25. ASSIGNMENT</h3>
							<ol>
								<li>
									<p>Notwithstanding anything contained herein, You agree and covenant that we may, at Our sole discretion, be entitled to assign its rights and obligations under this Terms of Use to any of its affiliates or to any entity, and in such case, such affiliate or entity shall be entitled to enforce these Terms of Use.</p>
								</li>
							</ol>

							<h3>26. SURVIVAL</h3>
							<ol>
								<li>
									<p>
										Notwithstanding the termination, expiration, or suspension of these Terms of Use, or the cancellation, deletion of Your account, or expiration of Your access to our Platform(s) or Services, any provisions of these Terms of Use that by their nature are intended to survive shall remain in full force and effect. This includes, but is not limited to, provisions relating to Responsible Use (clause 3), Intellectual Property (clause 12), No Warranties (clause 14), Indemnity (clause 15), Limitation of Liability (clause 16), Confidentiality (clause 17), Governing Law and Jurisdiction (clause 18), Dispute Resolution (clause 19), and Notice (clause 20).
									</p>
								</li>
							</ol>

							<h3>27. CONTACT INFORMATION</h3>
							<ol>
								<li>
									<p>If You have any questions or comments about our Terms of Use as outlined above, You can contact us at:</p>
									<ul>
										<li>Email Address: support@synerthinksolutions.com</li>
									</ul>
								</li>
							</ol>
						</section>
					</div>
				</div>
			</div>
		</PageWrapper>
	);
};

export default DashboardTermsandConditions;
