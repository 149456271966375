// src/app/screens/dashboard/TermsandConditions/index.js
import React from "react";
import PageWrapper from "../common/components/PageWrapper";
import PageHeader from "../common/components/PageHeader";
import styles from "../PrivacyPolicy/styles/privacypolicy.module.css"

const CookiePolicy = () => {
    return (
        <PageWrapper>
            <div className={styles.privacyParentContainer}>
                <div className={styles.privacyContainer}>
                    <PageHeader string={"Cookie Policy"} />
                    <div>

                        <section>
                            <p>
                                This cookie policy (“Cookie Policy”) explains how M/s. Synerthink Solutions LLP (“Synerthink”, “we”, “us”, or “our”) uses cookies to enhance the visitors' experience of the Website (defined below).
                            </p>
                            <p>
                                This Cookie Policy covers the use of cookies on <a href="https://web.certifi.biz/">https://web.certifi.biz/</a> (“Website”) and should be read together with our Privacy Policy <a href="[insert hyperlink of Synerthink’s Privacy Policy]">Privacy Policy</a> and Terms of Use <a href="[insert hyperlink of Synerthink’s Terms of Use]">Terms of Use</a>.
                            </p>
                        </section>

                        <section>
                            <h3>What is a Cookie?</h3>
                            <p>
                                A cookie is a small web file that a web browser creates and stores in the device used by a visitor either temporarily or permanently in order to make a web page more responsive to the requirements of such visitor. Our Privacy Policy describes how the Website collects and uses information from visitors of our Website, and one way we collect such information is through the use of cookies. To make our Website more responsive to the needs of our visitors, we use cookies to assign each visitor a unique, randomly generated identity that resides on the visitor’s device. Such cookies do not personally identify the visitor as an individual, instead, they identify the device that a visitor uses to access our Website. Unless you voluntarily identify yourself (through registration, for example), we will have no knowledge of who you are, even if we assign a cookie to your device.
                            </p>
                        </section>

                        <section>
                            <h3>Why Do We Use Cookies?</h3>
                            <p>
                                Cookies perform different functions to enhance your experience, i.e., allow you to navigate between pages efficiently by remembering your preferences and hence, disabling cookies may prevent you from using certain parts of the Website and mobile site.
                            </p>
                            <p>We use the information collected from cookies to:</p>
                            <ul>
                                <li>Improve the performance and usability of our Website</li>
                                <li>Personalize your experience on our Website</li>
                                <li>Identify your interests</li>
                                <li>Analyze Website traffic and trends</li>
                                <li>Conduct research and development</li>
                            </ul>
                        </section>
                    </div>
                </div>
            </div>
        </PageWrapper>
    );
};

export default CookiePolicy;