// src/app/screens/dashboard/TermsandConditions/index.js
import React from "react";
import PageWrapper from "../common/components/PageWrapper";
import PageHeader from "../common/components/PageHeader";
import styles from "../PrivacyPolicy/styles/privacypolicy.module.css"

const RefundPolicy = () => {
    return (
        <PageWrapper>
            <div className={styles.privacyParentContainer}>
                <div className={styles.privacyContainer}>
                    <PageHeader string={"Refund Policy"} />
                    <p>
                    This refund policy (<b>“Refund Policy”</b>) provides information for users on seeking any refund for the communication service (<b>“Service”</b>) provided by M/s. Synerthink Solutions LLP (<b>“Synerthink”</b>, <b>“us”</b>, <b>“we”</b>, <b>“our”</b>), under the brand name of CERTIFI, is as stated below:
                    </p>
                    <p>
                    Please note that one CERTIFI e-mail and one CERTIFI SMS sent together as part of the Service would be considered as one transaction and charged together.
                    </p>
                    <ol>
                        <li>
                            <strong>FOR PREPAID SERVICE:</strong>
                            <ol>
                                <li>
                                A refund will be processed if the CERTIFI e-mail could not be sent for any reasons attributable to Synerthink. This refund will be processed after due investigation for the failure in 15 working days from the day the CERTIFI e-mail was sent.
                                </li>
                                <li>
                                No refund will be provided in case the user provides an incorrect mobile number or e-mail address of the user and/or the recipient.
                                </li>
                                <li>
                                No refund will be provided for services rendered by Synerthink in the event that the communication of issuance of a certificate has been sent to the recipient through SMS and/or e-mail.
                                </li>
                                <li>
                                No refund would be provided in the event that the CERTIFI SMS is not delivered as it is an ancillary service to support the issuance of the certificate through the CERTIFI e-mail and such SMS is sent to the recipient for information purposes only.
                                </li>
                                <li>
                                No refund will be provided in the event that the user misuses the Service of Synerthink for sending promotion e-mails. The sender shall be liable to pay any fines, penalties, or punishments imposed by the competent authority for violating any applicable laws, regulations, directives, or instructions related to the misuse of the Service. 

                                </li>
                                <li>
                                In cases where no delivery reports are accessible on Synerthink’s platform to the user, whether in respect of issuance of email and / or SMS, and the recipient is able to access the email and / or using the OTP and the recipient’s mobile number, the certificate will still be generated and issued to the sender. No refund will be provided in such instances.
                                </li>
                                <li>
                                This Refund Policy should be read in conjunction with the Terms of Use. 
                                </li>
                            </ol>
                        </li>
                    </ol>
                </div>
            </div>
        </PageWrapper>
    );
};

export default RefundPolicy;