/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect, useState } from "react";
import Table from "../../../components/Table";
import {
	checkfrwdotp,
	frwdmail,
	GetFailedMail,
	GetSuccessMail,
	reqfrwdotp,
} from "../../API";
import searchIcon from "../../assets/images/search.png";
import refresh from "../../assets/images/refresh.png";
import Loader from "../../../components/Loader";
import usePopupModal from "../../../components/popUpModal";
import { toast } from "react-hot-toast";
import { getCertificate, getUserInfo } from "../../../utility";
import { useDispatch, useSelector } from "react-redux";
const FailedEmails = () => {
	const { modalVisible, hideModal, showModal } = usePopupModal();
	const {locale } = useSelector((state) => state.user);
	const dispatch = useDispatch();
	useEffect(() => {
		getToken();
	}, []);
	const [isSelected, setSelected] = useState([]);
	const [inbox, setInbox] = useState();
	const [isLoading, setLoading] = useState(false);
	const [today, setToday] = useState();
	const [search, setSearch] = useState();
	const [filteredDataSource, setFilteredDataSource] = useState();
	const onClick = ({ type, id, flag }) => {
		getCertificate(type, id);
	};

	const onSearch = (text) => {
		if (text) {
			const newData = inbox.filter(function (item) {
				const recipient = item.recipient.toUpperCase();
				const body = item.body.toUpperCase();
				const itemData = item.subject.toUpperCase();
				const textData = text.toUpperCase();
				return (
					itemData.indexOf(textData) > -1 ||
					recipient.indexOf(textData) > -1 ||
					body.indexOf(textData) > -1
				);
			});
			setFilteredDataSource(newData);
			setSearch(text);
		} else {
			setFilteredDataSource();
			setSearch(text);
		}
	};
	const getToken = async () => {
		let token = await localStorage.getItem("key");
		let currentDate = new Date();
		let year = currentDate.getFullYear();
		let month = String(currentDate.getMonth() + 1).padStart(2, "0");
		let day = String(currentDate.getDate()).padStart(2, "0");
		let formattedDate = `${year}-${month}-${day}`;
		setToday(formattedDate);
		setLoading(true);
		try {
			GetFailedMail(token).then((response) => {
				if (response?.data) {
					const sort = response?.data.sort(
						(a, b) =>
							new Date(b.time.split(" ")[0]) - new Date(a.time.split(" ")[0])
					);
					setInbox(sort);
					setLoading(false);
				} else {
					setLoading(false);
				}
			});
		} catch (error) {
			console.log(error, "error");
			setLoading(false);
		}
	};
	return (
		<div className="table-container">
			<div className="refresh">
				<div
					onClick={() => getToken()}
					style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
				>
					<img
						src={refresh}
						alt="refresh"
						style={{ width: 19.5, height: 20, paddingRight: 20 }}
					/>
					<span>{locale?.languageType=="English"?"Refresh":"ताज़ा करें"}</span>
				</div>
				<div className="input-wrapper">
					<input
						className="input"
						placeholder="Search"
						onChange={(e) => onSearch(e.target.value)}
					/>
					<img
						src={searchIcon}
						alt="search"
						style={{ width: 18, height: 18 }}
					/>
				</div>
			</div>
			{isLoading && <Loader />}
			<Table
				data={search ? filteredDataSource : inbox}
				isSuccess={false}
				loading={isLoading}
				onClick={onClick}
				type={"email"}
				lable={"Failed Email data"}
			/>
		</div>
	);
};

export default FailedEmails;
