import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./ComposeMail.css"; // Import the CSS file
import { v4 as uuidv4 } from "uuid";
import Loader from "../../../components/Loader";
import { toast } from "react-hot-toast";
import cashfree from "../../../util";
import { GetDraftData, GetPaymentStatus } from "../../API";
import { useLocation, useNavigate } from "react-router-dom";
import Captcha from "./Captcha";
import { Stepper, Step } from "react-form-stepper";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import { Redirection_url, ROOT } from "../../API/AppUrl";
import InfoIcon from '@mui/icons-material/Info';
import COLORS from "../../../utility/colors";
import usePopupModal from "../../../components/popUpModal";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PhonelinkEraseIcon from '@mui/icons-material/PhonelinkErase';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
const ComposeMail = () => {
	const { settingData, locale, userInfo } = useSelector((state) => state.user);
	const { ModalComponent, modalVisible, hideModal, showModal } = usePopupModal()
	const isMobile = useMediaQuery({
		query: "(max-width: 820px)",
	});
	const isiPadPro = useMediaQuery({
		query: "(max-width: 1024px)",
	});
	const generateCaptcha = () => {
		const chars =
			"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		let captcha = "";
		for (let i = 0; i < 6; i++) {
			captcha += chars.charAt(Math.floor(Math.random() * chars.length));
		}
		return captcha;
	};
	const { state } = useLocation();
	const [phone, setPhone] = useState("");
	const [subject, setSubject] = useState("");
	const [email, setEmail] = useState("");
	const [attachments, setAttachments] = useState([]);
	const [body, setBody] = useState("");
	const [isLoading, setLoading] = useState(false);
	const [captcha, setCaptcha] = useState(generateCaptcha());
	const [userInput, setUserInput] = useState("");
	const [activeStep, setActiveStep] = useState(1);
	const [isPhoneNumberVerify, setPhoneNumberVerify] = useState(true)
	const [isDraft, setDraft] = useState(false)
	const [isSubmit, setSubmit] = useState(false)
	const [statusData, setStatusData] = useState({
		isFailed: false,
		statusName: "",
	});
	const [bsa, setBSA] = useState(false);
	const [logs, setLOGS] = useState(false);
	const [copyMail, setCopyMail] = useState(false)
	const navigate = useNavigate();
	const { id } = state || {};
	useEffect(() => {
		getDraftData(id);
	}, [id]);
	useEffect(() => {
		if (!settingData?.mob_verify) {
			setPhoneNumberVerify(false)
			showModal(true)
		}
	}, [])

	const getDraftData = (id) => {
		if (id) {
			GetDraftData(id).then((response) => {
				setLoading(false);
				console.log(response, "response");
				if (response?.response == "ok") {
					setPhone(response?.draft_data?.mob);
					setSubject(response?.draft_data?.subject);
					setEmail(response?.draft_data?.recipient);
					setDraft(true)
					const validJsonString = response?.draft_data?.attachment?.replace(
						/'/g,
						'"'
					);
					const parsedArray = JSON.parse(validJsonString);
					setAttachments(parsedArray);

					setBody(response?.draft_data?.body);
				} else {
					toast.error("Something went wrong!");
				}
			});
		}
	};
	const handleFileChange = async (event) => {
		const newFiles = Array.from(event.target.files); // Convert FileList to an array

		const totalSize = newFiles.reduce((sum, file) => sum + file.size, 0); // Calculate the size of new files only

		const existingFilesSize = attachments.reduce(
			(sum, file) => sum + file.size,
			0
		); // Get the size of already stored files
		const combinedSize = totalSize + existingFilesSize; // Total combined size

		if (combinedSize > 5 * 1024 * 1024) {
			// Check if the combined size exceeds 5MB
			toast.error("Total file size should be less than 5MB");
			return;
		} else {
			try {
				const filePromises = newFiles.map((file) => convertToBase64(file)); // Convert only new files to Base64
				const base64Files = await Promise.all(filePromises); // Wait for all conversions to complete

				const formattedFiles = base64Files.map((base64, index) => ({
					name: newFiles[index].name,
					data: `data:${newFiles[index]?.type};base64,${base64}`,
				}));
				console.log(JSON.stringify(formattedFiles), "formattedFiles");
				// Update the state with new attachments
				setAttachments((prevAttachments) => [
					...prevAttachments,
					...formattedFiles,
				]);
			} catch (error) {
				console.error("Error converting files to Base64:", error);
				toast.error("An error occurred while processing files.");
			}
		}
	};

	const convertToBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result.split(",")[1]); // Get the Base64 part of the data URL
			reader.onerror = (error) => reject(error);
		});
	};

	const handleRemoveFile = (index) => {
		const updatedAttachments = [...attachments]; // Copy the current attachments array
		updatedAttachments.splice(index, 1); // Remove the file at the specified index
		setAttachments(updatedAttachments); // Update the state with the new array
	};

	const handleTwoFactorSubmit = (event) => {
		event.preventDefault();
		const res = validateCaptcha();
		if (!res) return null;
		setSubmit(true)
	}

	// Handle form submission
	const handleSubmit = async (event) => {
		event.preventDefault();
		const res = validateCaptcha();
		if (!res) return null;
		console.log({ phone, subject, attachments, body });
		setLoading(true);
		setSubmit(false)
		const orderId = uuidv4()
		const jsonObject = {
			purpose: "1",
			// orderid: orderId,
			...(isDraft ? { orderid: id } : {}),
			return_url: `${Redirection_url}/compose-email${isDraft ? `?orderId=` : '?orderId='}`,
			notify_url: `${Redirection_url}/compose-email${isDraft ? `?orderId=` : '?orderId='}`,
			// return_url: `${Redirection_url}/compose-email?orderId=${orderId}`,
			// notify_url: `${Redirection_url}/compose-email?orderId=${orderId}`,
			To_mail: email,
			mobile: phone,
			subject: subject,
			mail_body: body,
			attachments: attachments,
			bsa: bsa,
			logs: logs,
			copy_mail: copyMail
		};

		console.log(JSON.stringify(jsonObject, null, 4));
		const token = await localStorage.getItem("key");
		const requestOptions = {
			method: "POST",
			headers: {
				Authorization: `Token ${token}`,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(jsonObject),
		};

		try {
			const response = await fetch(
				`${ROOT}/beforePayment/`,
				requestOptions
			);
			const contentType = response.headers.get("Content-Type");
			const rawText = await response.text();
			console.log("Raw Response:", rawText);

			let result = contentType.includes("application/json")
				? JSON.parse(rawText)
				: { response: rawText };

			if (result?.order_id) {
				handleRedirect(result.id);
			} else {
				resetForm();
				toast.success(result?.response)
				window.location.reload()
			}
		} catch (error) {
			console.log("Submission Error:", error);
		} finally {
			setLoading(false);
		}
		// Handle form submission logic here, such as making an API call
	};

	const handleRedirect = (session_id) => {
		let paymentOptions = {
			paymentSessionId: session_id,
		};
		cashfree.checkout(paymentOptions).then((result) => {
			console.log(result);
		});
	};
	const handleInputChange = (e) => {
		setUserInput(e.target.value);
	};

	// Step 4: Function to validate CAPTCHA
	const validateCaptcha = () => {
		if (userInput === captcha) {
			return true;
		} else {
			toast.error("CAPTCHA is incorrect. Try again!");
			return false;
		}
	};

	// Step 5: Function to refresh the CAPTCHA
	const refreshCaptcha = () => {
		setCaptcha(generateCaptcha());
		setUserInput("");
	};
	const resetForm = () => {
		setPhone("");
		setSubject("");
		setEmail("");
		setAttachments([]);
		setBody("");
		setLoading(false);
	};

	useEffect(() => {
		const url = window.location.href;
		const orderId = url.split("orderId=")[1];
		if (orderId) {
			getPaymentDetails(orderId);
		}
	}, []);

	const getPaymentDetails = (orderId) => {
		setLoading(true);
		try {
			GetPaymentStatus(orderId).then((response) => {
				setLoading(false);
				if (response.order_info?.order_status) {
					resetForm();
					setActiveStep(5);
					setStatusData(() => ({
						isFailed: false,
						statusName: "Success",
					}));
					toast.success("Payment successfully done \n" + response?.resp, {
						duration:5000
					});
					setTimeout(() => {
						setActiveStep(2);
						setStatusData({
							isFailed: false,
							statusName: "",
						});
						navigate("/compose-email");
					}, 3000);
				} else {
					setActiveStep(5);
					setStatusData(() => ({
						isFailed: true,
						statusName: "Failed",
					}));
					setTimeout(() => {
						setActiveStep(2);
						setStatusData({
							isFailed: false,
							statusName: "",
						});
						navigate("/compose-email");
					}, 3000);
					toast.error("Something went wrong! \n" + response?.resp);
				}
			});
		} catch (error) {
			toast.error(JSON.stringify(error));
		} finally {
			setLoading(false);
		}
	};
	const checkBoxComponent = () => {
		return (
			<>
				<span style={{ color: "rgba(0,0,0,0.8)", fontSize: 14, fontWeight: '600' }}>
					{locale?.wouldYouLike}
				</span>
				<div style={{ display: 'flex', margin: '4px 0', flexDirection: isMobile ? 'column' : 'row' }}>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							width: "100%",
							margin: "auto",
						}}
					>
						<input
							type="checkbox"
							checked={bsa}
							onChange={() => setBSA(!bsa)}
							style={{ marginRight: 5 }}
						/>
						{locale?.languageType==="English" ? 
						<span title="Bharatiya Sakshya Adhiniyam, 2023 (BSA), recognizes the significance of electronic or digital records and has dedicated provisions in Chapter 5 to address the admissibility and proof of electronic evidence." style={{ color: "gray", fontSize: 12, lineHeight: 1.2 }}>
							Include certificate in <strong style={{ color: COLORS.black }}>63 BSA</strong> format?<span onClick={() => showModal(true)}><InfoIcon style={{ color: COLORS.blue, width: 12, height: 11, cursor: 'pointer' }} /></span>
						</span>
						:
						<span title="Bharatiya Sakshya Adhiniyam, 2023 (BSA), recognizes the significance of electronic or digital records and has dedicated provisions in Chapter 5 to address the admissibility and proof of electronic evidence." style={{ color: "gray", fontSize: 12, lineHeight: 1.2 }}>
							<strong style={{ color: COLORS.black }}>63 BSA</strong> प्रारूप में प्रमाणपत्र सम्मिलित करना?<span onClick={() => showModal(true)}><InfoIcon style={{ color: COLORS.blue, width: 12, height: 11, cursor: 'pointer' }} /></span>
						</span>}
						<ModalComponent>
							<div style={{ background: '#fff', padding: '40px 20px', borderRadius: 12, width: isMobile ? window.innerWidth / 1.2 : window.innerWidth / 3, position: 'relative' }}>
								<div onClick={() => hideModal(false)} style={{ position: 'absolute', top: 10, right: 10, cursor: 'pointer' }}><HighlightOffIcon style={{ color: COLORS.blue }} /></div>
								<span style={{ fontSize: '0.9rem', lineHeight: 1.4, letterSpacing: 1.2, color: COLORS.graniteGrey }}>
									Bharatiya Sakshya Adhiniyam, 2023 (BSA), recognizes electronic or digital records and has dedicated provisions in Chapter 5 to address the admissibility and proof of electronic evidence. In the event that a customer relies on certificates provided by Synerthink Solutions LLP (“Synerthink”), the customer is solely responsible for obtaining independent legal advice and/or representation to ensure compliance with applicable laws. Synerthink disclaims all liability for any prejudice or harm that may arise if the customer fails to obtain such legal advice or representation. This disclaimer extends to any consequences resulting from the customer’s reliance on the certificates without appropriate legal review.
								</span>
							</div>
						</ModalComponent>
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							width: "100%",
							margin: "auto",
						}}
					>
						<input
							type="checkbox"
							checked={logs}
							onChange={() => setLOGS(!logs)}
							style={{ marginRight: 5 }}
						/>
						<span style={{ color: "gray", fontSize: 12, }}>
							{locale?.languageType==="English" ? 'Add forensic audit trail to the certificate':'प्रमाणपत्र में फोरेंसिक ऑडिट ट्रेल सम्मिलित करना?'}
						</span>
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							width: "100%",
							margin: "auto",
						}}
					>
						<input
							type="checkbox"
							checked={copyMail}
							onChange={() => setCopyMail(!copyMail)}
							style={{ marginRight: 5 }}
						/>
						<span style={{ color: "gray", fontSize: 12, }}>
							{locale?.languageType==="English" ? "Receive a copy of the certified communication?": "प्रमाणित संचार की एक प्रति प्राप्त करना?"}
						</span>
					</div>
				</div>
			</>

		)
	}
	return (
		<div className="profile-page-container">
			<div className="profile-page-section">
				<span>{locale?.languageType==="English"?"Compose Email":"ईमेल लिखें"}</span>
			</div>
			{isLoading && <Loader />}
			<div className="form-container">
				{isSubmit && <div className="modal-wrapper">
					<div style={{ background: '#fff', padding: '40px 40px', borderRadius: 12, width: isMobile ? window.innerWidth / 1.8 : window.innerWidth / 4, position: 'relative', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
						<HelpOutlineIcon style={{ width: isMobile ? 70 : 100, height: isMobile ? 70 : 100, color: COLORS.blue }} />
						<span style={{ fontSize: '1.1rem', color: COLORS.graniteGrey, marginTop: 20, textAlign: 'center' }}>Are the recipient's email ID and phone number verified and accurate for this communication?</span>
						<div style={{ display: 'flex', justifyContent: 'space-around', width: '100%', marginTop: 20 }}>
							<span onClick={() => setSubmit(false)} style={{ background: COLORS.orangeShade, padding: '10px 20px', borderRadius: 8, color: COLORS.white, cursor: 'pointer' }}>No</span>
							<span onClick={handleSubmit} style={{ background: COLORS.blue, padding: '10px 20px', borderRadius: 8, color: COLORS.white, cursor: 'pointer' }}>Yes</span>
						</div>
					</div>
				</div>}
				<ModalComponent>
					<div style={{ background: '#fff', padding: '40px 40px', borderRadius: 12, width: isMobile ? window.innerWidth / 1.8 : window.innerWidth / 4, position: 'relative', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
						<PhonelinkEraseIcon style={{ width: isMobile ? 70 : 100, height: isMobile ? 70 : 100, color: COLORS.redText }} />
						<span style={{ fontSize: '1.1rem', color: COLORS.graniteGrey, marginTop: 20, textAlign: 'center' }}>Verify phone number to <span onClick={() => navigate("/setting")} style={{ textDecorationLine: 'underline', color: COLORS.blue, cursor: 'pointer' }}>proceed!</span></span>
					</div>
				</ModalComponent>
				<form onSubmit={handleTwoFactorSubmit}>
					<div style={{ height: "68vh", overflow: "scroll" }}>
						<Stepper
							activeStep={activeStep}
							connectorStateColors={true}
							connectorStyleConfig={{
								completedColor:
									activeStep == 5 && statusData.isFailed ? "red" : "#14619C",
								activeColor: "#14619C",
								disabledColor: "rgba(0,0,0,0.2)",
							}}
							styleConfig={{
								activeBgColor:
									activeStep == 4 && statusData.isFailed ? "red" : "#ec9138",
								completedBgColor: "#14619C",
								inactiveBgColor: "#eee",
								activeTextColor: "#fff",
								completedTextColor: "#fff",
								inactiveTextColor: "#444",
							}}
						>
							<Step label={locale?.languageType==="English" ?"Login":"लॉग इन करें"} />
							<Step label={locale?.languageType==="English" ? "Compose":"ईमेल लिखें"} />
							<Step label={locale?.Payment} />
							<Step
								label={
									activeStep == 5 && statusData.isFailed
										? "Failed"
										: activeStep == 5 && !statusData.isFailed
											? "Submitted"
											: locale?.languageType==="English"? "Submit":"जमा करना"
								}
							/>
						</Stepper>
						<div
							style={{
								display: "flex",
								width: "100%",
								flexDirection: isMobile ? "column" : "row",
							}}
						>
							<div style={{ width: isMobile ? "100%" : "40%" }}>
								<div className="form-group">
									<label className="form-label">
										{locale?.languageType==="English"?"Recipient's mobile number:":"प्राप्तकर्ता का मोबाइल नंबर"}
									</label>
									<input
										type="number"
										value={phone}
										onChange={(e) => setPhone(e.target.value)}
										required
										className="form-input"
										placeholder={locale.Enter_Recipient_Contact}
										maxLength={10}
										style={{ width: "96%" }}
									/>
								</div>
								{/* Email Field */}
								<div className="form-group">
									<label className="form-label">{locale?.languageType==="English"?"Recipient's email:":"प्राप्तकर्ता का ईमेल:"}</label>
									<input
										type="email"
										value={email}
										onChange={(e) => setEmail(e.target.value)}
										required
										className="form-input"
										placeholder={locale.Enter_Recipient_Email}
										style={{ width: "96%" }}
									/>
								</div>
								{/* Subject Field */}
								<div className="form-group">
									<label className="form-label">{locale?.languageType==="English" ? "Subject:":"विषय:"}</label>
									<input
										type="text"
										value={subject}
										onChange={(e) => setSubject(e.target.value)}
										required
										className="form-input"
										placeholder={locale.Subject}
										style={{ width: "96%" }}
									/>
								</div>

								<div className="form-group">
									<label className="form-label">
										{locale?.languageType==="English" ? "Attachments (Max 5MB total):":"अनुलग्नक (कुल अधिकतम 5MB):"}
									</label>
									{/* Attachments Field */}
									<div className="file-list" style={{ width: "97.6%" }}>
										{attachments.map((file, index) => (
											<div key={index} className="file-item">
												<span>{file?.name.length > 30 ? file.name.slice(0, 30) + '...' : file.name}</span>
												<button
													type="button"
													className="remove-file-button"
													onClick={() => handleRemoveFile(index)}
												>
													&times; {/* Cross icon */}
												</button>
											</div>
										))}
									</div>
									<label htmlFor="file-input" className="file-upload-label">
										{locale?.languageType==="English" ? "Choose Files":"फ़ाइलें चुनें"}
									</label>
									<input
										id="file-input"
										type="file"
										multiple
										onChange={handleFileChange}
										className="form-input-file"
										accept="*" // Adjust as needed
										style={{ display: "none" }}
									/>
								</div>
								{!isMobile && (
									<Captcha
										captcha={captcha}
										refreshCaptcha={refreshCaptcha}
										userInput={userInput}
										handleInputChange={handleInputChange}
									/>
								)}
							</div>
							<div
								style={{
									flexDirection: "column",
									width: isMobile ? "92%" : "58%",
								}}
							>
								{/* Rich Text Editor for Body Field */}
								<div className="form-group">
									<label className="form-label">{locale?.languageType==="English"?"Body:":"ईमेल का मुख्य भाग:"}</label>
									<ReactQuill
										theme="snow"
										value={body}
										onChange={setBody}
										className="form-editor"
										style={{ width: "100%" }}
									/>
								</div>
								{isMobile && (
									<Captcha
										captcha={captcha}
										refreshCaptcha={refreshCaptcha}
										userInput={userInput}
										handleInputChange={handleInputChange}
									/>
								)}
								{isPhoneNumberVerify && checkBoxComponent()}
								<div className="form-group">
									<button type="submit" className="form-button">
										{locale?.languageType==="English"?"Submit":"जमा करना"}
									</button>
									{/* {!userInfo?.credits ? <p style={{ color: "gray", margin: 0, marginTop: 4 }}>
										*Please note: A transaction fee of{" "}
										<strong style={{ color: "#000" }}>₹1</strong> will be
										applied upon submission.
									</p>:<p></p>} */}
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default ComposeMail;
