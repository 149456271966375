import React, { useState } from "react";
import Input from "../../../../components/Input";
import styles from "../styles/HeroSection.module.css";
import username from "../../../assets/images/username.png";
import passwordIcon from "../../../assets/images/password.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/images/logo.png";
import { toast } from "react-hot-toast";
import CryptoJS from "crypto-js";
import { login } from "../../../API";
import { setAdminInfo, setModalData, setTokenFunction } from "../../../../redux/Actions/UserAction";
import apiCall from "../../admin/API/apiService";
import apiEndpoints from "../../admin/API/apiEndpoints";
const HeroSection = () => {
	const { locale } = useSelector((state) => state.user);
	const [email, setEmail] = useState("");
	const [ispassword, setPassword] = useState();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [loginCredential, setLoginCredential] = useState({});
	const [userType, setUserType] = useState('normal'); 
	const onChangeText = (val, key_name) => {
		if (key_name === "password") {
			setPassword(val);
			var password = CryptoJS.SHA1(val);
			setLoginCredential((previous) => ({
				...previous,
				[key_name]: password,
			}));
		} else {
			setLoginCredential((previous) => ({
				...previous,
				[key_name]: val,
			}));
		}
	};
	const handleSubmit = async () => {
		if ((loginCredential?.email, loginCredential?.password)) {
			let loader = toast.loading("Loading...");
			var formdata = new FormData();
			formdata.append("username", loginCredential?.email);
			formdata.append("password", loginCredential?.password);
			try {
				login(formdata).then(async (response) => {
					if (response?.token) {
						toast.success("Login successfully!");
						toast.remove(loader);
						localStorage.setItem("key", response?.token);
						dispatch(setTokenFunction(true));
						const loginTime = new Date().getTime();
						localStorage.setItem("isLoggedIn", "true");
						localStorage.setItem("loginTime", loginTime);
					} else {
						toast.remove(loader);
						toast.error(response?.non_field_errors[0] || "Please enter correct credential!")
					}
				});
			} catch (error) {
				toast.remove(loader);
				toast.error((error?.non_field_errors[0]||"Something went wrong!"), {
					duration: 2000,
				});
				console.log("error", error);
			}
		} else {
			toast.error("Both field are mendatory!", {
				duration: 2000,
			});
		}
	};

	const handleConsultantLogin =async()=>{
		if ((loginCredential?.email, loginCredential?.password)) {
			let loader = toast.loading("Loading...");
			var formdata = new FormData();
			formdata.append("username", loginCredential?.email);
			formdata.append("password", loginCredential?.password);
			try {
			const result  = await apiCall("POST", apiEndpoints.LOGIN, formdata )
			console.log(result,"result");
			
			if(result.token){
				localStorage.setItem('token', result?.token);
				toast.success("Login successfully!");
				toast.remove(loader);
				dispatch(setAdminInfo({isLogin:true}))
			}
			} catch (error) {
				toast.remove(loader);
				toast.error("Something went wrong!", {
					duration: 2000,
				});
				console.log("error", error);
			}
		}
	}

	const loginComponent = () => {
		return (
			<div className={styles.form}>
				<div className={styles.loginForm}>
					<img src={logo} className={styles.formLogo} />
					{/* <div style={{ textAlign: 'center',  }}>
                    <label>
                        <input 
                            type="radio" 
                            value="normal" 
                            checked={userType === 'normal'} 
                            onChange={() => setUserType('normal')} 
                        />
                        Normal User
                    </label>
                    <label style={{ marginLeft: '10px' }}>
                        <input 
                            type="radio" 
                            value="consultant" 
                            checked={userType === 'consultant'} 
                            onChange={() => setUserType('consultant')} 
                        />
                        Consultant
                    </label>
                </div> */}
					<div className={styles.formGroup}>
						<Input
							icon={username}
							placeholder={locale?.EnterEmail || "Enter Email"}
							onChangeText={onChangeText}
							key_name="email"
							value={email}
						/>

						<Input
							icon={passwordIcon}
							placeholder={locale?.EnterPassword || "Enter Password"}
							onChangeText={onChangeText}
							key_name="password"
							value={ispassword}
							type={"password"}
						/>
					</div>
					<a
						className={styles.forgot}
						onClick={() => dispatch(setModalData({type:'ForgotPassword', isVisible:true}))}
						// onClick={()=>{window.location.href='?type=forgot'}}
					>
						{locale?.ForgotPassword}
					</a>
					<button
						onClick={() => {
							if(userType=="normal"){
								handleSubmit()
							}else{
								handleConsultantLogin()
							}
						}}
						className={styles.submitButton}
					>
						{locale.Login}
					</button>
					<a
						className={styles.switchForm}
						style={{color:'#000'}}
						onClick={() => {
							if(userType=="normal"){
								dispatch(setModalData({type:'Registration', isVisible:true}))
							}else{
								dispatch(setModalData({type:'RegisterConsultant', isVisible:true}))
							}
						}}
					>
						{locale?.DontHaveAccount} <span style={{color:'#0178b2'}}>create</span>
					</a>
				</div>
			</div>
		);
	};

	return (
		<div className={styles.heroSection} data-aos="fade-in">
			<div className={styles.content}>
				<h1>{locale.HeroTitle}</h1>
				<p>{locale.List0}</p>
				<p>{locale.List1}</p>
				<p>{locale.List2}</p>
				<p>{locale.List3}</p>
			</div>
			{loginComponent()}
		</div>
	);
};

export default HeroSection;