import React, { useState } from "react";
import "./login.css";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../components/Input";
import username from "../../assets/images/username.png";
import { Reset } from "../../API";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import styles from '../dashboard/modalStyle.module.css'
import { useMediaQuery } from "react-responsive";
import { setModalData } from "../../../redux/Actions/UserAction";
import withBlackBackground from "../dashboard/withBlackBackground";
import COLORS from "../../../utility/colors";
const Forgot = () => {
	const { locale } = useSelector((state) => state.user);
	const [loginCredential, setLoginCredential] = useState({});
	const navigate = useNavigate();
	const [inputKey, setInputKey] = useState(0);
	const dispatch = useDispatch();
	const onChangeText = (val, key_name) => {
		setLoginCredential((previous) => ({
			...previous,
			[key_name]: val,
		}));
	};
	const handleForgot = () => {
		toast.loading("Loading...");
		var formdata = new FormData();
		formdata.append("registered_id", loginCredential.email);
		try {
			Reset(formdata).then((response) => {
				if (response?.response === "ok") {
					console.log(response);
					toast.remove();
					toast.success("Request send please check email!");
					navigate("/");
					setLoginCredential({});
					setInputKey((prevKey) => prevKey + 1);
				} else {
					toast.remove();
					toast.error("Please enter register emailId !");
				}
			});
		} catch (error) {
			console.log(error);
			toast.remove();
			toast.error("Something went wrong !");
		}
	};

	const isMobile = useMediaQuery({
		query: "(max-width: 820px)",
	});

	return (
		<div className={styles.modalAnimation} style={{ position: 'absolute', maxWidth: isMobile ? '340px' : '500px', }}>
			<span onClick={() => dispatch(setModalData({ type: '', isVisible: false }))} style={{ position: 'absolute', top: -14, right: '10px', cursor: 'pointer', fontWeight: '900', right: -8, background: '#fff', padding: 4, height: 20, width: 20, textAlign: 'center', borderRadius: 100 }}>x</span>
			<div style={{ height: 'auto', width: '100%', height: '100%', overflow: 'scroll' }}>
				<div style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '20px',
					marginBottom: 20,
				}}>
					<img src={require("../../assets/images/logo.png")} style={{ width: 60, alignSelf: "center", marginBottom: 0 }} />
					<span style={{ textAlign: 'center' }}>{locale?.ForgotPassword}</span>
				</div>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						width: "100%",
						margin: "auto",
					}}
				>
					<Input
						icon={username}
						placeholder={locale.EnterEmail}
						onChangeText={onChangeText}
						key_name="email"
						value={loginCredential?.email}
						key={inputKey}
					/>
				</div>

				<div class="container">
					<a class="btn btn-1" onClick={handleForgot}>
						{locale.Send}
					</a>
				</div>
				<p style={{ textAlign: "center", color: "rgba(0,0,0,0.4)" }}>
					<a
						target="_blank"
						style={{ textDecoration: "none", color:COLORS.blue, onhover:{textDecorationLine:'underline'} }}
						href="https://play.google.com/store/apps/details?id=com.certifi"
					>
						Download Our App
					</a>
				</p>
			</div>
		</div>
	);
};

export default withBlackBackground(Forgot);
