import React from "react";
import PageWrapper from "../common/components/PageWrapper";
import { PrivacyData } from "./components/constant";
import PrivacyPolicy from "./components/privacypolicy";
import PageHeader from "../common/components/PageHeader";
import styles from './styles/privacypolicy.module.css'
import COLORS from "../../../../utility/colors";

const DashboardPrivacyPolicy = () => {
  return (
    <PageWrapper>
      {/* <PrivacyPolicy policy={PrivacyData} /> */}
      <div className={styles.privacyParentContainer}>
      <div className={styles.privacyContainer}>
      <PageHeader string={"Privacy Policy"}/>
      <p>Last Updated: 31st Dec 2024</p>

      <div className="section">
        <h3>1. INTRODUCTION</h3>
        <ol>
          <li>
            This privacy policy governs the manner in which M/s. Synerthink Solutions LLP (<b>“Synerthink”</b>, <b>“We”</b>, <b>“Us”</b>, or <b>“Our”</b>) collects, uses, maintains and discloses information collected from users of the <a href="https://certifi.biz" style={{color:COLORS.blue}}>https://certifi.biz</a> website (<b>“Website”</b>) and/or application <a href="https://play.google.com/store/apps/details?id=com.certifi" target="_blank" style={{color:COLORS.blue}}>https://play.google.com/store/apps/details?id=com.certifi</a> (<b>“App”</b>) (collectively referred to as <b>“Platforms”</b>). This privacy policy applies to the Platforms and all services offered by Synerthink. You agree to and are bound by the terms and conditions set forth below and in any modified or additional terms that Synerthink may publish from time to time (collectively, the <b>“Privacy Policy”</b>).
          </li>
        </ol>
      </div>

      <div className="section">
        <h3>2. DEFINITIONS</h3>
        <ol>
          <li><strong>“Product(s)“</strong> means and includes any products / services / offers / display items that are uploaded/showcased/displayed on the Platforms and the related description, information, procedure, processes, warranties, delivery schedule, etc.</li>
          <li><strong>“Personal Data“</strong> means any data about an individual who is identifiable by or in relation to such data.</li>
          <li><strong>“Third Parties“</strong> means and includes third-party vendors and/or third-party processors engaged by Synerthink during the course of its business.</li>
          <li><strong>“Applicable Laws“</strong> shall mean, without limitation, all relevant statutes, enactments, ordinances, regulations, rules, guidelines, directives, codes of practice, and other legal requirements, including the Information Technology Act 2000, Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011, Directions under sub-section (6) of Section 70B of the Information Technology Act, 2000 relating to Information Security Practices, Procedure, Prevention, Response and Reporting of Cyber Incidents for Safe & Trusted Internet dated 28 April 2022, and any other laws, regulations, or directives as may be applicable, amended, substituted, or re-enacted from time to time.</li>
        </ol>
      </div>

      <div className="section">
        <h3>3. SCOPE OF THE PRIVACY POLICY</h3>
        <ol>
          <li>This Privacy Policy applies to everyone who visits and/or uses our Platforms, (“collectively referred to as <strong>“Users”</strong> or <strong>“you”</strong>). This Privacy Policy does not extend to any websites or applications that may be accessed from the Platforms including, but not limited to any links we may provide to any other websites or applications.</li>
          <li>We follow this Privacy Policy in accordance with the Applicable Laws. For some services offered by us, applications, or events, we may have additional privacy notices. These notices should be read together with this Privacy Policy.</li>
          <li>By using our Platforms, you agree to comply with all Applicable Laws and regulations governing your use of our Platforms and in particular agree to adhere to your duties under the (Indian) Digital Personal Data Protection Act, 2023. You further agree to adhere to the terms and conditions set forth in our Terms of Use, which govern the permissible use of our Platforms. You may access our Terms of Use <a href="https://www.certifi.biz/dashboardTermsandConditions" style={{color:COLORS.blue}}>here</a>.</li>
          <li>All undefined terms used in this Privacy Policy shall have the meaning assigned to them in the Terms of Use.</li>
        </ol>
      </div>

      <div className="section">
        <h3>4. PERSONAL DATA WE COLLECT FROM YOU</h3>
        <ol>
          <li>We may collect Personal Data from Users on various occasions, including, but not limited to the following:
          <ol type="a">
            <li>When Users visit our Platforms.</li>
            <li>When Users register on our Platforms, avail our services through our Platforms, and or in connection with other activities, features or resources we make available on our Platform.</li>
          </ol>
          </li>
          <li>We may collect, use, store and transfer different kinds of Personal Data about you including the below mentioned:
            <ol type="a">
              <li>Name</li>
              <li>Government identification proof</li>
              <li>Financial information</li>
              <li>E-mail address</li>
              <li>Mailing address</li>
              <li>Phone number</li>
              <li>Data collected through cookies, such as the pages you visit, your interests, your internet protocol information, etc.</li>
            </ol>
          </li>
          <li>We may collect Personal Data from you only if you voluntarily submit such information to us. Users can always refuse to provide Personal Data, except that it may prevent them from engaging in or accessing certain parts or services provided on our Platforms. </li>
          <li>You acknowledge and agree that the provision of our services requires you to provide us the recipient's Personal Data. You further agree to provide such Personal Data only after obtaining the explicit and proper consent from the recipient in accordance with all Applicable Laws. You accept full responsibility for ensuring that the necessary consent has been obtained and agree that we shall not be liable for any claims, damages, or liabilities arising from your failure to obtain such consent or from the use of the Personal Data provided. You shall indemnify and hold harmless us, our affiliates, officers, employees, and agents from any and all claims, actions, damages, losses, liabilities, costs, and expenses (including reasonable attorneys' fees) arising out of or in connection with your failure to obtain the necessary consent or any violation of Applicable Laws in relation to the collection and processing of Personal Data.</li>
        </ol>
      </div>

      <div className="section">
        <h3>5. PURPOSES FOR COLLECTING YOUR PERSONAL DATA</h3>
        <ol>
                  <li>Synerthink may collect and use Users personal information for the following purposes:
          <ol type="a">
            <li>As part of our service:</li>
            <ol type="i">
              <li>The internet protocol (IP) address from where the User may access the system for making the agreement is recorded for addressing any future dispute.</li>
              <li>Personal Data of the User, such as the name, e-mail address, phone number are collected for creating digital proof of delivery certification.</li>
              <li>Financial information is collected to verify the identity and facilitate payments and security purposes.</li>
              <li>IP address of the system used by the User to access the Platforms are recorded for creating digital proof of existence certificate.</li>
              <li>The information collected as mentioned above in paragraph 5.1(a)(i) and paragraph 5.1(a)(ii) shall only be used for complaint resolution and for performance improvement</li>
              <li>The communication intended to be processed for certification is collected from registered Users and is stored in our systems for forward transmission and delivery certification</li>
              <li>Recipient’s Personal Data will be used for generating the certificate and will be used as a part of mandatory information in order to make a proof of delivery having legal sanctity.</li>
            </ol>
            <li>To personalize user experience: We may use Personal Data in an aggregate form to understand how our Users use the services and resources provided on our Platforms. </li>
          <li>To improve our Platforms: We may use the feedback you provide to improve our products and services.</li>
          <li>To send periodic e-mails: We may use the e-mail address stored in our systems to send User information and updates pertaining to their services. It may also be used to respond to their inquiries, questions, and/or other requests. If User decides to opt-in to our mailing list, they will receive e-mails that may include news regarding Synerthink, updates, related service information, etc. If at any time the User would like to unsubscribe from receiving future e-mails, we include detailed unsubscribe instructions at the bottom of each e-mail or User may contact us via our Website.</li>
          <li>To run a promotion, contest, survey or other Website feature: To send Users information they agreed to receive about topics we think will be of interest to them.</li>
          </ol>
        </li>
        </ol>

      </div>

      <div className="section">
        <h3>6. CHANGE IN PURPOSE FOR COLLECTING PERSONAL DATA</h3>
        <ol>
          <li>We will only use your Personal Data for the purposes for which we collected it. If we need to use your Personal Data for another purpose, we will tell you about it and explain why we are doing it. We may also ask for your permission, depending on the applicable laws.</li>
          <li>We may also process your Personal Data for certain legitimate purposes as per Applicable Laws.</li>
        </ol>
      </div>

      <div className="section">
        <h3>7. WEB BROWSER COOKIES</h3>
        <ol type="1">
        <li>As you interact with our Platforms, we will automatically collect technical data about your device, browsing actions and patterns. We collect this Personal Data by using cookies. The Personal Data collected by using cookies is used to customize the Platforms and enhance the value of the features offered through our Platforms. To learn more about cookies and how we use them, see our Cookie Policy.</li>

        </ol>
      </div>

      <div className="section">
        <h3>8. STORAGE AND RETENTION OF YOUR PERSONAL DATA</h3>
        <ol type="1">
          <li>All the Personal Data provided by you will be uploaded to secure servers in India that are controlled by us. We may also use third party processors to store Personal Data collected from you.</li>
          <li>All readable data of the communications made by the User will not be retained (and will be deleted) from Synerthink’s systems after two days (A+2 days) of generation of the Certificate. For clarity, “A+2 days” means the date on which the Certificate is generated (“A”), plus an additional two full calendar days. For instance, if the Certificate is generated on December 10 at 18:00 hours, the data will be retained until 23:59 hours on December 12 and then deleted. Users are advised to download their Certificates and associated data within this timeframe unless they have opted for extended retention options.</li>
          <li>The generated certificates are stored only in non-readable format (HASH) for future cross verification of issued certificates by the User.</li>
          <li>We may also anonymise your Personal Data (so that it can no longer be associated with you) for research or statistical purposes, in which case we may use this information indefinitely without further notice to you.</li>
          <li>We will only retain your Personal Data for as long as reasonably necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, regulatory, tax, accounting or reporting requirements. We may retain your Personal Data for a longer period in the event of a complaint or if we reasonably believe there is a prospect of litigation in respect to our relationship with you.</li>
          <li>Typically, we determine the appropriate retention period for Personal Data by considering the amount, nature and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your Personal Data, the purposes for which we process your Personal Data and whether we can achieve those purposes through other means, and the applicable legal, regulatory, tax, accounting, or other requirements.</li>
        </ol>
      </div>

      <div className="section">
        <h3>9. HOW WE PROTECT YOUR INFORMATION</h3>
        <ol>
          <li>Synerthink treats all Personal Data it receives from you as confidential and does not use the Personal Data for any purpose other than in accordance with this Privacy Policy. We have security measures in place to protect your Personal Data from unauthorized access, use, or disclosure.</li>
          <li>We regularly review our security measures to make sure they are keeping your Personal Data safe. However, no security measures are perfect.</li>
          <li>We implement reasonable security practices and procedures and have a comprehensive documented information security programme and information security policies that contain managerial, technical, operational and physical security control measures that are commensurate with respect to the information being collected and the nature of our business. We adopt appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of your personal information, username, password, transaction information and data stored on our Platforms and servers.</li>
          <li>Sensitive and private data exchange between the Platforms and its Users happens over an SSL secured communication channel and is encrypted and protected with digital signatures.</li>
        </ol>
      </div>

      <div className="section">
        <h3>10. DATA TRANSFERS OUTSIDE INDIA</h3>
        <ol type="1">
        <li>We do not transfer any Personal Data outside the territory of India. All Personal Data collected and processed by us will remain stored and maintained within servers located in India, in compliance with Applicable Laws.</li>
        <li>If we transfer Personal Data outside India, we will ensure such transfers follow Applicable Laws</li>
        </ol>

      </div>

      <div className="section">
        <h3>11. YOUR LEGAL RIGHTS</h3>
        <ol>
          <li>Under the (Indian) Digital Personal Data Protection Act. 2023, you have the following rights: 
            <ol type="a">
              <li> <b>Right to access information:</b>  You can ask us for the following information:
              <ol type="i">
                <li>A summary of the Personal Data we have about you.</li>
                <li>What we do with your Personal Data.</li>
                <li>Who we share your Personal Data with.</li>
                <li>What Personal Data we have shared with other organizations.</li>
              </ol>
              </li>
              <li><b>Right to correction, completion, and erasure:</b> You have the right to ask us to correct, complete, or erase your Personal Data. This means that you can ask us to: 
              <ol type="i">
                <li>Fix any mistakes in your Personal Data.</li>
                <li>Add any missing information to your Personal Data.</li>
                <li>Delete your Personal Data.</li>
              </ol>
              </li>
              <li><b>Right to grievance redressal:</b> You have the right to complain to us if you believe we have violated your privacy rights. You may reach out to our Data Grievance Officer at support@synerthinksolutions.com to file a complaint. In the event of you not being satisfied with the resolution provided by our Data Grievance Officer, you may also reach out and complain to the Data Protection Board of India in the manner mentioned in paragraph 12 below.</li>
              <li><b>Right to nominate:</b>You can choose someone to exercise your privacy rights on your behalf if you are unable to do so yourself. This could be because of death or an inability to exercise your rights due to unsoundness or mind or bodily infirmity.</li>
              <li><b>Right to appoint consent manager:</b> You can choose to appoint a consent manager i.e., registered with the Data Protection Board of India to give, manage, review or withdraw consent on your behalf. </li>
              <li><b>Right to withdraw consent:</b> You can withdraw your consent to us processing your Personal Data at any time. We will stop processing your Personal Data once you withdraw your consent. However, withdrawing your consent will not affect any processing that we did before you withdrew your consent. We may also be unable to provide you with certain products or services if you withdraw your consent. We will tell you if this is the case when you withdraw your consent.</li>
            </ol>
          </li>
          <li>If you wish to withdraw your consent to us processing of your Personal Data, please e-mail us at support@synerthinksolutions.com.</li>
          <li>If you wish to exercise any other rights set out above, please contact us on the address mentioned in paragraph 16 hereinbelow.</li>
          <li>We may need to request specific information from you to help us verify your identity. This is a security measure to ensure that Personal Data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.</li>
          <li>The rights set out above are not absolute and are subject to  exemptions and limitations as provided under the Applicable Law.</li>
        </ol>
      </div>

      <div className="section">
        <h3>12. RIGHT TO COMPLAINT TO DATA PROTECTION BOARD OF INDIA</h3>
        <ol>
          <li>You also have the right to make a complaint at any time to the Data Protection Board of India, which is the supervisory authority for data protection in India on.</li>
        </ol>
      </div>

      <div className="section">
        <h3>13. EXTERNAL LINKS</h3>
        <ol>
          <li>Our Platforms may include links to third-party websites, plug-ins, and applications. Clicking on those links or enabling those connections may allow third parties to collect or share Personal Data about you. We do not control these third party websites and assume no responsibility or liability for the actions, products, services, and content of any other third parties. We encourage you to carefully review the legal statements and other conditions of use of for website you access.</li>
        </ol>
      </div>

      <div className="section">
        <h3>14. REFUSAL TO PROVIDE PERSONAL DATA</h3>
        <ol>
          <li>Where we need to collect Personal Data by Applicable Laws, or under the terms of any agreement we have with you, and you fail to provide that Personal Data when requested, we may not be able to deliver the functionalities of our Platforms or any part thereof.</li>
        </ol>
      </div>

      <div className="section">
        <h3>15. CHANGES TO THIS PRIVACY POLICY</h3>
        <ol>
          <li>We may change this Privacy Policy from time to time. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect, and we will always show the date of the latest modification of the Privacy Policy. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.</li>
        </ol>
      </div>

      <div className="section">
        <h3>16. CONTACT OUR DATA PROTECTION OFFICER</h3>
        <ol>
          <li>If you have any questions or comments about our policy outlined above, you may contact our Data Protection Officer whose role includes acting as a point of contact for users in relation to concerns around how their data is processed. The contact details are as follows:</li>
        </ol>

        <ul>
          <li>Data Protection Officer </li>
          <li>Email: <a href="mailto:support@synerthinksolutions.com">support@synerthinksolutions.com</a></li>
        </ul>
      </div>

      <div className="section">
        <h3>17. DISPUTE RESOLUTION</h3>
        <ol>
          <li>In the event of any dispute arising out of or in connection with this Privacy Policy, including disputes relating to the interpretation, breach, or enforcement of this Privacy Policy, they shall be resolved in conjunction with the procedures and dispute resolution mechanisms set forth in the Terms of Use.</li>
        </ol>
      </div>
    </div>
    </div>
    </PageWrapper>
  );
};

export default DashboardPrivacyPolicy;
