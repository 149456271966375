import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import "./PageContainer.css";
import "./PaymentHistory.css";
import download from "../../assets/images/download.png";
import viewPaymentHistory from "../../assets/images/view-payment-history.png";
import ExportExcel from "../../assets/images/ExportExcel.png";
import refresh from "../../assets/images/refresh.png";
import { downloadInvoicePayment, getinvoice, GetPaymentHistory, viewinvoice } from "../../API";
import Loader from "../../../components/Loader";
import searchIcon from "../../assets/images/search.png";
import usePopupModal from "../../../components/popUpModal";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { setPaymentHistoryData } from "../../../redux/Actions/UserAction";
import { downloadPdf, excelDownload } from "../../../utility/index";
import DateRangeFilter from "./DateRangeFilter";
import Calendar from "../../../components/Calendar";
import COLORS from "../../../utility/colors";

const PaymentHistory = () => {
	const { modalVisible, hideModal, showModal } = usePopupModal();
	const [modalType, setmodalType] = useState({});
	const { paymentHistoryData, locale } = useSelector((state) => state.user);
	const dispatch = useDispatch();
	const [isLoading, setLoading] = useState(false);
	const [isSelected, setSelected] = useState([]);
	const [fromDate, setFromDate] = useState("");
	const [toDate, setToDate] = useState("");
	const [search, setSearch] = useState();
	const [filteredDataSource, setFilteredDataSource] = useState();
	const [data, setData] = useState([]);
	const [invoiceData, setInvoiceData] = useState();
	const [isVisibleActionModal, setVisibleActionModal] = useState(false)
	const [seletedId, setSelectedId] = useState()

	const en = locale?.languageType === "English";

	const isMobile = useMediaQuery({
		query: "(max-width: 820px)",
	});
	useEffect(() => {
		getPaymentData();
	}, []);
	const getPaymentData = async () => {
		setLoading(true);
		let token = await localStorage.getItem("key");
		try {
			const result = await GetPaymentHistory(token);
			if (result.response === "ok") {
				dispatch(setPaymentHistoryData(result.data));
				console.log(result.data, "payment history");
				setLoading(false);
			} else {
				setLoading(false);
			}
		} catch (error) {
			setLoading(false);
		}
	};
	const onSearch = (text) => {
		if (text) {
			const newData = paymentHistoryData.filter(function (item) {
				const status = item?.status?.toUpperCase();
				const mode = item?.mode?.toUpperCase();
				const amount = item?.paid_amount?.toUpperCase();
				const tid = item?.orderid?.toUpperCase();
				const textData = text?.toUpperCase();
				return (
					amount?.indexOf(textData) > -1 ||
					status?.indexOf(textData) > -1 ||
					tid?.indexOf(textData) > -1 ||
					mode?.indexOf(textData) > -1
				);
			});
			setFilteredDataSource(newData);
			setSearch(text);
		} else {
			setFilteredDataSource();
			setSearch(text);
		}
	};
	const viewInvoicefunc = (ids) => {
		setLoading(true);
		let token = localStorage.getItem("key");
		let raw = JSON.stringify({ id: ids });
		try {
			viewinvoice(raw, token).then((response) => {
				console.log(response, "hhhh");
				if (response?.resposne === "ok") {
					setLoading(false);
					setInvoiceData(response.data);
					showModal(true);
					setmodalType(() => ({
						invoice: true,
					}));
				} else {
					toast.error(response.detail);
					setLoading(false);
				}
			});
		} catch (error) {
			toast.error("Something went wrong!");
			setLoading(false);
		}
	};

	const ViewInvoiceComponent = () => {
		return (
			<div className="modal-wrapper">
				<div
					style={{
						backgroundColor: "#fff",
						width: 540,
						paddingBottom: 60,
						paddingTop: 20,
						borderRadius: 12,
					}}
				>
					<i
						onClick={() => hideModal(false)}
						className="fa fa-times-circle fa-lg"
						style={{
							color: "#e98936",
							display: "flex",
							justifyContent: "flex-end",
							paddingRight: 20,
							cursor: "pointer",
						}}
					/>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<h2
							className="gradient-text"
							style={{
								color: "#e98936",
								marginBottom: 30,
								textTransform: "uppercase",
							}}
						>
							Statement Account Details
						</h2>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								width: "90%",
								marginBottom: 14,
							}}
						>
							<span className="invoice-text-primary">
								<lable>TXN-ID</lable>
							</span>
							<span className="invoice-text-secondry">
								<label>{invoiceData[0]?.orderid}</label>
							</span>
						</div>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								width: "90%",
								marginBottom: 14,
							}}
						>
							<span className="invoice-text-primary">
								<lable>Date & Time</lable>
							</span>
							<span className="invoice-text-secondry">
								<label>{invoiceData[0]?.datetime}</label>
							</span>
						</div>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								width: "90%",
							}}
						>
							<span className="invoice-text-primary">
								<lable>PAID AMOUNT</lable>
							</span>
							<span className="invoice-text-secondry">
								<label>{invoiceData[0]?.paid_amount}</label>
							</span>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const OpenDownloadFilter = () => {
		return (
			<div className="modal-wrapper">
				<div
					style={{
						backgroundColor: "#fff",
						width: 540,
						paddingBottom: 60,
						paddingTop: 20,
						borderRadius: 12,
					}}
				>
					<i
						onClick={() => {
							hideModal(false);
							setmodalType(() => ({
								invoice: false,
								download: false,
							}));
							setFromDate("");
							setToDate("");
						}}
						className="fa fa-times-circle fa-lg"
						style={{
							color: "#e98936",
							display: "flex",
							justifyContent: "flex-end",
							paddingRight: 20,
							cursor: "pointer",
						}}
					/>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<h2
							className="gradient-text"
							style={{
								color: "#e98936",
								marginBottom: 30,
								textTransform: "uppercase",
							}}
						>
							{en ? "Download Statement Of Account" : "चालान का विवरण डाउनलोड करें"}
						</h2>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								width: "90%",
								marginBottom: 14,
							}}
						>
							<span className="invoice-text-primary">
								<lable>{en ? "From Date:" : "प्रारंभ तिथ"}</lable>
							</span>
							{/* <span className="invoice-text-secondry"> */}
							<input
								value={fromDate}
								type="date"
								onChange={(e) => setFromDate(e.target.value)}
								style={style.customInput}
							/>
							{/* </span> */}
						</div>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								width: "90%",
								marginBottom: 14,
							}}
						>
							<span className="invoice-text-primary">
								<lable>{en ? "To Date:" : "समाप्ति तिथि"}</lable>
							</span>
							{/* <span className="invoice-text-secondry"> */}
							<input
								value={toDate}
								type="date"
								onChange={(e) => setToDate(e.target.value)}
								min={fromDate}
								style={style.customInput}
							/>
							{/* </span> */}
						</div>
						<a
							class="btn btn-1"
							onClick={() => {
								downloadInvoice();
							}}
						>
							{en ? "Download" : "डाउनलोड"}
						</a>
					</div>
				</div>
			</div>
		);
	};

	const downloadInvoice = () => {
		setLoading(true);
		let token = localStorage.getItem("key");
		let raw = JSON.stringify({
			from_date: fromDate,
			to_date: toDate,
		});
		try {
			getinvoice(raw, token).then((response) => {
				console.log(response, "download invoice");
				if (response.response === "ok") {
					setLoading(false);
					downloadPdf(response?.data, "paymentInvoice");
					hideModal(false);
					setmodalType(() => ({
						invoice: false,
						download: false,
					}));
					setFromDate("");
					setToDate("");
				} else {
					setLoading(false);
					toast.error(response.response);
				}
			});
		} catch (error) {
			setLoading(false);
			toast.error("Something went wrong!");
			console.log(error);
		}
	};

	const downloadIndividualInvoice = (id, value) => {
		setLoading(true);
		let token = localStorage.getItem("key");
		let raw = JSON.stringify({
			"id": id,
			"credit_note": value
		});
		try {
			downloadInvoicePayment(raw, token).then((response) => {
				console.log(response, "download invoice");
				if (response.response === "ok") {
					setLoading(false);
					downloadPdf(response?.data, "paymentInvoice");
				} else {
					setLoading(false);
					toast.error(response.response);
				}
			});
		} catch (error) {
			setLoading(false);
			toast.error("Something went wrong!");
			console.log(error);
		}
	};

	if (!isMobile) {
		return (
			<div className="payement-table-container">
				<div className="refresh">
					<div
						onClick={() => getPaymentData()}
						style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
					>
						<img
							src={refresh}
							alt="refresh"
							style={{ width: 19.5, height: 20, paddingRight: 20 }}
						/>
						<span>{en ? "Payment History" : "भुगतान इतिहास"}</span>
					</div>
					<div style={{ display: "flex", marginRight: 20 }}>
						<div className="input-wrapper" style={{ marginRight: 20 }}>
							<input
								className="input"
								placeholder={en ? "Search" : "खोजें"}
								onChange={(e) => onSearch(e.target.value)}
							/>
							<img
								src={searchIcon}
								alt="search"
								style={{ width: 18, height: 18 }}
							/>
						</div>
						<img
							onClick={() => {
								showModal(true);
								setmodalType(() => ({
									invoice: false,
									download: true,
								}));
							}}
							src={download}
							alt="Download Statement Of Account"
							title="Download Statement Of Account"
							className="download-image"
						/>
					</div>
				</div>
				{isLoading && <Loader />}
				<table className="payment-table-wrapper">
					<tr className="row-wrapper" style={{ fontSize: 14 }}>
						<th>{en ? "Sr. No." : "क्रमांक"}</th>
						<th>{en ? "TXN-ID" : "लेन-देन आईडी"}</th>
						<th>{en ? "Mode" : "मोड"}</th>
						<th>{en ? "Status" : "स्थिति"}</th>
						<th>{en ? "Amount" : "राशि"}</th>
						<th>Action</th>

					</tr>
					{(search ? filteredDataSource : paymentHistoryData).map(
						(item, index) => {
							const formattedIndex = (index + 1).toString().padStart(2, "0");
							return (
								<tr className="payment-table-list">
									<td>
										<span>{formattedIndex}.</span>
									</td>
									<td>
										<div style={{ display: "flex", flexDirection: "column" }}>
											<span
												style={{
													backgroundColor: "#ddd",
													padding: 6,
													borderRadius: 4,
												}}
											>
												{item?.orderid}
											</span>
										</div>
									</td>
									<td>{item?.mode}</td>
									<td
										style={{
											color: item?.status === "SUCCESS" ? "GREEN" : "red",
											fontWeight: "600",
										}}
									>
										{item?.status}
									</td>
									<td>{item?.paid_amount}</td>
									<td style={{ position: 'relative' }}>
										<i onClick={() => {
											setVisibleActionModal(true);
											setSelectedId(item.orderid)
											setTimeout(() => {
												setVisibleActionModal(false);
											}, 3000);
										}} class="fa fa-ellipsis-v" aria-hidden="true" style={{ color: item?.refund_status || !(item?.mode === "Welcome Credit" || item?.mode === null) && item?.status === "SUCCESS" ? COLORS.blue : COLORS.disabledBlack, cursor: 'pointer' }}></i>
										{isVisibleActionModal && seletedId === item?.orderid && <div style={{ background: '#fff', height: 'auto', width: 160, position: 'absolute', right: 50, borderRadius: 8, zIndex: 999, boxShadow: item?.refund_status || !(item?.mode === "Welcome Credit" || item?.mode === null) && item?.status === "SUCCESS" ? '0px 4px 6px 4px rgba(0, 0, 0, 0.2)' : '' }}>
											{item?.refund_status && <div onClick={() => { downloadIndividualInvoice(item.orderid, 1) }} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 6, borderBottom: '1px solid #ddd', padding: '10px 8px', cursor: 'pointer' }}>
												<img
													src={viewPaymentHistory}
													alt="viewPaymentHistory"
													title="Invoice must be 'SUCCESS' and mode cannot be 'Bonus Credit' or 'Welcome Credit'."
													style={{
														width: 12,
														cursor: "pointer",

													}}
												/>
												<span style={{ fontSize: 12 }}>Download Credit Note</span>
											</div>}

											{!(item?.mode === "Welcome Credit" || item?.mode === null) && item?.status === "SUCCESS" && <div onClick={() => { downloadIndividualInvoice(item.orderid, 0) }} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 6, padding: '10px 8px', cursor: 'pointer' }}>
												<img
													src={viewPaymentHistory}
													alt="viewPaymentHistory"
													title="Invoice must be 'SUCCESS' and mode cannot be 'Bonus Credit' or 'Welcome Credit'."
													style={{
														width: 12,
														cursor: "pointer",

													}}
												/>
												<span style={{ fontSize: 12 }}>Download Invoice</span>
											</div>}

										</div>}
									</td>
								</tr>
							);
						}
					)}
				</table>
				{modalVisible && modalType?.invoice && <ViewInvoiceComponent />}
				{modalVisible && modalType?.download && <OpenDownloadFilter />}
			</div>
		);
	} else {
		return (
			<div className="payement-table-container">
				<div className="payment-refresh">
					<div
						onClick={() => getPaymentData()}
						style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
					>
						<img
							src={refresh}
							alt="refresh"
							style={{ width: 19.5, height: 20, paddingRight: 20 }}
						/>
						<span>{en ? "Payment History" : "भुगतान इतिहास"}</span>
					</div>
					<div>
						<img
							src={download}
							alt="Download Statement Of Account"
							title="Download Statement Of Account"
							style={{
								width: 14,
								marginRight: 14,
							}}
							onClick={() => {
								showModal(true);
								setmodalType(() => ({
									invoice: false,
									download: true,
								}));
							}}
						/>
					</div>
				</div>
				{isLoading && <Loader />}

				<div className="payment-table-wrapper-mweb">
					{paymentHistoryData.map((item) => (
						<>
							<div
								onClick={() => setSelected(item?.orderid)}
								key={item.orderid} // Don't forget to add a unique key for each item when mapping over an array
								className={"payment-table-list-mobile"}
								style={{ flexDirection: 'column' }}
							>
								<div
									style={{ flex: 1, display: "flex", flexDirection: "column" }}
								>
									<div
										style={{ display: "flex", justifyContent: "space-between" }}
									>
										<div className="payment-table-mobile-username-date">
											<span style={{ fontWeight: "bold" }}>
												{item?.product_info}
											</span>
											<span
												style={{
													backgroundColor: "#ddd",
													padding: 4,
													borderRadius: 4,
												}}
											>
												{item?.orderid.slice(0, 20)}...
											</span>
										</div>
									</div>
									<div className="payment-table-mobile-subject">
										<span style={{ width: "100%" }}>{en ? "Mode" : "मोड"}</span>
										<span style={{ width: "100%" }}>{en ? "Status" : "स्थिति"}</span>
										<span style={{ width: "40%" }}>{en ? "Amount" : "राशि"}</span>
									</div>
									<div className="payment-table-mobile-subject-value">
										<span style={{ width: "100%" }}>{item?.mode}</span>
										<span
											style={{
												color: item?.status === "SUCCESS" ? "GREEN" : "red",
												fontWeight: "400",
												width: "100%",
											}}
										>
											{item?.status}
										</span>
										<span style={{ width: "40%" }}>{item?.paid_amount}</span>
									</div>
								</div>
								<div style={{ display: 'flex', fontSize: 11, justifyContent: 'space-between', marginTop: 12 }}>
									{(!(item?.mode === "Welcome Credit" || item?.mode === null) && item?.status === "SUCCESS") && <span onClick={() => downloadIndividualInvoice(item.orderid, 0)} style={{ background: COLORS.blue, color: '#fff', padding: 4, borderRadius: 4 }}>{en ? "Download Invoice" : "चालान डाउनलोड करें"}</span>}
									{item?.refund_status && <span onClick={() => downloadIndividualInvoice(item.orderid, 1)} style={{ background: COLORS.blue, color: '#fff', padding: 4, borderRadius: 4 }}>{en ? "Download Credit Note" : "क्रेडिट नोट डाउनलोड करें"}</span>}
								</div>
							</div>

						</>


					))}
				</div>
				{modalVisible && modalType?.invoice && <ViewInvoiceComponent />}
				{modalVisible && modalType?.download && <OpenDownloadFilter />}
			</div>
		);
	}
};

const style = {
	customInput: {
		border: "0px solid transparent",
		backgroundColor: "#f8f9fa",
		color: "#333",
		fontSize: "16px",
		outline: "none",
		transition: "border-color 0.3s ease",
		width: "100%",
	},
};

export default PaymentHistory;
