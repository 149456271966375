import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import Input from "../../../components/Input";
import Loader from "../../../components/Loader";
import { getCertificate } from "../../API";
import username from "../../assets/images/username.png";
import { setModalData } from "../../../redux/Actions/UserAction";
import styles from '../dashboard/modalStyle.module.css'
import withBlackBackground from "../dashboard/withBlackBackground";
const CertifiEmail = () => {
	const { locale } = useSelector((state) => state?.user);
	const navigate = useNavigate();
	const dispatch = useDispatch()
	const isMobile = useMediaQuery({
		query: "(max-width: 1024px)",
	});
	const [loginCredential, setLoginCredential] = useState({});
	const onChangeText = (val, key_name) => {
		setLoginCredential((previous) => ({
			...previous,
			[key_name]: val,
		}));
	};

	const handleSubmit = async () => {
		const { mob_num, receiver_otp } = loginCredential || {};
		if (!mob_num || !receiver_otp)
			return toast.error("Both fields are required!");
		const data = {
			mob_num: loginCredential?.mob_num,
			receiver_otp: loginCredential?.receiver_otp,
		};
		const result = await getCertificate(JSON.stringify(data));
		if (result?.response == "ok") {
			dispatch(setModalData({ type: 'CertifyEmailDetails', isVisible: true, data: {data: result } }))
			// navigate("/certifi-email-details", {
			// 	state: { data: result },
			// });
		}else{
			toast.error("Invalid OTP");
		}
	};

	return (
		<div className={styles.modalAnimation} style={{position: 'absolute', maxWidth:'340px', height:'300px',}}>
			<span onClick={() => dispatch(setModalData({ type: '', isVisible: false }))} style={{ position: 'absolute', top: -14, right: '10px', cursor: 'pointer', fontWeight: '900', right: -8, background: '#fff', padding: 4, height: 20, width: 20, textAlign: 'center', borderRadius: 100 }}>x</span>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					width: "100%",
					margin: "auto",
				}}
			>
				<div style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '20px',
					marginBottom: 20,
				}}>
					<img src={require("../../assets/images/logo.png")} style={{ width: 60, alignSelf: "center", marginBottom: 0 }} />
					<span style={{ textAlign: 'center' }}>{locale?.CertifiedDesc}</span>
				</div>
				<Input
					icon={username}
					placeholder={locale?.languageType==="English" ? "Enter Phone Number" : "फोन नंबर दर्ज कर"}
					onChangeText={onChangeText}
					key_name="mob_num"
					value={loginCredential?.mob_num}
					maxLength={10}
				/>
				<Input
					icon={username}
					placeholder={locale?.languageType==="English" ? "Enter OTP":"ओटीपी दर्ज कर"}
					onChangeText={onChangeText}
					key_name="receiver_otp"
					value={loginCredential?.receiver_otp}
					maxLength={8}
				/>
			</div>
			<div style={{ marginTop: 40, display:'flex',margin:'auto', width:200}}>
				<a onClick={handleSubmit} class="btn btn-1">
					{locale?.SubmitOTP}
				</a>
			</div>
		</div>
	);
};

export default withBlackBackground(CertifiEmail);
