import React, { useEffect, useState } from "react";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import Footer from "./components/Footer";
import HeroSectionAbout from "./components/HeroSectionAbout";
import DigitallySignedCertificate from "./components/DigitallySignedCertificate";
import UseCases from "./components/UseCases";
import PageWrapper from "./common/components/PageWrapper";
import useLocale from "../../../utility/useLocal";
import './styles.css';
import ConsultantRegister from "./components/ConsultantRegister";
import { useSelector } from "react-redux";

const Dashboard = () => {
  const locale = useLocale();
  const {
    UseCases:UseCases1
  } = locale;

const isEnglish = locale?.languageType=="English"
  const StickyComponent = () => {
    return (
      <div className="sticky-container">
        <div className="vertical-lr">
        <div className="post-side-tab">Login</div>
          <div className="post-side-tab-chosen">Register As a Consultant</div>
        </div>
      </div>
    );
  };


  return (
    <PageWrapper>
      {/* <StickyComponent/> */}
      <HeroSection />
      <HeroSectionAbout />
      <DigitallySignedCertificate />
      <UseCases
        heading={UseCases1}
        data={[
          {
            title: isEnglish ? "Financial Services (Banks and Insurance Companies)" : "ित्तीय सेवाएँ (बैंक और बीमा कंपनियाँ)",
            content: isEnglish ? "Deliver Policy Documents, Agreements, Invoices, payment reminders, premium receipts, and policy-related updates with certified proof of communication.":"पॉलिसी दस्तावेज़, समझौते, चालान, भुगतान अनुस्मारक, प्रीमियम रसीदें, और पॉलिसी से संबंधित अपडेट प्रमाणित संचार के साथ भेजें। ",
          },
          {
            title: isEnglish ? "Legal and Regulatory Bodies":"कानूनी और नियामक संस्थाएँ",
            content: isEnglish ? "Send legally compliant notices, summons, court orders, and case-related documents with end-to-end certification.":"कानूनी रूप से अनुपालक नोटिस, सम्मन, अदालती आदेश, और मामलों से जुड़े दस्तावेज़ पूर्ण प्रमाणित प्रक्रिया के साथ भेजें। ",
          },
          {
            title: isEnglish ? "Government Departments":"सरकारी विभाग",
            content: isEnglish ? "Notify citizens about taxes, fines, FIRs, municipal dues, and other important official communications in a secure, verifiable manner.": "नागरिकों को कर, जुर्माने, एफआईआर, नगरपालिका बकाया, और अन्य महत्वपूर्ण आधिकारिक सूचनाएँ सुरक्षित और सत्यापन योग्य तरीके से सूचित करें। ",
          },
          {
            title: isEnglish ? "General Public":"सामान्य जनता",
            content: isEnglish ? "Share important documents such as contracts, tenders, wills, financial information and KYC data with peace of mind and certified validation. Simple yet important documents like marriage invitation, party invites can be reliably delivered." : "महत्वपूर्ण दस्तावेज़ जैसे अनुबंध, निविदा, वसीयत, वित्तीय जानकारी, और केवाईसी डेटा को प्रमाणित और निश्चिंत तरीके से साझा करें। साधारण लेकिन महत्वपूर्ण दस्तावेज़, जैसे शादी के निमंत्रण और पार्टी निमंत्रण, भी भरोसेमंद रूप से भेजे जा सकते हैं। ",
          },
          {
            title: isEnglish ? "Educational Institutions":"शैक्षिक संस्थान",
            content: isEnglish ? "Provide students with exam notifications, results, admission letters, and degree-related updates through secure, certified channels.":"छात्रों को परीक्षा सूचनाएँ, परिणाम, प्रवेश पत्र, और डिग्री से संबंधित अपडेट सुरक्षित और प्रमाणित चैनलों के माध्यम से प्रदान करें।  ",
          },
          {
            title: isEnglish ? "Corporates and Enterprises":"कॉर्पोरेट और उद्यम",
            content: isEnglish ?"Enhance important internal and external communications, including HR updates, increment letters, agreement, PF, ESI and other statutory requirements, vendor correspondence, and customer interactions, with certified assurance.": "महत्वपूर्ण आंतरिक और बाहरी संचार जैसे एचआर अपडेट, वेतनवृद्धि पत्र, समझौते, पीएफ, ईएसआई और अन्य सांविधिक आवश्यकताओं, विक्रेता पत्राचार, और ग्राहक इंटरैक्शन को प्रमाणित विश्वास के साथ बढ़ाएँ।",
          },
        ]}
      />
    </PageWrapper>
  );
};

export default Dashboard;
