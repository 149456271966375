import React from "react";
import PageWrapper from "../common/components/PageWrapper";
import AboutHeader from "../About/components/AboutHeader";
import AboutConent from "../About/components/AboutConent";
import VisionandMission from "../About/components/VisionandMission";
import Services from "../../../assets/images/Services.jpg";
import UseCases from "../components/UseCases";
import useLocale from "../../../../utility/useLocal";


const DashboardServices = () => {
  const locale = useLocale();
  const {
    Services:Heading,
    WhatMakesUsDifferent,
  } = locale

  const isEnglish = locale?.languageType=="English"
  return (
    <PageWrapper>
      <AboutHeader heading={Heading} />
      <AboutConent
        style={{
          height: "80vh",
        }}
        heading={WhatMakesUsDifferent}
        image={Services}
        content={[
          isEnglish ? `We are committed to delivering Reliable, Secure, and Cost-Effective communication solutions that empower businesses and individuals to communicate effectively in today’s digital world.`:'हम विश्वसनीय, सुरक्षित और किफायती संचार समाधान प्रदान करने के लिए प्रतिबद्ध हैं जो व्यवसायों और व्यक्तियों को आज की डिजिटल दुनिया में प्रभावी ढंग से संवाद करने में सक्षम बनाते हैं',
          isEnglish ? `Through our patented ‘Certified Communication’ solution under the brand name of CERTIFI, you can be assured of an authentic, tamper proof communication with a complete forensic audit trail, giving credibility to the same communication.`:'हमारे पेटेंटेड `सर्टिफाइड कम्युनिकेशन` समाधान के माध्यम से, जिसे CERTIFI ब्रांड नाम के तहत पेश किया गया है, आप एक प्रामाणिक, छेड़छाड़-प्रूफ संचार के साथ एक पूर्ण फोरेंसिक ऑडिट ट्रेल का आश्वासन प्राप्त कर सकते हैं, जो उसी संचार को विश्वसनीयता प्रदान करता है।',
          isEnglish ?`One of the unique features of CERTIFI is that we certify the content of the communication and issue a digitally signed certificate duly time stamped by a Govt. licensed timestamping agency. Security and privacy are ensured with encrypted end-to-end communication which is fully automated.`:'CERTIFI की एक अनूठी विशेषता यह है कि हम संचार की सामग्री को प्रमाणित करते हैं और एक डिजिटल रूप से हस्ताक्षरित प्रमाणपत्र जारी करते हैं, जिसे सरकार द्वारा लाइसेंस प्राप्त टाइमस्टैम्पिंग एजेंसी द्वारा समयबद्ध किया जाता है। सुरक्षा और गोपनीयता को एन्क्रिप्टेड एंड-टू-एंड संचार के साथ सुनिश्चित किया जाता है जो पूरी तरह से स्वचालित है।',
          isEnglish ?`We know that every customer is unique which is why our services are designed to adapt to your specific goals, industry, and audience. From customized solution to ongoing support to managed services, we’re here to ensure success at every stage.`:'हम जानते हैं कि हर ग्राहक अद्वितीय है, यही कारण है कि हमारी सेवाएं आपके विशिष्ट लक्ष्यों, उद्योग और दर्शकों के अनुरूप डिज़ाइन की गई हैं। अनुकूलित समाधान से लेकर निरंतर समर्थन और प्रबंधित सेवाओं तक, हम हर चरण में सफलता सुनिश्चित करने के लिए यहां हैं।',
          isEnglish ?`We stay ahead of the curve, leveraging the latest technologies to address evolving threats and challenges. With our innovative solutions, you can trust that your communication is in expert hands.`:'हम नवीनतम तकनीकों का लाभ उठाते हुए, उभरते खतरों और चुनौतियों का समाधान करने के लिए आगे बढ़ते रहते हैं। हमारे अभिनव समाधानों के साथ, आप भरोसा कर सकते हैं कि आपका संचार विशेषज्ञ हाथों में है',
          isEnglish ?`With a steadfast focus on reliability, security, authenticity, and customer satisfaction, we are proud to serve as a trusted partner for businesses and individuals alike. Enhance the effectiveness of all your important communications with CERTIFI.`:'िश्वसनीयता, सुरक्षा, प्रामाणिकता और ग्राहक संतुष्टि पर अडिग ध्यान केंद्रित करने के साथ, हम व्यवसायों और व्यक्तियों के लिए एक विश्वसनीय भागीदार के रूप में सेवा करने पर गर्व करते हैं। CERTIFI के साथ अपने सभी महत्वपूर्ण संचार की प्रभावशीलता को बढ़ाएं।'
        ]}
      />
      <UseCases
        heading={isEnglish ? "Benefits":'लाभ'}
        data={[
          {
            title: isEnglish ? "Content Certification for Maximum Reliability":"⁠अधिकतम विश्वसनीयता के लिए संवाद प्रमाणीकरण ",
            content: null,
          },
          {
            title: isEnglish ? "Double Notifications, Instant Attention":"डबल नोटिफिकेशन, तुरंत ध्यान आकर्षित करें ",
            content: null,
          },
          {
            title: isEnglish ? "Tamper-proof with Unmatched Document Integrity":" ⁠छेड़छाड़-रहित और बेजोड़ दस्तावेज़ सुरक्षा ",
            content: null,
          },
          {
            title: isEnglish ? "Simple and Effortless Integration":"⁠सरल और सहज इंटीग्रेशन",
            content: null,
          },
          {
            title:isEnglish ? "Legally Sound, Trusted where it matters":"⁠कानूनी रूप से मान्य, जहाँ विश्वास सबसे अधिक मायने रखता ह",
            content: null,
          },
          {
            title: isEnglish ? "Built for Confidence, Backed by Technology":"आत्मविश्वास के लिए डिज़ाइन किया गया, तकनीक द्वारा समर्थित",
            content: null,
          },
        ]}
      />
    </PageWrapper>
  );
};



export default DashboardServices;
