import React from "react";
import PageWrapper from "../common/components/PageWrapper";
import AboutHeader from "./components/AboutHeader";
import AboutConent from "./components/AboutConent";
import VisionandMission from "./components/VisionandMission";
import SMSmarketing from "../../../assets/images/SMS-marketing.jpg";
import useLocale from "../../../../utility/useLocal";

const DashBoardAbout = () => {
  const locale = useLocale()
  const {
    AboutUS,
    Vision,
    VisionContent,
    Mission,
  } = locale;

  const isEnglish = locale?.languageType=="English"
  return (
    <PageWrapper>
      <AboutHeader heading={AboutUS} />
      <AboutConent
        heading={AboutUS}
        image={SMSmarketing}
        content={[isEnglish ? `At Synerthink, we combine trust and technology to revolutionize communication. Co-founded by entrepreneurs with vast experience in the global telecom ecosystem, communications are in the company’s DNA.`:'Synerthink में, हम विश्वास और तकनीक को मिलाकर संचार में क्रांति लाने का काम करते हैं। वैश्विक टेलीकॉम इकोसिस्टम में व्यापक अनुभव रखने वाले उद्यमियों द्वारा सह-स्थापित, संचार हमारी कंपनी के डीएनए में है।',

          isEnglish ? `Our flagship product, CERTIFI, is a Certified Communication Service designed to provide secure, reliable, and legally compliant communication service for all your important written communication. With features like timestamping, content certification, and encryption, CERTIFI ensures every communication you send is Certified, tamper-proof, encrypted, and trusted. CERTIFI ensures full privacy between sender and receiver.`:'हमारा प्रमुख उत्पाद, CERTIFI, एक प्रमाणित संचार सेवा है जो आपके सभी महत्वपूर्ण लिखित संचार के लिए सुरक्षित, भरोसेमंद और कानूनी रूप से अनुपालक सेवा प्रदान करता है। टाइमस्टैम्पिंग, सामग्री प्रमाणन और एन्क्रिप्शन जैसी सुविधाओं के साथ, CERTIFI यह सुनिश्चित करता है कि आपका हर संचार प्रमाणित, छेड़छाड़-रहित, एन्क्रिप्टेड और भरोसेमंद हो। CERTIFI प्रेषक और प्राप्तकर्ता के बीच पूर्ण गोपनीयता सुनिश्चित करता है।',
          isEnglish ? `As a proud contributor to India’s digital transformation, Synerthink is committed to creating solutions that are not only efficient and secure but also eco-friendly. At the heart of everything we do is a simple promise: “Your Trust, Our Technology.”`:'भारत के डिजिटल परिवर्तन में गर्व से योगदान करते हुए, Synerthink उन समाधान बनाने के लिए प्रतिबद्ध है जो न केवल प्रभावी और सुरक्षित हैं बल्कि पर्यावरण के अनुकूल भी हैं। हमारे हर प्रयास के केंद्र में एक सरल वादा है: "आपका विश्वास, हमारी तकनीक।"']}
      />
      <VisionandMission
        data={[
          {
            title: Vision,
            content: VisionContent,
          },
          {
            title: Mission,
            content: isEnglish ? "Our mission is to empower individuals and organizations by providing secure, tamper-proof, and certified communication solutions that inspire confidence and streamline operations. Through CERTIFI, we aim to enhance communication reliability, foster trust, and create value-driven experiences for our users.":'हमारा मिशन व्यक्तियों और संगठनों को सुरक्षित, छेड़छाड़-रहित, और प्रमाणित संचार समाधान प्रदान करके उन्हें सशक्त बनाना है, जो विश्वास को प्रेरित करता है और संचालन को सरल बनाता है। CERTIFI के माध्यम से, हम संचार की विश्वसनीयता को बढ़ाने, विश्वास को बढ़ावा देने, और उपयोगकर्ताओं के लिए मूल्य-आधारित अनुभव बनाने का लक्ष्य रखते हैं',
          },
        ]}
      />
    </PageWrapper>
  );
};

export default DashBoardAbout;
