import React from "react";
import styles from "../styles/UseCases.module.css";
import COLORS from "../../../../utility/colors";
import useLocale from "../../../../utility/useLocal";

function UseCases({
  heading = "",
  data = []
}) {
  const locale = useLocale();

const isEnglish = locale?.languageType=="English"
  return (
    <div className={styles.UseCases}>
      <div className={styles.innerWrapper}>
        <div className={styles.headingWrapper}>
          <span className={styles.heading}>{heading}</span>
        </div>
        <div className={styles.cardContainer}>
          {data.map(({ title, content }, i) => (
            <div className={styles.card} key={i}>
              <div className={styles.iconContainer}>
                <i class="fa fa-quote-left" />
              </div>
              <div className={styles.tileandContentContainer}>
                <h3 className={styles.cardTitle}>{title}</h3>
                <p className={styles.cardContent}>
                  {content}
                </p>
              </div>
            </div>
          ))}
        {window.location.pathname==="/" && <span style={{margin:'10px 0px', color:COLORS.white, fontWeight:'600', alignSelf:'center', width:window.innerWidth/1.4}}>{isEnglish ? "CERTIFI transforms the way communication is trusted, making it an essential tool across industries and use cases. Choose CERTIFI for sending secure, certified, and reliable communication that leaves no room for doubt.":"*CERTIFI* भरोसेमंद संचार के तरीके को बदलता है, जिससे यह सभी उद्योगों और उपयोग मामलों के लिए एक आवश्यक उपकरण बन जाता है। *CERTIFI* को चुनें ताकि आपका संचार सुरक्षित, प्रमाणित, और भरोसेमंद हो, जिसमें किसी भी प्रकार की शंका की कोई गुंजाइश न हो।"}</span>}

        </div>
      </div>
    </div>
  );
}

export default UseCases;
