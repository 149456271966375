import React, { memo, useState } from "react";
import styles from "../styles/Header.module.css";
import logo from "../../../assets/images/hrlogo.png";
import menuburger from "../../../assets/images/burger.png";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import useLocale from "../../../../utility/useLocal";
import { setModalData } from "../../../../redux/Actions/UserAction";
import { useDispatch } from "react-redux";
// import { useSelector } from "react-redux";

const Header = ({ handleLanguageChange, language }) => {
  // const { locale } = useSelector((state) => state.user);
  const locale = useLocale();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 1280px)" });
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dispatch = useDispatch()
  // Toggle menu function
  const toggleMenu = () => setIsMenuOpen((prev) => !prev);
  const handleRedirect = (path) => {
    console.log(path, "path");

    if (path == "openModal") {
      return dispatch(setModalData({ type: 'CertifyEmail', isVisible: true }))
    }
    if (path == "/contact") {
      return dispatch(setModalData({ type: 'Contact', isVisible: true }))
    }
    else {
      return navigate(path)
    }
  }
  const renderNavLink = (path, label) => (
    <a onClick={() => handleRedirect(path)} className={styles.navLink}>
      {label}
    </a>
  );

  const renderHeader = () => (
    <div className={styles.nav}>
      {renderNavLink("/", locale.Home)}
      {renderNavLink("/DashBoardAbout", locale.About)}
      {renderNavLink("/DashBoardServices", locale.Services)}
      {renderNavLink("openModal", locale.CERTIFI_EMAIL)}
      {renderNavLink("/contact", locale.ContactUs)}

      <div className={styles.privacy}>
        {locale.PrivacyPolicy}
        <div className={styles.privacyBox}>
          {/* {renderNavLink("/DashBoardAbout", locale.PrivacyPolicy)} */}
          {/* {renderNavLink("/DashBoardAbout", "Terms & Conditions")} */}
          <a
            onClick={() => navigate("/privacy-policy")}
            className={styles.dropdownItem}
          >
            Privacy Policy
          </a>
          <a
            onClick={() => navigate("/dashboardTermsandConditions")}
            className={styles.dropdownItem}
          >
            User Agreement
          </a>
          {/* <a onClick={() => navigate("/cookie-policy")}
            className={styles.dropdownItem}
          >
            Cookie Policy
          </a> */}
          <a
            onClick={() => navigate("/refund-policy")}
            className={styles.dropdownItem}
          >
            Refund Policy
          </a>
          <a
            onClick={() => navigate("/disclaimer-policy")}
            className={styles.dropdownItem}
          >
            Disclaimer Policy
          </a>
          <a
            onClick={() => navigate("/charges")}
            className={styles.dropdownItem}
          >
            Charges
          </a>
        </div>
      </div>
      <select
        onChange={(e) => handleLanguageChange(e.target.value)}
        className="language-selector"
        style={{ padding: "5px", borderRadius: "4px" }}
        value={language}
      >
        <option value="en">🌐 English</option>
        <option value="hi">🌐 Hindi</option>
      </select>
    </div>
  );

  return (
    <header className={styles.header}>
      <div className={styles.logoContainer}>
        <img onClick={() => { navigate("/"); window.scrollTo(0, 0) }} src={logo} alt="Logo" className={styles.logoImage} style={{ cursor: 'pointer' }} />
        {isMobile && (
          <div className={styles.menuWrapper}>
            <img
              src={menuburger}
              alt="Menu"
              className={styles.menuBurgerIcon}
              onClick={toggleMenu}
            />
          </div>
        )}
      </div>
      {(isMobile && isMenuOpen) || !isMobile ? renderHeader() : null}
    </header>
  );
};

export default memo(Header);
