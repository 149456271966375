// src/app/screens/dashboard/TermsandConditions/index.js
import React from "react";
import PageWrapper from "../common/components/PageWrapper";
import PageHeader from "../common/components/PageHeader";
import styles from "../PrivacyPolicy/styles/privacypolicy.module.css"
import COLORS from "../../../../utility/colors";

const Charges = () => {
    return (
        <PageWrapper>
            <div className={styles.privacyParentContainer}>
                <div className={styles.privacyContainer}>
                    <PageHeader string={"Charges Policy"} />
                    <div>
                        <h3>Charges as on 21st Jan 2025 excluding Taxes, Duties, Levies [In Indian National Rupees ‘INR’]</h3>
                        <div style={{border:'1px solid gray', padding:'10px 0', borderRadius:8, height:window.innerHeight/1.5,}}>
                        <section style={{display:'flex', justifyContent:'space-between', borderBottom:'1px solid gray', padding:'0 20px',height:(window.innerHeight/3)/3, alignItems:'center'}}>
                            <h4 style={{width:'80%', color:COLORS.blackThree}}>	Certified communication delivery with standard certification</h4>
                            <p><strong>INR 10.00</strong></p>
                        </section>

                        <section style={{display:'flex', justifyContent:'space-between', borderBottom:'1px solid gray', padding:'0 20px',height:(window.innerHeight/3)/3, alignItems:'center'}}>
                            <h4 style={{width:'80%', color:COLORS.blackThree}}>Certified communication delivery with standard certification and forensic audit trail</h4>
                            <p><strong>INR 12.00</strong></p>
                        </section>

                        <section style={{display:'flex', justifyContent:'space-between', borderBottom:'1px solid gray', padding:'0 20px',height:(window.innerHeight/3)/3, alignItems:'center'}}>
                            <h4 style={{width:'80%', color:COLORS.blackThree}}>Certified communication delivery with 63 BSA-compliant certification</h4>
                            <p><strong>INR 12.00</strong></p>
                        </section>
                        <section style={{display:'flex', justifyContent:'space-between', borderBottom:'1px solid gray', padding:'0 20px',height:(window.innerHeight/3)/3, alignItems:'center'}}>
                            <h4 style={{width:'80%', color:COLORS.blackThree}}>Certified communication delivery with 63 BSA-compliant certification and forensic audit trail</h4>
                            <p><strong>INR 14.00</strong></p>
                        </section>
                        <section style={{display:'flex', justifyContent:'space-between', borderBottom:'1px solid gray', padding:'0 20px',height:(window.innerHeight/3)/3, alignItems:'center'}}>
                            <h4 style={{width:'80%', color:COLORS.blackThree}}>Reverification service for certified communication</h4>
                            <p><strong>INR 4.00</strong></p>
                        </section>
                        <section style={{display:'flex', justifyContent:'space-between', padding:'0 20px',height:(window.innerHeight/3)/3, alignItems:'center'}}>
                            <h4 style={{width:'80%', color:COLORS.blackThree}}>Reverification service for certified communication in 63 BSA-format certification</h4>
                            <p><strong>INR 6.00</strong></p>
                        </section>
                        </div>



                        <section>
                            <h3>Disclaimer:</h3>
                            <p>
                                The certificate(s) sought by M/s. Synerthink Solutions LLP’s (“Synerthink”, “we”, “us”, or “our”) user(s) will not be retained (and will be deleted) from Synerthink’s systems after two days (A+2 days) of generation of the certificate(s). In the event that Synerthink’s user requires Synerthink to store such certificate(s) on Synerthink’s systems/database beyond such period, then the user is advised to choose the suitable option at the time of availing/selecting the service.
                            </p>
                            <p>
                                Synerthink reserves the rights to change the above charges as per prevalent market rates from time to time at its own discretion and will be updated here. Users are advised to regularly update themselves of any changes to these charges. Synerthink is not obligated to separately notify its users of any changes to the above charges.
                            </p>
                            <p>
                                Synerthink reserves the right to introduce and offer additional services, features, or transactions at our discretion, which may be subject to separate fees or charges. The user agrees to pay any such fees or charges applicable to these additional services or transactions, should the user choose to access or utilize them. Synerthink further reserves the right to modify, discontinue, or otherwise change the nature of the services offered, including introducing new services or features, with or without notice. The user’s continued use of Synerthink's CERTIFI platform constitutes the user’s agreement to any applicable fees or terms associated with these additional offerings.
                            </p>
                        </section>
                    </div>
                </div>
            </div>
        </PageWrapper>
    );
};

export default Charges;