import React, { useState } from "react";
import "./login.css";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import username from "../../assets/images/username.png";
import email from "../../assets/images/white-email.png";
import phone from "../../assets/images/phone.png";
import location from "../../assets/images/location.png";
import hash from "../../assets/images/hash.png";

import CryptoJS from "crypto-js";
import { register } from "../../API";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import withBlackBackground from "../dashboard/withBlackBackground";
import { setModalData } from "../../../redux/Actions/UserAction";
import styles from '../dashboard/modalStyle.module.css'
import { useMediaQuery } from "react-responsive";
import COLORS from "../../../utility/colors";
const Registration = () => {
	const { locale } = useSelector((state) => state.user);

	const stateData = [
		{ "state": "Jammu & Kashmir", "stateValue": "01" },
		{ "state": "Himachal Pradesh", "stateValue": "02" },
		{ "state": "Punjab", "stateValue": "03" },
		{ "state": "Chandigarh", "stateValue": "04" },
		{ "state": "Uttarakhand", "stateValue": "05" },
		{ "state": "Haryana", "stateValue": "06" },
		{ "state": "Delhi", "stateValue": "07" },
		{ "state": "Rajasthan", "stateValue": "08" },
		{ "state": "Uttar Pradesh", "stateValue": "09" },
		{ "state": "Bihar", "stateValue": "10" },
		{ "state": "Sikkim", "stateValue": "11" },
		{ "state": "Arunachal Pradesh", "stateValue": "12" },
		{ "state": "Nagaland", "stateValue": "13" },
		{ "state": "Manipur", "stateValue": "14" },
		{ "state": "Mizoram", "stateValue": "15" },
		{ "state": "Tripura", "stateValue": "16" },
		{ "state": "Meghalaya", "stateValue": "17" },
		{ "state": "Assam", "stateValue": "18" },
		{ "state": "West Bengal", "stateValue": "19" },
		{ "state": "Jharkhand", "stateValue": "20" },
		{ "state": "Odisha", "stateValue": "21" },
		{ "state": "Chhattisgarh", "stateValue": "22" },
		{ "state": "Madhya Pradesh", "stateValue": "23" },
		{ "state": "Gujarat", "stateValue": "24" },
		{ "state": "Daman & Diu", "stateValue": "25" },
		{ "state": "Dadra & Nagar Haveli & Daman & Diu", "stateValue": "26" },
		{ "state": "Maharashtra", "stateValue": "27" },
		{ "state": "Karnataka", "stateValue": "29" },
		{ "state": "Goa", "stateValue": "30" },
		{ "state": "Lakshdweep", "stateValue": "31" },
		{ "state": "Kerala", "stateValue": "32" },
		{ "state": "Tamil Nadu", "stateValue": "33" },
		{ "state": "Puducherry", "stateValue": "34" },
		{ "state": "Andaman & Nicobar Islands", "stateValue": "35" },
		{ "state": "Telangana", "stateValue": "36" },
		{ "state": "Andhra Pradesh", "stateValue": "37" },
		{ "state": "Ladakh", "stateValue": "38" },
		{ "state": "Other Territory", "stateValue": "97" }
	  ];
	  


	const [registrationCredential, setRegistrationCredential] = useState({
		first_name: "",
		last_name: "",
		email: "",
		password: "",
		confirmPassword: "",
		contactNumber: "",
		SecurityAnswer1: "",
		SecurityAnswer2: "",
		Address1: "",
		Address2: "",
		ReferralCode: "",
		SecretQuestion1Answer: "",
		SecretQuestion2Answer: "",
		userservice: ["1", "2"],
	});
	const [pass, setPass] = useState({});
	const [isChecked, setIsChecked] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const navigate = useNavigate();
	const dispatch = useDispatch()
	const secretQuestions1 = [
		"What is your birth date?",
		"What is your birth place?",
		"What is your birth state capital?",
		"What is your favourite color?",
		"What is your favourite destination place?",
		"What is your favourite food?",
		"What is your favourite movie?",
		"What is your favourite sport?",
		"What is your first car brand?",
		"What is your first car color?",
		"What is your first mobile brand?",
	];

	const secretQuestions2 = [
		"What is your first pet name?",
		"What is your first school name?",
		"What is your graduation percentage?",
		"What is your spouse birth date?",
		"What is your spouse birth place?",
		"Where did you go on your first international trip?",
		"Where did you study 6th grade?",
		"Who is your favourite actor?",
		"Who is your favourite sports person?",
	];

	const onChangeText = (val, key_name) => {
		setRegistrationCredential((previous) => ({
			...previous,
			[key_name]: val,
		}));

		if (key_name === "password" || key_name === "cpassword") {
			var encryptedPassword = CryptoJS.SHA1(val);
			setPass((previous) => ({
				...previous,
				[key_name]: encryptedPassword,
			}));
		}
	};

	const toggleUserService = (item) => {
		setRegistrationCredential((previous) => {
			const { userservice } = previous;
			if (
				userservice.includes(item) &&
				registrationCredential?.userservice?.length > 1
			) {
				return {
					...previous,
					userservice: userservice.filter((service) => service !== item),
				};
			} else if (!registrationCredential?.userservice?.includes(item)) {
				return {
					...previous,
					userservice: [...userservice, item],
				};
			} else {
				toast.error("At least one service should be active.");
				return {
					...previous,
					userservice: [...userservice],
				};
			}
		});
	};

	const handleCheckboxChange = () => {
		setIsChecked(!isChecked);
	};

	const handleLogin = async () => {
		const {
			first_name,
			last_name,
			email,
			password,
			cpassword,
			contactNumber,
			SecurityAnswer1,
			SecurityAnswer2,
			Address1,
			Address2,
			SecretQuestion1Answer,
			SecretQuestion2Answer,
			ReferralCode,
			statecode,
			pincode,
			gst
		} = registrationCredential;

		if (password === cpassword) {
			if (
				first_name &&
				last_name &&
				email &&
				password &&
				cpassword &&
				contactNumber &&
				SecretQuestion1Answer &&
				SecurityAnswer1 &&
				SecretQuestion2Answer &&
				SecurityAnswer2 &&
				Address1 &&
				Address2 &&
				isChecked &&
				statecode &&
				pincode
			) {
				const formData = new FormData();
				formData.append("password", pass?.password);
				const passwordHash = formData.get("password");
				const data = {
					first_name,
					last_name,
					email,
					password: passwordHash,
					contactNumber,
					question_1: SecretQuestion1Answer,
					answer_1: SecurityAnswer1,
					question_2: SecretQuestion2Answer,
					answer_2: SecurityAnswer2,
					address_1: Address1,
					address_2: Address2,
					ref_code: ReferralCode,
					statecode:statecode,
					pincode:pincode,
					gst:gst
				};
				const emailRegExp =
					/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				const uppercaseRegExp = /(?=.*?[A-Z])/;
				const lowercaseRegExp = /(?=.*?[a-z])/;
				const digitsRegExp = /(?=.*?[0-9])/;
				const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
				const minLengthRegExp = /.{8,}/;
				const mobval = /.{10,}/;
				const pincodeRegex = /^(?=.*?[0-9])\d{1,6}$/;

				const uppercasePassword = uppercaseRegExp.test(password);
				const lowercasePassword = lowercaseRegExp.test(password);
				const digitsPassword = digitsRegExp.test(password);
				const specialCharPassword = specialCharRegExp.test(password);
				const minLengthPassword = minLengthRegExp.test(password);
				const mobvalidate = mobval.test(contactNumber);
				const emailValidate = emailRegExp.test(email);
				const pincodeValidation = pincodeRegex.test(pincode)
				// if(!emailValidate){
				// 	return toast.error("Email is not in valid format");
				// }
				if (
					uppercasePassword &&
					lowercasePassword &&
					digitsPassword &&
					specialCharPassword &&
					minLengthPassword &&
					mobvalidate &&
					emailValidate && 
					pincodeValidation
				) {
					try {
						setLoading(true);
						const response = await register(JSON.stringify(data));
						setLoading(false);
						if (response?.response === "ok") {
							dispatch(setModalData({
								type: 'Agreement', isVisible: true, data: {
									data: response?.data,
									userData: { first_name, email, contactNumber },
								}
							}))
							// navigate("/agreement", {
							// 	state: {
							// 		data: response?.data,
							// 		userData: { first_name, email, contactNumber },
							// 	},
							// });
						} else {
							toast.error(response.response);
						}
					} catch (error) {
						setLoading(false);
						toast.error("An error occurred: " + error.message);
					}
				} else {
					toast.error("Please validate the input fields");
				}
			} else {
				toast.error("All fields are mandatory");
			}
		} else {
			toast.error("Confirm password didn't match");
		}
	};
	const isMobile = useMediaQuery({
		query: "(max-width: 820px)",
	});

	return (
		<div className={styles.modalAnimation} style={{ position: 'absolute', maxWidth: isMobile ? '340px' : '500px', height: '500px' }}>
			<span onClick={() => dispatch(setModalData({ type: '', isVisible: false }))} style={{ position: 'absolute', top: -14, right: '10px', cursor: 'pointer', fontWeight: '900', right: -8, background: '#fff', padding: 4, height: 20, width: 20, textAlign: 'center', borderRadius: 100 }}>x</span>
			<div style={{ height: 'auto', width: '100%', height: '100%', overflow: 'scroll' }}>
				<div style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '20px',
					marginBottom: 20,
				}}>
					<img src={require("../../assets/images/logo.png")} style={{ width: 60, alignSelf: "center", marginBottom: 0 }} />
					<span style={{ textAlign: 'center' }}>{locale?.RegisterNewAccount}</span>
				</div>


				<div
					style={{
						display: "flex",
						flexDirection: "column",
						width: "100%",
						margin: "auto",
					}}
				>
					<Input
						icon={username}
						placeholder={locale?.FirstName}
						value={registrationCredential.first_name}
						key_name="first_name"
						onChangeText={onChangeText}
					/>
					<Input
						icon={username}
						value={registrationCredential.last_name}
						placeholder={locale?.LastName}
						key_name="last_name"
						onChangeText={onChangeText}
					/>
					<Input
						icon={email}
						value={registrationCredential.email}
						placeholder={locale?.EnterEmail}
						key_name="email"
						onChangeText={onChangeText}
					/>
					<Input
						type={"password"}
						value={registrationCredential.password}
						placeholder={locale?.EnterPassword}
						key_name="password"
						onChangeText={onChangeText}
					/>
					<p className="password-content" style={{ marginLeft: isMobile ? '20px' : '50px' }}>{locale?.PassDesc}</p>

					<Input
						type={"password"}
						placeholder={locale?.ConfirmPassword}
						value={registrationCredential.cpassword}
						key_name="cpassword"
						onChangeText={onChangeText}
					/>
					<Input
						icon={phone}
						placeholder={locale?.ContactNumber}
						value={registrationCredential.contactNumber}
						key_name="contactNumber"
						onChangeText={onChangeText}
					/>

					<div
						onClick={() => {
							setRegistrationCredential((previous) => ({
								...previous,
								["SecretQuestion1"]: !registrationCredential?.SecretQuestion1,
								["SecretQuestion2"]: false,
							}));
						}}
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							padding: 10,
							justifyContent: "space-between",
							width: "82%",
							backgroundColor: "rgba(20,97,156, 0.9)",
							borderRadius: 6,
							color: "#fff",
							cursor: "pointer",
							marginBottom: 10,
							margin: "auto",
						}}
					>
						<span>
							{registrationCredential?.SecretQuestion1Answer
								? registrationCredential?.SecretQuestion1Answer
								: locale?.SecretQuestion + " 1"}
						</span>
						<i class="fa fa-angle-down" aria-hidden="true"></i>
					</div>
					<div
						className={`secretQuestion ${registrationCredential?.SecretQuestion1 ? "" : "hidden"
							}`}
						style={{ margin: "auto", marginBottom: 10 }}
					>
						{secretQuestions1.map((question, index) => (
							<p
								onClick={() => {
									setRegistrationCredential((previous) => ({
										...previous,
										["SecretQuestion1Answer"]: question,
										["SecretQuestion1"]: false,
									}));
								}}
								key={index}
								style={{
									margin: 4,
									backgroundColor: "rgba(0,0,0,0.04)",
									padding: 8,
									borderRadius: 6,
									cursor: "pointer",
									color: "rgba(0,0,0,0.7)",
								}}
							>
								{question}
							</p>
						))}
					</div>
					{registrationCredential?.SecretQuestion1Answer && (
						<div style={{ marginBottom: 10, width: "100%" }}>
							<Input
								icon={username}
								value={registrationCredential?.SecurityAnswer1}
								placeholder={locale?.EnterAnswer}
								onChangeText={(val, key_name) => {
									setRegistrationCredential((previous) => ({
										...previous,
										[key_name]: val,
									}));
								}}
								key_name={"SecurityAnswer1"}
							/>
						</div>
					)}

					<div
						onClick={() => {
							setRegistrationCredential((previous) => ({
								...previous,
								["SecretQuestion2"]: !registrationCredential?.SecretQuestion2,
								["SecretQuestion1"]: false,
							}));
						}}
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							padding: 10,
							justifyContent: "space-between",
							width: "82%",
							backgroundColor: "rgba(20,97,156, 0.9)",
							borderRadius: 6,
							color: "#fff",
							cursor: "pointer",
							marginBottom: 10,
							margin: "auto",
						}}
					>
						<span>
							{registrationCredential?.SecretQuestion2Answer
								? registrationCredential?.SecretQuestion2Answer
								: locale?.SecretQuestion + " 2"}
						</span>
						<i class="fa fa-angle-down" aria-hidden="true"></i>
					</div>
					<div
						className={`secretQuestion ${registrationCredential?.SecretQuestion2 ? "" : "hidden"
							}`}
						style={{ margin: "auto", marginBottom: 10 }}
					>
						{secretQuestions2.map((question, index) => (
							<p
								onClick={() => {
									setRegistrationCredential((previous) => ({
										...previous,
										["SecretQuestion2Answer"]: question,
										["SecretQuestion2"]: false,
									}));
								}}
								key={index}
								style={{
									margin: 4,
									backgroundColor: "rgba(0,0,0,0.04)",
									padding: 8,
									borderRadius: 6,
									cursor: "pointer",
									color: "rgba(0,0,0,0.7)",
								}}
							>
								{question}
							</p>
						))}
					</div>
					{registrationCredential?.SecretQuestion2Answer && (
						<div style={{ marginBottom: 10, width: "100%" }}>
							<Input
								icon={username}
								value={registrationCredential?.SecurityAnswer2}
								placeholder={locale?.EnterAnswer}
								onChangeText={(val, key_name) => {
									setRegistrationCredential((previous) => ({
										...previous,
										[key_name]: val,
									}));
								}}
								key_name={"SecurityAnswer2"}
							/>
						</div>
					)}


					<Input
						icon={location}
						placeholder={locale?.Address + " 1"}
						value={registrationCredential.Address1}
						key_name="Address1"
						onChangeText={onChangeText}
					/>
					<Input
						icon={location}
						placeholder={locale?.Address + " 2"}
						value={registrationCredential.Address2}
						key_name="Address2"
						onChangeText={onChangeText}
					/>
					<div
						onClick={() => {
							setRegistrationCredential((previous) => ({
								...previous,
								["stateData"]: !registrationCredential?.stateData,
								["SecretQuestion1"]: false,
								["SecretQuestion2"]: false,
							}));
						}}
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							padding: 10,
							justifyContent: "space-between",
							width: "82%",
							backgroundColor: "rgba(20,97,156, 0.9)",
							borderRadius: 6,
							color: "#fff",
							cursor: "pointer",
							marginBottom: 10,
							margin: "auto",
						}}
					>
						<span>
							{registrationCredential?.stateDataAnswer ?? locale?.selectState} {registrationCredential?.stateDataAnswer && <i class="fa fa-check-circle" aria-hidden="true"></i>}
						</span>
						<i class="fa fa-angle-down" aria-hidden="true"></i>
					</div>

					<div
						className={`secretQuestion ${registrationCredential?.stateData ? "" : "hidden"
							}`}
						style={{ margin: "auto", marginBottom: 10 }}
					>
						{stateData.map((item, index) => (
							<p
								onClick={() => {
									setRegistrationCredential((previous) => ({
										...previous,
										["stateDataAnswer"]: item?.state,
										["statecode"]: item?.stateValue,
										["stateData"]: false,
									}));
								}}
								key={index}
								style={{
									margin: 4,
									backgroundColor: "rgba(0,0,0,0.04)",
									padding: 8,
									borderRadius: 6,
									cursor: "pointer",
									color: "rgba(0,0,0,0.7)",
								}}
							>
								{item?.state}
							</p>
						))}
					</div>
					<Input
						icon={location}
						placeholder={locale?.pincode}
						value={registrationCredential?.pinCode}
						key_name="pincode"
						onChangeText={onChangeText}
					/>
					<Input
						icon={hash}
						placeholder={locale?.gst}
						value={registrationCredential?.GST}
						key_name="gst"
						onChangeText={onChangeText}
					/>
					{/* <Input
						icon={username}
						placeholder={"Enter Referral Code"}
						value={registrationCredential.ReferralCode}
						key_name="ReferralCode"
						onChangeText={onChangeText}
						maxLength={6}
					/> */}
				</div>

				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						marginTop: 10,
						width: "90%",
						margin: "auto",
					}}
				>
					<input
						type="checkbox"
						checked={isChecked}
						onChange={handleCheckboxChange}
						style={{ marginRight: 5 }}
					/>
					{locale?.languageType==="English"?<p className="policy-content">
						By checking this box, you confirm that you have read, understood, and agree to be bound by our <a style={{ color: COLORS.blue }} onClick={() => {
							dispatch(setModalData({ type: '', isVisible: false }));
							navigate("/dashboardTermsandConditions")
						}}>User Agreement</a>, <a style={{ color: COLORS.blue }} onClick={() => {
							dispatch(setModalData({ type: '', isVisible: false }));
							navigate("/disclaimer-policy")
						}}>Disclaimer</a>, <a style={{ color: COLORS.blue }} onClick={() => {
							dispatch(setModalData({ type: '', isVisible: false }));
							navigate("/privacy-policy")
						}}>Privacy Policy</a>, and other applicable terms and conditions.
					</p>:
					<p>
					इस बॉक्स को चेक करके, आप पुष्टि करते हैं कि आपने हमारे <a style={{ color: COLORS.blue }} onClick={() => {
							dispatch(setModalData({ type: '', isVisible: false }));
							navigate("/dashboardTermsandConditions")
						}}>उपयोगकर्ता समझौते</a>, <a style={{ color: COLORS.blue }} onClick={() => {
							dispatch(setModalData({ type: '', isVisible: false }));
							navigate("/disclaimer-policy")
						}}>अस्वीकरण</a>, <a style={{ color: COLORS.blue }} onClick={() => {
							dispatch(setModalData({ type: '', isVisible: false }));
							navigate("/privacy-policy")
						}}>गोपनीयता नीति</a> और अन्य लागू नियमों और शर्तों को पढ़ा, समझा और उनसे बंधे रहने के लिए सहमति दी है।
					</p>}
				</div>

				<div style={{ display: "flex", justifyContent: "center" }}>
					<Button onClick={handleLogin} name={locale?.SignAgreement} />
				</div>
			</div>

		</div>
	);
};

export default withBlackBackground(Registration);
