import React, { useState } from 'react'
import withBlackBackground from '../withBlackBackground'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import username from "../../../assets/images/username.png";
import passwordIcon from "../../../assets/images/password.png";
import Input from '../../../../components/Input';
import CryptoJS from "crypto-js";
import toast from 'react-hot-toast';
import styles from "../styles/HeroSection.module.css";
import logo from "../../../assets/images/logo.png";
import modalStyle from '../modalStyle.module.css'
import { setModalData } from '../../../../redux/Actions/UserAction';
import { registerConsultant } from '../../../API';

const ConsultantRegister = () => {
    const { locale } = useSelector((state) => state.user);
	const dispatch = useDispatch();
	const [loginCredential, setLoginCredential] = useState({});
	const [errorMessages, setErrorMessages] = useState({});

    const onChangeText = (val, key_name) => {
		setLoginCredential((previous) => ({
			...previous,
			[key_name]: val,
		}));
		setErrorMessages((prev) => ({ ...prev, [key_name]: '' }));
	};

    const isValidName = (name) => {
        // Name must be at least 3 characters long and consist of letters only
        const nameRegex = /^[A-Za-z]{3,}$/;
        return nameRegex.test(name);
    };

    const isValidPassword = (password) => {
        // Password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and be at least 8 characters long
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return passwordRegex.test(password);
    };

    const isValidEmail = (email) => {
        // Basic email validation regex
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const isValidPhoneNumber = (phone) => {
        // Indian phone number validation (must start with 6, 7, 8, or 9 and be 10 digits long)
        const phoneRegex = /^[6789]\d{9}$/; // Adjust regex for Indian phone numbers
        return phoneRegex.test(phone);
    };

    const handleSubmit = async () => {
		let errors = {};
		if (!loginCredential.first_name) {
			errors.first_name = "First Name is required.";
		} else if (!isValidName(loginCredential.first_name)) {
			errors.first_name = "First Name must be at least 3 characters long and contain only letters.";
		}
		if (!loginCredential.last_name) {
			errors.last_name = "Last Name is required.";
		} else if (!isValidName(loginCredential.last_name)) {
			errors.last_name = "Last Name must be at least 3 characters long and contain only letters.";
		}
		if (!loginCredential.email) {
			errors.email = "Email is required.";
		} else if (!isValidEmail(loginCredential.email)) {
			errors.email = "Please enter a valid email address.";
		}
		if (!loginCredential.mobile) {
			errors.mobile = "Phone Number is required.";
		} else if (!isValidPhoneNumber(loginCredential.mobile)) {
			errors.mobile = "Please enter a valid Indian phone number (must start with 6, 7, 8, or 9 and be 10 digits long).";
		}
		if (!loginCredential.password) {
			errors.password = "Password is required.";
		} else if (!isValidPassword(loginCredential.password)) {
			errors.password = "Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one number, and one special character.";
		}

		if (Object.keys(errors).length > 0) {
			setErrorMessages(errors);
			return;
		}

		let loader = toast.loading("Loading...");
		var formdata = new FormData();
		formdata.append("first_name", loginCredential.first_name);
		formdata.append("last_name", loginCredential.last_name);
		formdata.append("email", loginCredential.email);
		formdata.append("password", loginCredential.password);
		formdata.append("mobile", loginCredential.mobile);

		try {
			registerConsultant(formdata).then(async (response) => {
				if (response) {
					toast.success("Register successfully!");
					toast.remove(loader);
				} else {
					toast.remove(loader);
					toast.error(response?.non_field_errors[0] || "Please enter correct credential!")
				}
			});
		} catch (error) {
			toast.remove(loader);
			toast.error((error?.non_field_errors[0]||"Something went wrong!"), {
                duration: 2000,
            });
			console.log("error", error);
		}
	};

    return (
        <div className={modalStyle.modalAnimation} style={{height:'auto',position:'absolute', maxWidth:'340px',}}>
            <div className={styles.loginForm}>
                <span onClick={()=>dispatch(setModalData({type:'', isVisible:false}))} style={{position:'absolute', top:-14, cursor:'pointer', fontWeight:'900', right:-8, background:'#fff', padding:4, height:20, width:20, textAlign:'center', borderRadius:100}}>x</span>
                <img src={logo} style={{ width: 60, alignSelf: "center", marginBottom:0 }} />
                <span style={{textAlign:'center'}}>Register as a consultant</span>
                <div className={styles.formGroup}>
                    <Input
                        icon={username}
                        placeholder={"First Name"}
                        onChangeText={onChangeText}
                        key_name="first_name"
                        value={loginCredential?.first_name}
                    />
                    {errorMessages.first_name && <span style={{ color: 'red', fontSize:12, marginLeft:20, marginBottom:10, marginTop:-6  }}>{errorMessages.first_name}</span>}

                    <Input
                        icon={username}
                        placeholder={"Last Name"}
                        onChangeText={onChangeText}
                        key_name="last_name"
                        value={loginCredential?.last_name}
                    />
                    {errorMessages.last_name && <span style={{ color: 'red', fontSize:12, marginLeft:20, marginBottom:10, marginTop:-6  }}>{errorMessages.last_name}</span>}

                    <Input
                        icon={username}
                        placeholder={locale?.EnterEmail || "Enter Email"}
                        onChangeText={onChangeText}
                        key_name="email"
                        value={loginCredential?.email}
                    />
                    {errorMessages.email && <span style={{ color: 'red', fontSize:12, marginLeft:20, marginBottom:10, marginTop:-6  }}>{errorMessages.email}</span>}

                    <Input
                        icon={username}
                        placeholder={"Phone Number"}
                        onChangeText={onChangeText}
                        key_name="mobile"
                        value={loginCredential?.mobile}
                    />
                    {errorMessages.mobile && <span style={{ color: 'red', fontSize:12, marginLeft:20, marginBottom:10, marginTop:-6  }}>{errorMessages.mobile}</span>}

                    <Input
                        icon={passwordIcon}
                        placeholder={locale?.EnterPassword || "Enter Password"}
                        onChangeText={onChangeText}
                        key_name="password"
                        value={loginCredential?.password}
                        type={"password"}
                    />
                    {errorMessages.password && <span style={{ color: 'red', fontSize:12, marginLeft:20, marginBottom:10, marginTop:-6  }}>{errorMessages.password}</span>}
                </div>
                <button
                    onClick={() => handleSubmit()}
                    className={styles.submitButton}
                >
                    {locale.Create}
                </button>
            </div>
        </div>
    )
}

export default withBlackBackground(ConsultantRegister)