import toast from "react-hot-toast";

export const toasty = (message, type) => {
    if (type === "error") {
        toast.error(message, {
            style: {
                border: '1px solid #e53e3e',
                padding: '16px',
                color: '#e53e3e',
                backgroundColor: '#fff5f5',
            },
            iconTheme: {
                primary: '#e53e3e',
                secondary: '#fff5f5',
            },
        });
    } else if (type === "warning") {
        toast(message, {
            style: {
                border: '1px solid #d69e2e',
                padding: '16px',
                color: '#d69e2e',
                backgroundColor: '#fff8e1',
            },
            iconTheme: {
                primary: '#d69e2e',
                secondary: '#fff8e1',
            },
        });
    } else {
        toast.success(message, {
            style: {
                border: '1px solid #2c5da7',
                padding: '16px',
                color: '#2c5da7',
            },
            iconTheme: {
                primary: '#2c5da7',
                secondary: '#FFFAEE',
            },
            duration:5000
        });
    }
};