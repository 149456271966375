import React from 'react'
import COLORS from '../../../../../utility/colors'

function PageHeader({string="Heading"}) {
  return (
    <div style={{
        width:"100%",
        backgroundColor:COLORS.orangeShade,
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        padding:10,
        // margin:0
    }}>
      <span style={{
        fontSize:24,
        fontWeight:"bold",
        color:COLORS.white

      }}>{string}</span>
    </div>
  )
}

export default PageHeader
