export default {
	Login: "लॉग इन करें",
	ForgotPassword: "पासवर्ड भूल गए?",
	EnterPassword: "पास वर्ड दर्ज करें",
	EnterEmail: "ईमेल दर्ज करें",
	DontHaveAccount: "क्या आपके पास खाता नहीं है?",
	Create: "खाता बनाएं",
	FirstName: "प्रथम नाम दर्ज करें",
	LastName: "अंतिम नाम दर्ज करें",
	PassDesc: `1 अपर केस कैरेक्टर, 1 लोअर केस कैरेक्टर, 1 अंक और अंडरस्कोर के अलावा 1 विशेष कैरेक्टर, इसमें कम से कम 8 कैरेक्टर और अधिकतम 32 कैरेक्टर होने चाहिए।`,
	ConfirmPassword: "पुष्टि पासवर्ड दर्ज करें",
	ContactNumber: "संपर्क नंबर दर्ज करें",
	SecretQuestion: "गोपनीय प्रश्न",
	EnterAnswer: "उत्तर दर्ज करें",
	Address: "पता",
	ByClick: "साइन अप पर क्लिक करके, आप हमारी शर्तों से सहमत होते हैं",
	term: "शर्त",
	AndRead: "और आपने हमारा लेख पढ़ लिया है",
	DataPolicy: "डेटा नीति और कुकी उपयोग",
	CreateAccount: "खाता बनाएं",
	Agreement: "समझौता पत्र",
	Accept: "स्वीकार करना",
	CertifiEmailSent: "प्रमाणिक ईमेल भेजा गया",
	EmailNotDelivered: "ईमेल डिलीवर नहीं हुआ",
	DataNotFound: "डाटा प्राप्त नहीं हुआ!",
	Subject: "विषय दर्ज करें",
	SMSDelivery: "SMS डिलीवरी",
	EmailReadTime: "Email पढ़ने का समय",
	Home: "होम्‌",
	Payment: "पेमेंट",
	Profile: "प्रोफाइल",
	Setting: "सेटिंग",
	Email: "ईमेल",
	Settings: "सेटिंग",
	SrNo: "क्रमांक।",
	CertifiEmailCompose: "प्रमाणित ईमेल लिखें",
	EnterDescription: "विवरण दर्ज करें",
	Send: "भेजें",
	AttachFile: "दस्तावेज संलग्न करें",
	Save: "सेव",
	BasicInfo:
		"बुनियादी जानकारी, जैसे आपका नाम और ईमेल आईडी, जो आपने खाता बनाते समय दर्ज की थी",
	Cancel: "रद्द करना",
	DraftEmail: "ड्राफ्ट ईमेल",
	AggrementSignature: "समझौते पर हस्ताक्षर",
	SignAgreement: "समझौते पर हस्ताक्षर करें",
	Decline: "अस्वीकार करना",
	WELCOME: "आपका स्वागत है",
	RegisterNewAccount: "नये खाते को पंजीकृत करें",
	SubmitOTP: "ओटीपी सबमिट करें",
	CertifiedDesc: "प्रमाणित मेल देखने के लिए कृपया OTP दर्ज करें",
	About: "CERTIFI के विषय पर",
	Services: "सेवाएं",
	PrivacyPolicy: "गोपनीयता नीति",
	Terms_Conditions: "नियम एवं शर्तें",
	HeroTitle: "प्रमाणित संचार सेवा या CERTIFI",
	List0: "◉ प्रौद्योगिकी के माध्यम से विश्वास को सशक्त बनाना",
	List1: "◉ भारत सरकार द्वारा पेटेंट कराया गया",
	List2: "◉ डिलीवरी और प्रमाणित सामग्री का प्रामाणिक प्रमाण",
	List3: "◉ मेड इन इंडिया",
	AboutCertify: "CERTIFI के बारे में",
	AboutCertifyContent:
		"आज की तेज़ गति वाली डिजिटल दुनिया में व्यवसायों और व्यक्तियों के लिए प्रभावी संचार महत्वपूर्ण है। इलेक्ट्रॉनिक संचार चैनलों पर बढ़ती निर्भरता के साथ, संदेशों की विश्वसनीयता और सुरक्षा सुनिश्चित करना महत्वपूर्ण है। यहीं पर ईमेल और एसएमएस के माध्यम से सर्टिफ़िकेशन चलन में आता है। Certifi Certifi के ब्रांड नाम के तहत प्रदान किया जाता है",
	ReadMore: "और पढ़ें",
	Certified_SMS_Heading: `प्रमाणित संवाद क्यों महत्वपूर्ण है`,
	Certified_SMS_Content1:
		"विश्वसनीय और सुरक्षित सुनिश्चित करने के लिए Certifi एक अनिवार्य उपकरण है संदेश भेजना। व्यवसाय और व्यक्ति इन सेवाओं का उपयोग कर सकते हैं संचार को विश्वसनीय रूप से बढ़ाएं, संदेश वितरण क्षमता में वृद्धि करें, और अपने दर्शकों के साथ विश्वास मजबूत करें। यह सावधानी से करना महत्वपूर्ण है मूल्यांकन करें और एक प्रतिष्ठित मंच चुनें जो विशिष्ट के साथ संरेखित हो आवश्यकताएँ और अपेक्षाएँ।",
	Certified_SMS_Card1_Heading: "एसएमएस डिजिटली हस्ताक्षरित प्रमाणपत्र",
	Certified_SMS_Card1_Content1:
		"प्रमाणित संदेशों में स्पैम फ़िल्टर को बायपास करने और प्राप्तकर्ता के इनबॉक्स तक पहुंचने की अधिक संभावना होती है।",
	Certified_SMS_Card2_Heading: "डिजिटल हस्ताक्षरित प्रमाणपत्र ईमेल करें",
	Certified_SMS_Card2_Content1:
		"फ़िशिंग हमलों और ईमेल स्पूफिंग के जोखिम को कम करने में प्रमाणन।",

	Banks_and_Insurance: "बैंक और बीमा कंपनियां",
	Banks_and_Insurance_Content: `बिल वितरण, बकाया भुगतान अनुस्मारक, प्रीमियम अनुस्मारक, प्रीमियम रसीदें, बीमा संबंधित भुगतान`,
	Courts: "अदालतें और अन्य नियामक निकाय",
	Courts_Content: `कानूनी नोटिस, आदेश, और अन्य केस से संबंधित जानकारी भेजने के लिए`,
	Government_Departments: "सरकारी विभाग जैसे कर विभाग, पुलिस, नगरपालिकाएं",
	Government_Departments_Content: `नागरिकों को महत्वपूर्ण सूचनाएं भेजने के लिए जैसे कर नोटिस, ट्रैफिक चालान, एफआईआर प्रतियां, नगर निगम कर, शुल्क संबंधित संचार`,
	General_Public: "सामान्य जनता",
	General_Public_Content:
		"महत्वपूर्ण दस्तावेज भेजने के लिए जैसे अनुबंध, वसीयत, निविदा दस्तावेज, केवाईसी अनुपालन दस्तावेज आदि।",
	Educational_Institutions: "शैक्षणिक संस्थान",
	Educational_Institutions_Content:
		"परीक्षा अधिसूचनाएं, रोल नंबर, परिणाम, डिग्री, केवाईसी के लिए।",
	Enterprises: "उद्यम/ कंपनियां",
	Enterprises_Content:
		"आंतरिक और बाहरी पक्षों के साथ विभिन्न आधिकारिक और महत्वपूर्ण संचार - एचआर संबंधित, आंतरिक संचार, विक्रेता संबंधित, बिक्री और ग्राहक संबंधित।",

	Footer_Logo_Content:
		"ईमेल और एसएमएस के माध्यम से प्रमाणित संचार के लिए एक पेटेंट, सुरक्षित और भारत में निर्मित समाधान, डिजिटल इंडिया के माध्यम से जनता को सशक्त बनाना।",
	Company: "कंपनी",
	Address: "गुरूग्राम, हरियाणा, भारत।",
	QuickLinks: "त्वरित सम्पक",
	ContactUs: "हमसे संपर्क करें",
	UseCases: "उपयोग उदाहरण",
	AboutUS: "हमारे बारे में",
	AboutUsContent: `Synerthink जुड़े हुए विश्व के लिए विश्वसनीय डेटा समाधानों की पेशकश करता है। इसे वैश्विक टेलीकॉम पारिस्थितिकी तंत्र में विशाल अनुभव वाले उद्यमियों द्वारा सह-स्थापित किया गया था, संचार कंपनी के DNA में है। CERTIFI, Synerthink का एक उत्पाद, एक प्रामाणिक और विश्वसनीय डिजिटल इलेक्ट्रॉनिक संचार मंच है। डिजिटल इंडिया के एक नए पहलकदमी के रूप में, CERTIFI सुरक्षित, प्रभावी और पारिस्थितिकीय संचार के लिए एक बेंचमार्क स्थापित करता है। यह समय स्टैम्पिंग, सामग्री प्रमाणन, और संदेश एन्क्रिप्शन जैसी सुविधाओं के माध्यम से गोपनीयता और प्रामाणिकता सुनिश्चित करता है, जो आधुनिक और पारंपरिक उपयोगकर्ताओं दोनों की आवश्यकताओं को पूरा करता है जबकि भारत के इलेक्ट्रॉनिक संचार के प्रमाण के लिए प्रासंगिक कानूनों के प्रावधानों के साथ समन्वय करता है।`,
	Vision: "दृष्टि",
	VisionContent: `हमारी दृष्टि विश्व स्तर पर प्रमाणित संचार सेवाओं के प्रमुख प्रदाता बनने की है, जो सेवा और उत्पाद उत्कृष्टता, नवाचार, और ग्राहक संतोष के प्रति अपनी प्रतिबद्धता के लिए पहचाने जाते हैं। हम लगातार अपने ग्राहकों की बदलती आवश्यकताओं को पूरा करने के लिए अनुकूलित करने का लक्ष्य रखते हैं।`,
	Mission: "मिशन",
	MissionContent:
		"हमारा मिशन व्यवसायों और व्यक्तियों को हमारी प्रमाणित संचार सेवा के माध्यम से विश्वसनीय और सुरक्षित संचार समाधान प्रदान करना है। हम उनकी प्रभावशीलता को बढ़ाने का प्रयास करते हैं।",
	Services: "सेवाएँ",
	WhatMakesUsDifferent: "हमें अलग क्या बनाता है?",
	CoreCommitment:
		"हमारे मूल में, जो हमें अलग करता है वह हमारे ग्राहकों के संचार की विश्वसनीयता और सुरक्षा के प्रति हमारी अटूट प्रतिबद्धता है। हम समझते हैं कि आज की डिजिटल दुनिया में प्रभावी संचार की महत्वपूर्णता है, और हम यह सुनिश्चित करने के लिए हर संभव प्रयास करते हैं कि हर संदेश सुरक्षित और सुरक्षित रूप से पहुँचाया जाए।",
	CommitmentToInnovation:
		"नवाचार के प्रति हमारी प्रतिबद्धता हमें उभरती हुई खतरों और चुनौतियों से आगे रहने में सक्षम बनाती है, हमारे ग्राहकों को मन की शांति प्रदान करती है, यह जानते हुए कि उनकी संचार रणनीतियाँ सक्षम हाथों में हैं।",
	CustomerCentricApproach:
		"इसके अतिरिक्त, हमारी ग्राहक-केंद्रित दृष्टिकोण का मतलब है कि हम प्रत्येक ग्राहक की अद्वितीय आवश्यकताओं और आवश्यकताओं को पूरा करने के लिए अपनी सेवाओं को अनुकूलित करते हैं, व्यक्तिगत समाधानों और हर कदम पर बेजोड़ समर्थन प्रदान करते हैं।",
	Focus:
		"विश्वसनीयता, सुरक्षा, नवाचार, और ग्राहक संतोष पर ध्यान केंद्रित करने के साथ, हमें गर्व है कि हम व्यवसायों और व्यक्तियों के लिए विश्वसनीय साझेदार हैं जो SMS और ईमेल प्रमाणन सेवाओं के साथ अपनी संचार रणनीतियों को सुधारने के लिए खोज कर रहे हैं।",
	KeyFeatures: "मुख्य विशेषताएँ",
	MessageAuthentication: "संदेश प्रमाणीकरण",
	MessageAuthentication_Content:
		"CERTIFI मजबूत तंत्र प्रदान करता है जो संदेश को प्रमाणीकरण करने और यह सुनिश्चित करता है कि संदेश यात्रा के दौरान असंपादित रहें। इन सुविधाओं का उपयोग करके, उपयोगकर्ता विश्वास स्थापित कर सकते हैं और संवेदनशील जानकारी तक अनधिकृत पहुँच को रोक सकते हैं, यह सुनिश्चित करते हुए कि संचार सुरक्षित और विश्वसनीय हो।",
	IncreasedDeliverability: "प्रेषण में वृद्धि",
	IncreasedDeliverability_Content:
		"CERTIFI SMS और ईमेल संदेशों की प्रेषण क्षमता को अनुकूलित करके बढ़ाता है। यह सुनिश्चित करता है कि आपके संदेश विश्वसनीय रूप से इच्छित प्राप्तकर्ता के ईमेल पते पर पहुँचें, प्रभावी संचार बनाए रखते हुए।",
	SecurityMeasures: "सुरक्षा उपाय",
	SecurityMeasures_Content:
		"CERTIFI आपके संदेशों और संवेदनशील डेटा की सुरक्षा के लिए एक श्रृंखला के सुरक्षा उपाय लागू करता है। इसमें संदेशों को यात्रा के दौरान और संग्रहण में एन्क्रिप्ट करना, उपयोगकर्ता डेटा को सुरक्षित रूप से संग्रहीत करना, और उद्योग मानक सुरक्षा प्रोटोकॉल का पालन करना शामिल है। ये प्रथाएँ डेटा उल्लंघनों और अनधिकृत पहुँच के खिलाफ मजबूत सुरक्षा सुनिश्चित करती हैं, आपके संचार की गोपनीयता और अखंडता बनाए रखते हुए।",
	ScalabilityAndReliability: "स्केलेबिलिटी और विश्वसनीयता",
	ScalabilityAndReliability_Content:
		"CERTIFI की एक प्रमुख विशेषता इसकी क्षमता है कि यह बड़े पैमाने पर संदेशों को कुशलता और विश्वसनीयता से संभाल सकता है। CERTIFI उच्च उपलब्धता और स्केलेबिलिटी सुनिश्चित करने के लिए मजबूत अवसंरचना और अतिरिक्त प्रणालियाँ प्रदान करता है, यहां तक कि चरम उपयोग के समय में भी। यह सुनिश्चित करता है कि आपके संचार बिना किसी रुकावट के निरंतरता में बने रहें, चाहे संदेशों की मात्रा कितनी भी हो।",
	SMSCertificate: "SMS डिजिटल हस्ताक्षरित प्रमाण पत्र",
	SMSCertificate_Content:
		"प्रमाणित संदेशों को स्पैम फ़िल्टरों को बायपास करने और प्राप्तकर्ताओं के इनबॉक्स में पहुँचने के अधिक अवसर मिलते हैं।",
	EmailCertificate: "ईमेल डिजिटल हस्ताक्षरित प्रमाण पत्र",
	EmailCertificate_Content:
		"प्रमाणन फ़िशिंग हमलों और ईमेल स्पूफिंग के जोखिम को कम करने में मदद करता है।",
	CERTIFI_EMAIL: "CERTIFI ईमेल देखें",
	REGISTER_AS_A_COSULTANT: 'कंसल्टेंट के रूप में पंजीकरण करें',
	Enter_Recipient_Email:`प्राप्तकर्ता का ईमेल पता दर्ज करें।`,
	Enter_Recipient_Contact: `प्राप्तकर्ता का संपर्क नंबर दर्ज करें।`,




heroSectionAboutContent1: 'आज की डिजिटल-प्रथम दुनिया में, जहां लिखित संवाद व्यक्तिगत और पेशेवर बातचीत की रीढ़ है, विश्वसनीयता और सुरक्षा सुनिश्चित करना पहले से कहीं अधिक महत्वपूर्ण हो गया है।',
  heroSectionAboutContent2:'का एक प्रोडक्ट है, जो सर्टिफाइड ईमेल और सर्टिफाइड एसएमएस के तालमेल को दर्शाता है, हर लिखित संवाद में विश्वास और आश्वासन प्रदान करता है। ब्रांड नाम "CERTIFI" आपके संवाद को प्रमाणित करने की हमारी प्रतिबद्धता को दर्शाता है, जबकि "Synerthink" नवाचारी सोच के सामंजस्य का प्रतीक है। साथ में, हम एक निर्बाध, स्वचालित प्लेटफ़ॉर्म प्रदान करते हैं जो डिलीवरी की गारंटी देता है, कानूनी आश्वासन प्रदान करता है, और संवाद का प्रमाण प्रमाणित करता है—वह भी जब इसकी सबसे अधिक आवश्यकता हो।',

  heroSectionCertified_SMS_Content1:'आज के समय में, जहां विश्वसनीयता, सुरक्षा और विश्वास प्रभावशाली संवाद की नींव हैं, डिलीवरी में विफलता, संवाद में छेड़छाड़ और जवाबदेही की कमी जैसे मुद्दे महत्वपूर्ण लिखित संवाद को बाधित कर सकते हैं। यहीं पर CERTIFI का महत्व सामने आता है।',
  heroSectionCertified_SMS_Content2:'CERTIFI यह सुनिश्चित करता है कि हर संवाद जो आप भेजते हैं, प्रमाणित, ट्रैसेबल और विश्वसनीय हो। भेजने वाले और प्राप्त करने वाले के विवरण, संवाद और समय की जानकारी को एक कानूनी रूप से मान्य प्रमाणपत्र में कैप्चर करके, CERTIFI संवाद में विश्वास और जवाबदेही की एक अनूठी परत प्रदान करता है।',
  heroSectionCertified_SMS_Content3:'चाहे आप महत्वपूर्ण अपडेट, कानूनी नोटिस, या आधिकारिक दस्तावेज भेज रहे हों, प्रमाणित संवाद आपको इस बात का विश्वास देता है कि आपका संदेश डिलीवर होगा, संरक्षित रहेगा और स्वीकार किया जाएगा।',


  languageType:'Hindi',
  pincode:'पिन कोड दर्ज करें',
  selectState:'राज्य चुनें',
  gst:'जीएसटी नंबर दर्ज करें',
	ReVerify:'पुनः पुष्टि',

	wouldYouLike:'क्या आप निम्नलिखित करना चाहेंगे:',
	include63BSA:'63 BSA प्रारूप में प्रमाणपत्र सम्मिलित करें?',
	addForensic:'प्रमाणपत्र में फोरेंसिक ऑडिट ट्रेल सम्मिलित करें?',
	receiveCopy:'प्रमाणित संचार की एक प्रति प्राप्त करना चाहेंगे?'
};
