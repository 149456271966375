import React from "react";
import styles from "../styles/DigitallySignedCertificate.module.css";
import certifiedsms from "../../../assets/images/certifiedsms.png";
import certifiedsms1 from "../../../assets/images/certifiedsms1.png";
import useLocale from "../../../../utility/useLocal";
function DigitallySignedCertificate() {
  const locale = useLocale();
  return (
    <div className={styles.DigitallySignedCertificate}>
      <div className={styles.innerWrapper}>
        <div className={styles.headingWrapper}>
          <span className={styles.heading}>
            {locale.Certified_SMS_Heading}
          </span>
        </div>
        <div className={styles.contentWrapper}>
          <span className={styles.content} style={{textAlign:'left'}}>
          {locale?.heroSectionCertified_SMS_Content1}<br/><br/>
          {locale?.heroSectionCertified_SMS_Content2}
            <br/><br/>{locale?.heroSectionCertified_SMS_Content3}
          </span>
        </div>
      </div>
    </div>
  );
}

export default DigitallySignedCertificate;
