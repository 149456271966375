import React, { useEffect, useMemo, useState } from "react";
import "./TableContainer.css";
import { useMediaQuery } from "react-responsive";
import {
	copyExcelData,
	excelDownload,
	formatDate,
	printExcelFile,
} from "../utility";
import download from "../app/assets/images/download.png";
import ExportExcel from "../app/assets/images/ExportExcel.png";
import copy from "../app/assets/images/copy.png";
import Print from "../app/assets/images/Print.png";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { useSelector } from "react-redux";
import { Icon } from "@mui/material";
import COLORS from "../utility/colors";

const Table = ({ data, onClick, loading, type, lable, isSuccess, isDraft }) => {
	const { settingData, locale, userInfo } = useSelector((state) => state.user);
	const tableData = data || [];
	const isMobile = useMediaQuery({
		query: "(max-width: 820px)",
	});
	const itemsPerPage = 30;
	const [currentPage, setCurrentPage] = useState(1);

	useEffect(() => {
		setCurrentPage(1); // Reset to the first page when the table data changes
	}, [tableData]);
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = tableData.slice(indexOfFirstItem, indexOfLastItem);
	const total_Pages = Math.ceil(tableData.length / itemsPerPage);
	const paginate = (pageNumber) => setCurrentPage(pageNumber);
	const nextPage = () => {
		if (total_Pages > currentPage) {
			paginate(currentPage + 1);
		}
	};
	const prevPage = () => {
		if (currentPage > 1) {
			paginate(currentPage - 1);
		}
	};
	const PaginationComponent = () => {
		return (
			<div className="pagination-wrapper">
				<div
					style={{
						display: "flex",
						alignItems: "center",
						margin: "6px",
					}}
				>
					<span style={{ marginRight: "10px", color: "#fff" }}>
						Page {currentPage} of {total_Pages}
					</span>
					<span style={{ marginRight: "10px", color: "#fff" }}>
						Showing {indexOfFirstItem + 1} to {indexOfLastItem} of{" "}
						{tableData.length} entries
					</span>
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "flex-end",
					}}
				>
					<i
						className="fa fa-arrow-circle-left fa-lg"
						onClick={() => prevPage()}
						style={{
							color: "#e98936",
							marginRight: 10,
							cursor: "pointer",
							backgroundColor: "#fff",
							padding: 6,
							borderRadius: 100,
						}}
					/>
					<i
						className="fa fa-arrow-circle-right fa-lg"
						onClick={() => nextPage()}
						style={{
							color: "#e98936",
							marginRight: 10,
							cursor: "pointer",
							backgroundColor: "#fff",
							padding: 6,
							borderRadius: 100,
						}}
					/>
				</div>
			</div>
		);
	};

	const downloadOption = () => {
		return (
			<>
				<div className="download-image-absolute">
					<img
						onClick={() => excelDownload(currentItems, lable)}
						src={ExportExcel}
						alt="download"
						className="download-image"
						title="Download"
					/>
				</div>
				<div className="download-image-absolute1">
					<img
						onClick={() => printExcelFile(currentItems, lable)}
						src={Print}
						alt="download"
						className="download-image"
						title="Print"
					/>
				</div>
				<div className="download-image-absolute2">
					<img
						onClick={() => copyExcelData(currentItems)}
						src={copy}
						alt="download"
						className="download-image"
						style={{ width: 20 }}
						title="Copy"
					/>
				</div>
			</>
		);
	};
	if (!isMobile) {
		return (
			<table className="table-wrapper" style={{ marginBottom: 30 }}>
				<tr className="row-wrapper">
					<th>{locale?.languageType==="English" ? "Recipient":"प्राप्तकर्ता"}</th>
					<th>{locale?.languageType==="English"? "Subject":"विषय"}</th>
					{!isDraft && isSuccess && <th>{locale?.languageType==="English"?"SMS Delivery":"एसएमएस डिलीवरी"}</th>}
					{!isDraft && <th>{locale?.languageType==="English"?"Email Delivery":"ईमेल वितरण"}</th>}
					{!isDraft && isSuccess && <th>{locale?.languageType==="English"?"Email Read Time":"ईमेल पढ़ने का समय"}</th>}
					{/* {isSuccess && <th>Status</th>} */}
					{!isDraft ? (
						<th style={{ textAlign: "center" }}>{locale?.languageType=="English"?"Certificate":"प्रमाणपत्र"}</th>
					) : null}
					{isDraft && <th>{locale?.languageType==="English"?"Action":"कार्रवाई"}</th>}
				</tr>
				{currentItems?.map((item, index) => {
					return (
						<tr
							key={index}
							className="table-list"
							onClick={() => onClick({ id: item?.id })}
						>
							<td>
								<div
									style={{
										display: "flex",
										alignItems: "center",
									}}
								>
									<span>{item?.recipient}</span>
								</div>
							</td>
							<td>
								{item?.subject?.length > 40
									? `${item?.subject?.slice(0, 40)}...`
									: item?.subject}
							</td>
							{!isDraft && isSuccess && (
								<td>{item?.sms_dr_time ? formatDate(item?.sms_dr_time) :'N/A'}</td>
							)}
							{!isDraft && <td>{item?.email_dr_time ? formatDate(item?.email_dr_time) : 'N/A'}</td>}
							{!isDraft && isSuccess && <td>{item?.read_time ? formatDate(item?.read_time) : 'N/A'}</td>}
							{/* {isSuccess && (
								<td
									style={{
										color: "#14619d",
									}}
								>
									{item?.sms_dr_time && item?.email_dr_time ? "✔✔" : "✔"}
								</td>
							)} */}
							{!isDraft ? (
								<td style={{ textAlign: "center" }}>
									<div onClick={() =>
											onClick({
												type: type,
												id: item.id,
											})
										}>
									<CloudDownloadIcon style={{color:COLORS.blue,cursor: "pointer", alignSelf: "center"}}/> 
									</div>
								</td>
							) : null}
							{isDraft && (
								<td
									onClick={()=>onClick({ id: item?.id, flag:'draft' })}
									style={{
										color: "#14619d",
										cursor: "pointer",
									}}
								>
									<i className="fa fa-pencil" />
								</td>
							)}
						</tr>
					);
				})}
				{tableData.length > 30 && PaginationComponent()}
				{downloadOption()}
				{tableData.length === 0 && !loading && (
					<h3
						style={{ position: "absolute", top: "50%", bottom: 0, left: "45%" }}
					>
						{locale?.languageType==="English"?"No Data Found!!":"डाटा प्राप्त नहीं हुआ!!"}
					</h3>
				)}
			</table>
		);
	} else {
		return (
			<div className="table-wrapper">
				{currentItems.map((item) => (
					<div
						key={item.id} // Don't forget to add a unique key for each item when mapping over an array
						className={"table-list-mobile"}
						onClick={() => onClick({ id: item?.id })}
					>
						<div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
							<div style={{ display: "flex", justifyContent: "space-between" }}>
								<div className="table-mobile-username-date">
									<span style={{ fontWeight: "bold" }}>{item?.recipient}</span>
									{/* {isSuccess && (
										<span
											style={{
												color: "#14619d",
											}}
										>
											{item?.sms_dr_time && item?.email_dr_time ? "✔✔" : "✔"}
										</span>
									)} */}
								</div>
							</div>
							<div className="table-mobile-subject">
								<span>
									<b>{locale?.languageType==="English"?"Sub:":"उप:"} </b>
									{item?.subject?.length > 120
										? `${item?.subject?.slice(0, 120)}...`
										: item?.subject}
								</span>
								<br />
								{!isDraft && isSuccess && (
									<span
										style={{
											display: "flex",
											justifyContent: "space-between",
											backgroundColor: "#14619C20",
											padding: 10,
											borderRadius: 4,
											marginTop: 20,
										}}
									>
										<b>{locale?.languageType==="English"?"SMS Delivery":"एसएमएस डिलीवरी"}</b>
										{item?.sms_dr_time ? formatDate(item?.sms_dr_time) :'N/A'}
									</span>
								)}

								{!isDraft && (
									<>
										<br />
										<span
											style={{
												display: "flex",
												justifyContent: "space-between",
												backgroundColor: "#14619C20",
												padding: 10,
												borderRadius: 4,
											}}
										>
											<b>{locale?.languageType==="English"?"Email Delivery":"ईमेल वितरण"}</b>
											{item?.email_dr_time ? formatDate(item?.email_dr_time) : 'N/A'}
										</span>
									</>
								)}

								{!isDraft && isSuccess && (
									<>
										<br />
										<span
											style={{
												display: "flex",
												justifyContent: "space-between",
												backgroundColor: "#14619C20",
												padding: 10,
												borderRadius: 4,
											}}
										>
											<b>{locale?.languageType==="English"?"Email Read Time":"ईमेल पढ़ने का समय"}</b>
											{item?.read_time ? formatDate(item?.read_time) : 'N/A'}
										</span>
									</>
								)}
								{!isDraft ? (
									<span
										onClick={() =>
											onClick({
												type: type,
												id: item.id,
											})
										}
										style={{
											display: "flex",
											justifyContent: "space-between",
											backgroundColor: "#14619C20",
											padding: 10,
											borderRadius: 4,
											marginTop: 12,
										}}
									>
										<b>{locale?.languageType=="English"?"Certificate":"प्रमाणपत्र"}</b>
										<CloudDownloadIcon style={{color:COLORS.blue,cursor: "pointer", alignSelf: "center"}}/>
									</span>
								) : null}
								{isDraft && (
									<span
									onClick={() =>
										onClick({
											id: item.id,
											flag:"draft"
										})
									}
										style={{
											display: "flex",
											justifyContent: "space-between",
											backgroundColor: "#14619C20",
											padding: 10,
											borderRadius: 4,
											marginTop: 12,
											color: "#14619d",
										}}
									>
										<b>{locale?.languageType==="English"?"Action":"कार्रवाई"}</b>
										<i className="fa fa-pencil" />
									</span>
								)}
							</div>
						</div>
					</div>
				))}
				{tableData.length > 30 && (
					<div
						style={{
							position: "absolute",
							bottom: 80,
							right: 0,
							width: "90%",
							fontSize: 12,
						}}
					>
						{PaginationComponent()}
					</div>
				)}

				{tableData.length === 0 && !loading && (
					<h3
						style={{ position: "absolute", top: "40%", bottom: 0, left: "35%" }}
					>
						{locale?.languageType==="English"?"No Data Found!!":"डाटा प्राप्त नहीं हुआ!!"}
					</h3>
				)}
			</div>
		);
	}
};

export default Table;
