import React from 'react'
import withBlackBackground from '../dashboard/withBlackBackground'
import { useMediaQuery } from 'react-responsive';
import BoxGif from '../../assets/images/animatedGif.gif'
import ConfettiGif from '../../assets/images/confetti.gif'
import COLORS from '../../../utility/colors';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setTokenFunction } from '../../../redux/Actions/UserAction';

const AnimationModal = () => {
    const isMobile = useMediaQuery({
        query: "(max-width: 820px)",
    });
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleRedirection = () => {
        localStorage.removeItem("jF3k!@m%n$t&y*z(H)U+p-o_lK?W[E]R~`lD;c/b.xN")
        dispatch(setTokenFunction(true));
        navigate('/compose-email')
    }

    return (
        <div style={{ background: '#444444', borderRadius: 12, width: isMobile ? window.innerWidth / 1.1 : window.innerWidth / 3, position: 'relative', display: 'flex', alignItems: 'center', flexDirection: 'column', overflow: 'hidden', height: window.innerHeight / 2.2, }}>
            <img src={ConfettiGif} style={{ position: 'absolute' }} />
            <img src={BoxGif} style={{ position: 'absolute' }} />
            <div style={{ position: 'absolute', padding: '40px 60px' }}>
                <h3 style={{ color: '#fff', textAlign: 'center', fontSize: isMobile ? 14 : 18 }}>Welcome to CERTIFI! Your First <span style={{ color: COLORS.white, fontWeight: '900', fontSize: isMobile ? 18 : 22 }}>10</span>  Transactions Are On Us.</h3>
            </div>
            <span onClick={() => handleRedirection()} style={{ background: COLORS.orangeShade, padding: '10px 20px', borderRadius: 8, color: COLORS.white, cursor: 'pointer', position: 'absolute', bottom: 20, alignSelf: 'center', fontWeight: '900', letterSpacing: 1.4, fontSize: isMobile?14:16, textAlign: 'center' }}>Compose Your First Certified Email Now!</span>

        </div>
    )
}

export default withBlackBackground(AnimationModal)