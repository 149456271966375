// src/app/screens/dashboard/TermsandConditions/index.js
import React from "react";
import PageWrapper from "../common/components/PageWrapper";
import PageHeader from "../common/components/PageHeader";
import styles from "../PrivacyPolicy/styles/privacypolicy.module.css"

const DisclaimerPolicy = () => {
    return (
        <PageWrapper>
            <div className={styles.privacyParentContainer}>
                <div className={styles.privacyContainer}>
                    <PageHeader string={"Disclaimer Policy"} />
                    <p>
                    Any individual, entity, or organization accessing or using the website, application, or platform of M/s Synerthink Solutions LLP (<b>“Synerthink”</b>, <b>“us”</b>, <b>“we”</b> or <b>“our”</b>) for any purpose, including but not limited to transmitting data, sending messages, serving notices, generating digital records, or storing digital records (<b>“User”</b>)  acknowledges that the User wishes to gain more information about us and our activities for their own use and purpose. Utmost care, attention and caution have been taken by us to ensure the data on our website and application is properly compiled. The data or other information received or obtained by us have been taken from sources which we consider genuine and reliable. However, we expressly disclaim any responsibility for verifying the accuracy, completeness, or currency of such data.
                    </p>
                    <p>
                    The information on our website and/or application is made available to the user on their specific request and any information obtained is completely at the volition of the user. Any use of our platform for transmitting emails, messages, notices, or attachments, or generating certificates for evidentiary purposes is solely at the user's risk, and we make no warranties or guarantees regarding the admissibility, validity, or reliability of such digital records in any legal or judicial proceeding.
                    </p>
                    <p>
                    By utilizing Synerthink’s CERTIFI systems and services, the user expressly consents to the collection, storage, access, and transmission of data provided to Synerthink, as necessary for service functionality. Synerthink will implement commercially reasonable measures to safeguard the privacy and security of user data. However, Synerthink disclaims any liability for unintended data mishandling or breaches caused by factors beyond its control, including but not limited to unauthorized access, cyber-attacks, or unforeseen technical failures. Users are encouraged to review Synerthink's privacy policy for detailed information on data handling practices.
                    </p>
                    <p>
                    We do not guarantee the accuracy, veracity, or completeness of the contents on our website/ application and we shall not be responsible for omissions, errors, or any reliance placed on such information. We are also not responsible for any loss or damages whatsoever suffered by the user’s reliance of any information through our website/ application. The user agrees that they assume full responsibility for verifying the accuracy and legality of any data transmitted via the platform.
                    </p>
                    <p>
                    The User agrees that in the event that the User requires any further documentation from Synerthink, including but not limited to affidavits under section 63 of the Bharatiya Sakshya Adhiniyam, 2023, the same may be provided by Synerthink at its sole discretion at an additional applicable cost determined by Synerthink.
                    </p>
                </div>
            </div>
        </PageWrapper>
    );
};

export default DisclaimerPolicy;